import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";

export class SearchExpenseType extends React.Component {
  render() {
    return (
      <h1>Search Expense Type is shifted to Expense Type Details</h1> 

    );
  }
}
