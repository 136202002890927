import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import "../Css/Adminlte.min.css";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import { Link } from "react-router-dom";
import APIUtil from "../../Util/ApiUtil";
import { Footer } from "../Footer";

let careHomeUser = JSON.parse(localStorage.getItem("user"));
export class AdminSingleCareHomeDetailsCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      careHomeId: this.props.match.params.id,
      Balance: 0.0,
      Deposits: 0.0,
      Expense: 0.0,
      totalResidentsCount: 0,
      CareHomeName: "",
      CareHomePhone: "",
      CareHomeAddress: "",
      ManagerName: "",
      ManagerPhone: "",
      ManagerEmail: "",
    };

    this.refreshList = this.refreshList.bind(this);
    this.refreshList();
  }

  refreshList() {
    fetch(
      APIUtil.BASE_URL +
        APIUtil.CARE_HOME_DETAILS_BY_ID +
        "/" +
        this.state.careHomeId
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Deposits: data.deposits,
          Balance: data.balance,
          Expense: data.expenses,
          totalResidentsCount: data.totalResidents,

          CareHomeName: data.careHome.name,
          CareHomePhone: data.careHome.phone,
          CareHomeAddress: data.careHome.address,

          ManagerName: data.manager.name,
          ManagerPhone: data.manager.phone,
          ManagerEmail: data.manager.email,
        });
      });
  }

  render() {
    return (
      <div className="hold-transition sidebar-mini sidebar-collapse">
        <div className="wrapper">
          <AdminNavbar />
          <AdminSidebar />
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Details</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="/Admin/AdminIndex">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Care Home Details
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <div className="info-box1 bg-white">
                      <span className="info-box-icon text-success">
                        <i className="fas fa-long-arrow-alt-down"></i>
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-number">
                          <i className="fas fa-xs fa-pound-sign"></i>{" "}
                          <strong>{this.state.Deposits}</strong>
                        </span>
                        <span className="info-box-text">Income</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="info-box1 bg-white">
                      <span className="info-box-icon text-warning">
                        <i className="fas fa-long-arrow-alt-up"></i>
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-number">
                          <i className="fas fa-xs fa-pound-sign"></i>
                          <strong>{this.state.Expense} </strong>
                        </span>
                        <span className="info-box-text">Expenses</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="info-box1 bg-white">
                      <span className="info-box-icon text-primary">
                        <i className="fas fa-money-bill"></i>
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-number text-primary">
                          <i className="fas fa-xs fa-pound-sign"></i>{" "}
                          <strong>{this.state.Balance}</strong>
                        </span>
                        <span className="info-box-text">Balance</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="info-box1 bg-white">
                      <span className="info-box-icon text-secondary">
                        <i className="fas fa-xs fa-user"></i>
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-number">
                          <strong>{this.state.totalResidentsCount}</strong>
                          <span className="float-right text-primary">
                            <Link to={"/Admin/AdminAddResident"+"/"+
                                            this.state.careHomeId}>
                              {" "}
                              <i className="fas fa-plus fa-sm"></i>
                            </Link>
                          </span>
                        </span>
                        <span className="info-box-text">Total Residents</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Care Home</h4>
                        <div className="card-tools">
                        <a href={ "/Admin/AdminEditCareHome/" +
                                            this.state.careHomeId
                                          } style={{color:'#2a9df4'}}>
                          <i className="fas fa-edit"></i>
                        </a> 
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="text-muted">
                            <h4 style={{ color: "#2a9df4" }}>
                              <i className="fas fa-xs fa-home"></i>
                              &nbsp;&nbsp;{this.state.CareHomeName}
                            </h4>
                            <p className="text-sm">
                              Phone No.
                              <b className="d-block">
                                {this.state.CareHomePhone}
                              </b>
                            </p>
                            <p className="text-sm">
                              Address
                              <b className="d-block">
                                {this.state.CareHomeAddress}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-5">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Manager</h4>
                        <div className="card-tools">
                        <a href={ "/Admin/AdminEditCareHome/" +
                                            this.state.careHomeId
                                          } style={{color:'#2a9df4'}}>
                          <i className="fas fa-edit"></i>
                        </a> 
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="text-muted">
                            <h4 >
                              <i className="fas fa-xs fa-user"></i>&nbsp;{" "}
                              {this.state.ManagerName}
                            </h4>
                            <p className="text-sm">
                              Phone No.
                              <b className="d-block">
                                {this.state.ManagerPhone}
                              </b>
                            </p>
                            <p className="text-sm">
                              E-mail
                              <b className="d-block">
                                {this.state.ManagerEmail}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer/>

        </div>

      </div>
    );
  }
}

export default AdminSingleCareHomeDetailsCard;
