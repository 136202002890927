import React from 'react';
import { BrowserRouter as Router, Route, Link,Switch } from 'react-router-dom';
import '../Css/Adminlte.min.css';
import '../Css/Custom.css';

export class ExpenseCard extends React.Component {
    render() {
      
        return(
            <div className="col-lg-5">
            <div className="card">
              <div className="card-header">
                  <h3 className="card-title">Expenses</h3>
                  <div className="card-tools">
                      <span className="text-bold text-md text-primary"><i className="fas fa-xs fa-pound-sign"></i> 500.00</span> &nbsp; &nbsp;
                      <a href="#" className="btn btn-sm btn-secondary" aria-hidden="true" data-toggle="modal" data-target="#Modal-add"><i className="fas fa-plus"> </i> Add  </a> 
                      </div>				
                </div> 
                {/* <!-- /.card-header --> */}
                <div className="card-body">
                  <table id="example2" className="table table-bordered table-hover">
                    <thead>
                    <tr>
                      <th style={{width:"25%"}}>Type</th>
                      <th style={{width:"20%"}}>Amount</th>
                      <th style={{width:"20%"}}>Date</th>
                      <th style={{width:"10%"}}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Personal</td>
                      <td><i className="fas fa-xs fa-pound-sign"></i> 10.70</td>
                      <td>12.05.2021</td>
                      <td>
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#Modal-edit" title="Edit"><i className="fas fa-sm fa-edit"></i></a>
                         &nbsp;&nbsp;&nbsp;
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#modal-default1" title="Delete"><i className="fas fa-sm fa-trash-alt"></i></a> 
                      </td>
                    </tr>
                    <tr>
                      <td>Maintenance</td>
                      <td><i className="fas fa-xs fa-pound-sign"></i> 12.00</td>
                      <td>20.05.2021</td>
                      <td>
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#Modal-edit" title="Edit"><i className="fas fa-sm fa-edit"></i></a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#modal-default1" title="Delete"><i className="fas fa-sm fa-trash-alt"></i></a> 
                      </td>			
                    </tr>
                    <tr>
                      <td>Personal</td>
                      <td><i className="fas fa-xs fa-pound-sign"></i> 25.55</td>
                      <td>24.05.2021</td>
                      <td>
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#Modal-edit" title="Edit"><i className="fas fa-sm fa-edit"></i></a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#modal-default1" title="Delete"><i className="fas fa-sm fa-trash-alt"></i></a> 
                      </td>
                    </tr>
                  <tr>
                      <td>Administrative</td>
                      <td><i className="fas fa-xs fa-pound-sign"></i> 25.00</td>
                      <td>5.05.2021</td>
                      <td>
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#Modal-edit" title="Edit"><i className="fas fa-sm fa-edit"></i></a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#modal-default1" title="Delete"><i className="fas fa-sm fa-trash-alt"></i></a> 
                      </td>
                    </tr>
                    <tr>
                      <td>Maintenance</td>
                      <td><i className="fas fa-xs fa-pound-sign"></i> 1.68</td>
                      <td>10.05.2021</td>
                      <td>
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#Modal-edit" title="Edit"><i className="fas fa-sm fa-edit"></i></a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#modal-default1" title="Delete"><i className="fas fa-sm fa-trash-alt"></i></a> 
                      </td>
                    </tr> 
                    <tr>
                      <td>Personal</td>
                      <td><i className="fas fa-xs fa-pound-sign"></i> 1.68</td>
                      <td>10.05.2021</td>
                      <td>
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#Modal-edit" title="Edit"><i className="fas fa-sm fa-edit"></i></a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#modal-default1" title="Delete"><i className="fas fa-sm fa-trash-alt"></i></a> 
                      </td>
                    </tr> 	
                    <tr>
                      <td>Maintenance</td>
                      <td><i className="fas fa-xs fa-pound-sign"></i> 1.68</td>
                      <td>10.05.2021</td>
                      <td>
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#Modal-edit" title="Edit"><i className="fas fa-sm fa-edit"></i></a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#" aria-hidden="true"  data-toggle="modal" data-target="#modal-default1" title="Delete"><i className="fas fa-sm fa-trash-alt"></i></a> 
                      </td>
                    </tr> 				  
                    </tbody>
                  </table>
                </div>		  
            </div>
            </div> 
              
            //   <!-- /.card -->
           
            )
    }
}
 
