import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import { ManagerNavbar } from "./ManagerNavbar";
import ManagerSidebar from "./ManagerSidebar";
import { Footer } from "../Footer";
import { EditExpenseTypeModal } from '../Modals/Manager/EditExpenseTypeModal'

import AddExpenseType from "./AddExpenseType";
import { SearchExpenseType } from "./SearchExpenseType";
import { ExpenseTypeList } from "./ExpenseTypeList";
import APIUtil from "../../Util/ApiUtil";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from "react-paginate";

import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
toast.configure()
let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class ExpenseTypesDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ExpenseTypes: [],
            ExpenseTypeId: "",
            selectedOption: "",
            ExpenseTypeName: "",
            ExpenseTypeNameError: "",

            //edit states 
            eExpenseTypeId: "",
            eExpenseTypeName: "",

            searchExpenseType: '',

            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageCount: 1,
            context: [],


        }

        this.onChangeExpenseTypeName = this.onChangeExpenseTypeName.bind(this);
        this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
        this.editModelClose = this.editModelClose.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.handleTypeSearch = this.handleTypeSearch.bind(this);
        this.onChangeExpenseType = this.onChangeExpenseType.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount() {

        this.refreshList();
    }


    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState(
            {
                selected: selectedPage,
                offset: offset,
            },
            () => {
                this.setState({ ExpenseTypes: this.state.context.slice(this.state.offset, this.state.offset + this.state.perPage) });
            }
        );
    };



    refreshList() {
        fetch(APIUtil.BASE_URL + APIUtil.EXPENSE_TYPE
            + "/" + careHomeUser.CareHomeId, {
            method: "GET",
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then(
                (result) => {
                    this.setState({ context: result, ExpenseTypes: result.slice(this.state.offset, this.state.offset + this.state.perPage), pageCount: Math.ceil(result.length / this.state.perPage) });

                },
                (error) => {
                    console.log("failed");
                }
            )
    }

    handleTypeSearch() {
        if (this.state.searchExpenseType.length > 0) {
            let searched = this.state.context.filter(type => type.name === this.state.searchExpenseType[0].name)
            this.setState({ ExpenseTypes: searched, pageCount: 1 });
        } else {
            this.setState({ ExpenseTypes: this.state.context.slice(this.state.offset, this.state.offset + this.state.perPage) })
        }
    }

    clearTypeSearch() {
        this.setState({ ExpenseTypes: this.state.context.slice(this.state.offset, this.state.offset + this.state.perPage), pageCount: Math.ceil(this.state.context.length / this.state.perPage) })

    }

    onChangeExpenseTypeName(event) {
        this.setState({
            ExpenseTypeNameError: "",
            ExpenseTypeName: event.target.value,
        });
    }

    notify() {
        toast.info('Expense Type is Added',
            { position: toast.POSITION.TOP_RIGHT })
    }

    notify_err() {
        toast.error('Error Occured. Please try again.',
            { position: toast.POSITION.TOP_RIGHT })
    }

    valid() {
        let isValid = true;
        if (this.state.ExpenseTypeName == null || this.state.ExpenseTypeName == "") {
            this.setState({ ExpenseTypeNameError: "Field cannot be empty" })
            isValid = false;
        }
        return isValid;
    }

    handleSubmitAdd(event) {

        if (!this.valid()) {
            toast.error('Expense Type cannot be empty.',
                { position: toast.POSITION.TOP_RIGHT })
            return;
        }

        fetch(APIUtil.BASE_URL + APIUtil.EXPENSE_TYPE, {
            method: "POST",
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: this.state.ExpenseTypeName,
                careHomeId: parseInt(careHomeUser.CareHomeId),
            }),
        })
            .then((response) => {
                this.notify();
                this.setState({ ExpenseTypeName: '' });
                this.refreshList();

            },
                (error) => {
                    console.log("failed");
                    this.notify_err();

                })

    }

    editModelClose() {
        this.setState({
            editModelShow: false,
            eExpenseTypeId: '',
            eExpenseTypeName: ''
        })
        this.refreshList();
    }

    onChangeExpenseType(selected) {
        this.setState({ searchExpenseType: selected });
    }

    render() {
        const inputProps = { name: 'name' };
        let expenseTypes = [];
        this.state.context.map((type) => {
            if (type.name !== '') {
                expenseTypes.push({ name: type.name });
            }
        });
        return (
            <>
                <div
                    id="sidebar"
                    className="hold-transition sidebar-mini sidebar-collapse"
                >
                    <div className="wrapper">
                        <ManagerNavbar />
                        <ManagerSidebar />
                        <div className="content-wrapper">
                            <section className="content">
                                <section className="content-header">
                                    <div className="container-fluid">
                                        <div className="row mb-2">
                                            <div className="col-sm-6">
                                                <h1>Expense Types</h1>
                                            </div>
                                            <div className="col-sm-6">
                                                <ol className="breadcrumb float-sm-right">
                                                    <li className="breadcrumb-item">
                                                        <a href="/Manager/ManagerIndex">Home</a>
                                                    </li>
                                                    <li className="breadcrumb-item active">Expenses</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Add</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="form-group col-md-8">
                                                            <label>Type</label>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    {" "}
                                                                    <span className="input-group-text">
                                                                        <i className="fas fa-sm fa-hashtag"></i>
                                                                    </span>{" "}
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Expense Title"
                                                                    value={this.state.ExpenseTypeName}
                                                                    onChange={this.onChangeExpenseTypeName}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-4 mb-0">
                                                            <label for="inputName"></label>
                                                            <div style={{ padding: ".375rem .75rem" }}>
                                                                <button onClick={this.handleSubmitAdd}
                                                                    type="button"
                                                                    className="btn btn-block btn-secondary search1"
                                                                >
                                                                    <i className="fas fa-sm fa-plus"></i> Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Search</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="form-group col-md-6">
                                                            <label for="inputName">Type</label>

                                                            <Typeahead
                                                                ref='name_typeahead'
                                                                id="basic-typeahead-example"
                                                                inputProps={inputProps}
                                                                labelKey="name"
                                                                options={expenseTypes}
                                                                placeholder="Search"
                                                                className="search"
                                                                id="name"
                                                                clearButton={true}
                                                                onChange={(selected) => {
                                                                    if (selected.length > 0) {
                                                                        this.setState({ searchExpenseType: selected })
                                                                    } else {
                                                                        this.setState({ searchExpenseType: [] }, this.clearTypeSearch());
                                                                    }

                                                                }}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6 mb-0">
                                                            <label for="inputName"></label>
                                                            <div style={{ padding: ".375rem .75rem" }}>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-block btn-primary search1"
                                                                    onClick={this.handleTypeSearch}
                                                                >
                                                                    Search
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">List</h3>
                                                {/* <!--<div className="card-tools">
            <a href="#" className="btn btn-sm btn-secondary"  aria-hidden="true"  data-toggle="modal" data-target="#Modal-add"><i className="fas fa-plus"></i> Add </a>
                
                </div>	-->			 */}
                                            </div>

                                            <div className="card-body">
                                                <table id="example2" className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "65%" }}>Type</th>
                                                            <th style={{ width: "35%" }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.ExpenseTypes.map((ExpenseType) => (
                                                            <tr key={ExpenseType.id}>
                                                                <td>{ExpenseType.name}</td>
                                                                <td>
                                                                    <a style={{ color: "#007bff" }} onClick={() =>
                                                                        this.setState({
                                                                            editModelShow: true,
                                                                            eExpenseTypeId: ExpenseType.id,
                                                                            eExpenseTypeName: ExpenseType.name
                                                                        })
                                                                    }

                                                                        aria-hidden="true" data-toggle="modal" data-target="#Modal-edit">
                                                                        <i className="fas fa-sm fa-edit"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))}

                                                        {this.state.editModelShow && (
                                                            <EditExpenseTypeModal
                                                                show={this.state.editModelShow}
                                                                onHide={this.editModelClose}
                                                                // onClick={this.onClick}
                                                                ExpenseTypeId={this.state.eExpenseTypeId}
                                                                ExpenseTypeName={this.state.eExpenseTypeName}

                                                            />
                                                        )}

                                                    </tbody>
                                                </table>

                                                <ReactPaginate
                                                    previousLabel={"prev"}
                                                    nextLabel={"next"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}
                                                    forcePage={this.state.selected}
                                                />
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </section>
                        </div>
                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}
