import React from 'react';
import '../Css/Adminlte.min.css';
import '../Css/Custom.css';
import '../Css/Resident.css';
import APIUtil from "../../Util/ApiUtil";
import moment from "moment";
import ReactPaginate from "react-paginate";



export class Payments extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      FamilyMemberId:"",
      deposits:[],
      Amount:0,
      DepositDate: new Date(),
      loading: false,

       //pagination
       Offset: 0,
       perPage: 10,
       currentPage: 0,
       pageNum: 1,
    
    };

    this.refreshList = this.refreshList.bind(this);
    
  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.state.FamilyMemberId=user.Id;
    
    
    this.refreshList();
  }

  refreshList() {
    {
      this.setState({ loading: true });
    }
     fetch(APIUtil.BASE_URL + APIUtil.GET_DEPOSIT_API+'/'+this.state.FamilyMemberId)
    .then((response) => response.json())
    .then((result) => {
        this.setState({
        deposits: result, 
        
       pageCount: Math.ceil(result.totalCount / this.state.perPage),
       loading: false,
       pageNum: Math.ceil(this.state.Offset / 10),
      });
 
      
    });
  }

    render() {
      let deposits = [];
   
    {
      this.state.deposits?.map((deposit) => {
       
        deposits.push(
          <tr>
            <td><i className="fas fa-xs  fa-pound-sign"></i>&nbsp;{deposit.amount}</td>
            <td>{moment(deposit.date).format("DD-MMM-YY")}</td>
          </tr>
        );
      });
    }
        return (
            
            <div className="card">
            <div className="card-header">
                   <h3 className="card-title">Deposits Report</h3>			
                 </div> 
                 <div className="card-body">
                 {this.state.loading ? (
                        <div style={{ textAlign: "center" }}>
                          <img src="/dist/img/loader.gif" width="20%" />
                        </div>
                      ) : (
                        <>
                   <table id="example2" className="table table-bordered table-hover">
                     <thead>
                     <tr >
                       <th style={{width:"50%"}}>Amount</th>
                       <th style={{width:"50%"}}>Date</th>
                     </tr>
                     </thead>
                     <tbody>
                       {/* {this.state.deposits.map((deposit) => {
                       <tr key={deposit.id}>
                       <td><i className="fas fa-xs  fa-pound-sign"></i>{deposit.amount}</td>
                     </tr>
                       })} */}
                       {deposits}
                      </tbody>
                   </table>
                   <ReactPaginate
             previousLabel={"prev"}
             nextLabel={"next"}
             breakLabel={"..."}
             breakClassName={"break-me"}
             pageCount={this.state.pageCount}
             forcePage={this.state.pageNum}
             marginPagesDisplayed={2}
             pageRangeDisplayed={5}
             onPageChange={this.handlePageClick}
             containerClassName={"pagination"}
             subContainerClassName={"pages pagination"}
             activeClassName={"active"}
          />
          </>
          )}
                 </div>
                 
               </div>

        );
    }
}
