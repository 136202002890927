import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import "../Css/CustomDatePick.css";
import Swal from "sweetalert2";

import "../Css/Adminlte.min.css";
import {ManagerNavbar} from "./ManagerNavbar";
import {ManagerSidebar} from "./ManagerSidebar";
import AddExpenseModal from "../Modals/Admin/AddExpenseModal";
import AddFundsModal from "../Modals/Manager/AddFundsModal";
import EditThresholdModal from "../Modals/Manager/EditThresholdModal";
import ReactPaginate from "react-paginate";

import APIUtil from "../../Util/ApiUtil";
import AsyncCsv from "../AsyncCsv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export class ManagerSingleResidentDetails extends React.Component {
    csvLink = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            residentId: this.props.match.params.id,
            selectValue: "1",
            expenseTypeName: "",

            // expenseType:[],
            moneyReceived: 0.0,
            Expenses: 0.0,
            Balance: 0.0,

            PersonalName: "",
            PersonalAge: 0,
            PersonalRoomNumber: "0",
            dateOfBirth: null,
            FamilyMemberName: "",
            FamilyMemberEmail: "",
            FamilyMemberPhone: "",
            FamilyMemberAddress: "",
            PersonalUserName: "",
            showAddExpenseModal: false,
            showAddFundModal: false,
            showEditThresholdModal: false,
            expenses: [],
            TransactionDetailsModel: [],
            ExpenseType: "",
            Date: "",
            Amount: 0,            
            Id: undefined,
            Info: "",
            TypeId: undefined,
            DownloadUrl: APIUtil.DOWNLOAD_BASE_URL,
            Type: "",
            name: this.props.filename ? this.props.filename : 'data',
            
            addExpenseData: {
                amount: 0,
                date: "",
                expenseTypeId: 0,
                info: "",
                file: {},
            },

            
            loading: false,
            selectedResident: this.props.match.params.id,
            Offset: 0,
            perPage: 10,
            currentPage: 0,
            pageNum: 1,
            MinimumBalance: 0,
            startDate: null,
            endDate: null,
            selectedStartDate: null,
            selectedEndDate: null,
        };

        this.refreshList = this.refreshList.bind(this);
        this.closeResidentExpenseModal = this.closeResidentExpenseModal.bind(this);
        this.addResidentExpenseModal = this.addResidentExpenseModal.bind(this);
        this.addResidentFundModal = this.addResidentFundModal.bind(this);
        this.closeResidentFundModal = this.closeResidentFundModal.bind(this);
        this.getTransactionDetails = this.getTransactionDetails.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.closeEditThresholdModal = this.closeEditThresholdModal.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.getMinimumBalance = this.getMinimumBalance.bind(this);
        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.onChangeEndDate = this.onChangeEndDate.bind(this);
        this.updateTransaction = this.updateTransaction.bind(this);
    }

    onChangeStartDate(date) {
        this.setState({startDate: date})
    }

    onChangeEndDate(date) {
        this.setState({endDate: date})
    }

    componentDidMount() {
    }

    async handleDropdownChange(e) {
        await this.setState(
            {selectValue: e.target.value},
        );
        await this.getTransactionDetails();
    }

    addResidentExpenseModal() {
        this.setState({
            showAddExpenseModal: true,
            Id: undefined,
            Info: undefined,
            Date: undefined,
            Amount: undefined,
            TypeId: undefined,
        });
    }

    closeResidentExpenseModal() {
        this.setState({showAddExpenseModal: false,});
        this.refreshList();
        this.getTransactionDetails();
    }

    addResidentFundModal() {
        this.setState({
            showAddFundModal: true, 
            Id: undefined,
            Info: undefined,
            Date: undefined,
            Amount: undefined,
        });
    }

    closeResidentFundModal() {
        this.setState({showAddFundModal: false,});
        this.refreshList();
        this.getTransactionDetails();
    }

    closeEditThresholdModal() {
        this.setState({showEditThresholdModal: false});
        this.getMinimumBalance();
    }

    getAge(dateOfBirth) {
        var date = new Date(dateOfBirth);
        var today = new Date();
        var age_now = today.getFullYear() - date.getFullYear();
        var m = today.getMonth() - date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
            age_now--;
        }
        return age_now;
    }

    updateTransaction(transaction) {
        var parts = transaction.id.split("-");
        switch(parts[0])
        {
            case 'E':
                this.setState({
                    showAddExpenseModal: true,
                    Id: parts[1],
                    Info: transaction.info,
                    Date: transaction.date,
                    Amount: transaction.amount,
                    TypeId: transaction.expenseTypeId
                });
                break;
            case 'D':
                this.setState({
                    showAddFundModal: true,
                    Id: parts[1],
                    Info: transaction.info,
                    Date: transaction.date,
                    Amount: transaction.amount
                });
                break;
        }
    }

    refreshList() {
        fetch(
            APIUtil.BASE_URL + APIUtil.CARE_HOME_RESIDENT_DETAILS_BY_ID + "/" + this.state.residentId
        )
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    moneyReceived: data.moneyReceived,
                    Expenses: data.expenses,
                    Balance: data.balance,
                    dateOfBirth: data.personal.dateOfBirth,

                    PersonalName: data.personal.name,
                    PersonalAge: data.personal.age,
                    PersonalRoomNumber: data.personal.roomNumber,
                    PersonalUserName: data.familyMember.userName,
                    FamilyMemberName: data.familyMember.name,
                    FamilyMemberEmail: data.familyMember.email,
                    FamilyMemberPhone: data.familyMember.phoneNumber,
                    FamilyMemberAddress: data.familyMember.address,
                });

            });
    }

    componentDidMount() {
        this.refreshList();
        this.getTransactionDetails();
        this.getMinimumBalance();
    }

    getMinimumBalance() {
        fetch(
            APIUtil.BASE_URL + APIUtil.RESIDENT_SUMMARY + "/" + this.state.residentId
        )
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    MinimumBalance: parseInt(data.minimumBalance),
                });
            });
    }

    getTransactionDetails() {


        var url = APIUtil.BASE_URL + APIUtil.GET_TRANSACTION_DETAILS_API + "/" + this.state.residentId + `?Limit=` + this.state.perPage + `&Offset=` + this.state.Offset;
        if (this.state.startDate == null || this.state.endDate == null) {
            url = url + `&startDate=` + '01-01-0001' + `&endDate=` + '01-01-9999';
        } else {
            if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 5000,
                    icon: "error",
                    title: "End date can not be before start date",
                });
                return;
            }
            var startDateString = moment(new Date(this.state.startDate)).format('DD-MM-yyyy');
            var endDateString = moment(new Date(this.state.endDate)).format('DD-MM-yyyy');

            url = url + `&startDate=` + startDateString + `&endDate=` + endDateString;
        }
        {
            this.setState({loading: true});
        }
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    DownloadUrl: APIUtil.DOWNLOAD_BASE_URL + "/" + data.path.replace("//" + "/"),
                    TransactionDetailsModel: data.transactionDetailsModel,
                    pageCount: Math.ceil(data.count / this.state.perPage),
                    loading: false,
                    pageNum: Math.ceil(this.state.Offset / 10),

                });
            });
    }

    formatDate(string) {
        var options = {year: 'numeric', month: 'long', day: 'numeric'};
        return new Date(string).toLocaleDateString([], options);
    }

    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState(
            {
                currentPage: selectedPage,
                Offset: offset,
            },
            () => {
                this.getTransactionDetails();
            }
        );
    };

    formatUrl(path) {
        if (path != null && path != "") {
            var url = APIUtil.DOWNLOAD_BASE_URL + "/" + path.replace("C:\\inetpub\\wwwroot\\CareHomes\\Attachments\\", "");
            return url;
        } else return "";
    }

    render() {
        return (
            <>
                <div
                    id="sidebar"
                    className="hold-transition sidebar-mini sidebar-collapse"
                >
                    <div className="wrapper">
                        <ManagerNavbar/>
                        <ManagerSidebar/>
                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1 className="text-primary">
                                                Resident{" "}
                                                <small className="text-gray-dark">
                                                    Details for {this.state.PersonalName}
                                                </small>{" "}
                                            </h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item">
                                                    <a href="/Admin/AdminIndex">Home</a></li>
                                                <li className="breadcrumb-item active">Resident</li>
                                                <li className="breadcrumb-item active">Details</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- /.card --> */}
                                        <div className="col-3">
                                            <div className="info-box1 bg-white">
                        <span className="info-box-icon text-success">
                          <i className="fas fa-long-arrow-alt-down"></i>
                        </span>
                                                <div className="info-box-content">
                          <span className="info-box-number">
                            <i className="fas fa-xs fa-pound-sign"></i>{" "}
                              <strong>{this.state.moneyReceived}</strong>
                          </span>
                                                    <span className="info-box-text">Money Received</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="info-box1 bg-white">
                        <span className="info-box-icon text-warning">
                          <i className="fas fa-long-arrow-alt-up"></i>
                        </span>
                                                <div className="info-box-content">
                          <span className="info-box-number">
                            <i className="fas fa-xs fa-pound-sign"></i>
                            <strong> {this.state.Expenses} </strong>
                          </span>
                                                    <span className="info-box-text">Expenses</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="info-box1 bg-white">
                                                <span className="info-box-icon text-primary">
                                                  <i className="fas fa-money-bill"></i>
                                                </span>
                                                <div className="info-box-content">
                                                    <span className="info-box-number text-primary">
                                                      <i className="fas fa-xs fa-pound-sign"></i>{" "}
                                                      <strong>{this.state.Balance}</strong>
                                                    </span>
                                                    <span className="info-box-text">Balance</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 md-3 sm-1">
                                            <a
                                                onClick={() => this.addResidentFundModal()}
                                                data-toggle="modal"
                                                data-target="#Modal-add-funds"
                                            >
                                                <div className="btn btn-outline-primary btn-block mb-1">
                                                    <i className="fas fa-sm fa-plus"></i>&nbsp;
                                                    &nbsp;&nbsp; &nbsp; <strong>Add Funds</strong>
                                                </div>
                                            </a>
                                            <a
                                                onClick={() => this.addResidentExpenseModal()}
                                                data-toggle="modal"
                                                data-target="#Modal-manage"
                                            >
                                                <div className="btn btn-outline-secondary btn-block">
                                                    <i className="fas fa-sm fa-wallet"></i>&nbsp;
                                                    &nbsp;&nbsp; &nbsp; <strong>Add Expense</strong>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>


                                <div className=" row col-lg-12">
                                    <div className="col-lg-4">
                                        <div className="card" style={{height: '280px'}}>
                                            <div className="card-header">
                                                <h4 className="card-title">Personal</h4>
                                                <div className="card-tools">
                                                    <a href={"/Manager/ManagerEditResident/" +
                                                        this.state.residentId
                                                    } style={{color: '#2a9df4'}}>
                                                        <i className="fas fa-edit"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="card-body mb-4 ml-2">
                                                <div className="row">
                                                    <div className="text-muted">
                                                        <h4 style={{color: '#2a9df4'}}>
                                                            <i className="fas fa-xs fa-user"></i>&nbsp;{" "}
                                                            {this.state.PersonalName}
                                                        </h4>
                                                        <p className="text-sm">Age
                                                            <b className="d-block">{this.getAge(this.state.dateOfBirth)}</b>
                                                        </p>
                                                        <p className="text-sm">
                                                            Room No.
                                                            <b className="d-block">
                                                                {this.state.PersonalRoomNumber}
                                                            </b>
                                                        </p>
                                                        <p className="text-sm">
                                                            Username
                                                            <b className="d-block">
                                                                {this.state.PersonalUserName}
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="card" style={{height: '280px'}}>
                                            <div className="card-header">
                                                <h4 className="card-title">Family Member</h4>
                                                <div className="card-tools">
                                                    <a href={"/Manager/ManagerEditResident/" +
                                                        this.state.residentId
                                                    } style={{color: '#2a9df4'}}>
                                                        <i className="fas fa-edit"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="card-body ml-2">
                                                <div className="row">
                                                    <div className="text-muted">
                                                        <h6>
                                                            <i className="fas fa-xs fa-user"></i>&nbsp;
                                                            {this.state.FamilyMemberName}
                                                        </h6>
                                                        <p className="text-sm">
                                                            E-mail
                                                            <b className="d-block">
                                                                {this.state.FamilyMemberEmail}
                                                            </b>
                                                        </p>
                                                        <p className="text-sm">
                                                            Phone No.
                                                            <b className="d-block">
                                                                {this.state.FamilyMemberPhone}
                                                            </b>
                                                        </p>
                                                        <p className="text-sm">
                                                            Address
                                                            <b className="d-block">
                                                                {this.state.FamilyMemberAddress}
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4" style={{height: '280px'}}>
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Minimum Balance</h4>
                                                <div className="card-tools">
                                                    <a href="#" onClick={() =>
                                                        this.setState({
                                                            showEditThresholdModal: true
                                                        })}>
                                                        <i className="fas fa-edit"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="card-body-balance ml-2">
                                                <div className="row">
                                                    <div className="text-muted">
                                                        <h6>At present</h6>
                                                        <b className="d-block">
                                                            <i className="fa fa-xs fa-pound-sign"></i>
                                                            {this.state.MinimumBalance}
                                                        </b>
                                                        <p className="text-sm">
                                                            Set the minimum balance threshold for resident.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className=" row col-lg-12">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">Transaction Details</h3>
                                                <div className="card-tools">
                                                    <div className="row">
                                                        <div className="col-md-5  DatePick2Change">
                                                            <DatePicker
                                                                dateFormat="dd-MM-yyyy"
                                                                maxDate={moment(this.state.maxDate).toDate()}
                                                                selected={this.state.startDate}
                                                                onChange={this.onChangeStartDate}
                                                                className="form-control"
                                                                placeholderText="Start Date"
                                                                style={{width: '100%'}}
                                                            />
                                                        </div>
                                                        <div className="col-md-5  DatePick2Change">
                                                            <DatePicker
                                                                dateFormat="dd-MM-yyyy"
                                                                maxDate={moment(this.state.maxDate).toDate()}
                                                                selected={this.state.endDate}
                                                                onChange={this.onChangeEndDate}
                                                                placeholderText="End Date"
                                                                className="form-control"
                                                                style={{width: '100% !important'}}
                                                            /></div>
                                                        <div onClick={this.getTransactionDetails}
                                                             className="btn btn-primary col-md-1"
                                                             id="submitbtn"
                                                        ><i className="fas fa-search"></i>
                                                        </div>

                                                        <div className="col-md-1">
                                                            {this.state.DownloadUrl != "" ? (
                                                                <a style={{marginTop: '0px'}}
                                                                   href={this.state.DownloadUrl} target="_self"
                                                                   className="btn btn-tool btn-sm" download>
                                                                    <i className="fas fa-download"></i>
                                                                </a>) : ""}
                                                        </div>
                                                    </div>


                                                    {/* <input type="date" format="dd/mm/yyyy" placeholder="StartDate" />
                        <input type="date" format="dd/mm/yyyy"  placeholder="EndDate" /> */}

                                                </div>
                                            </div>

                                            <div className="card-body">
                                                {this.state.loading ? (
                                                    <div style={{textAlign: "center"}}>
                                                        <img src="/dist/img/loader.gif" width="20%"/>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <table
                                                            id="example2"
                                                            className="table table-bordered table-hover"
                                                        >
                                                            <thead>
                                                            <tr>
                                                                <th style={{width: "40%"}}>Type</th>
                                                                <th style={{width: "30%"}}>Date</th>
                                                                <th style={{width: "30%"}}>Amount</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.TransactionDetailsModel.map((expense) => (

                                                                <tr key={expense.id}>
                                                                    <td>
                                                                        <a onClick={() => this.updateTransaction(expense)}
                                                                           href="#">{expense.expenseTypeName}</a>
                                                                        <p style={{fontSize: "14px"}}
                                                                           className="text-muted mb-0">  {expense.info} </p>
                                                                    </td>
                                                                    <td>{this.formatDate(expense.date)}</td>
                                                                    <td>

                                                                        <div style={{display: "inline-block"}}><i
                                                                            className="fas fa-xs  fa-pound-sign"></i> {expense.amount}
                                                                        </div>

                                                                        {expense.type === "Credit" ? (
                                                                            <div className="float-right">
                                                                              <span className="float-right text-success">
                                                                                {expense.type}
                                                                              </span>
                                                                                <span
                                                                                    className="text-sm-right float-right mr-2 ">
                                            <i className="fas fa-long-arrow-alt-down text-success"></i>
                                          </span>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="float-right">
                                          <span className="float-right text-warning">
                                            {expense.type}
                                          </span>
                                                                                <span
                                                                                    className="float-right mr-2 text-sm-right">
                                            <i className="fas fa-long-arrow-alt-up text-warning"></i>
                                          </span>
                                                                            </div>

                                                                        )}
                                                                        {this.formatUrl(expense.receiptPath) != "" ? (

                                                                            <a href={this.formatUrl(expense.receiptPath).replace("\\", "/")}
                                                                               className="float-right mr-2 text-sm-right">
                                                                                <i className="fas fas fa-file-download text-warning"></i>
                                                                            </a>) : ""}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>

                                                        <ReactPaginate
                                                            previousLabel={"prev"}
                                                            nextLabel={"next"}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={this.state.pageCount}
                                                            forcePage={this.state.pageNum}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={this.handlePageClick}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        {this.state.showAddExpenseModal ? (
                                            <AddExpenseModal
                                                show={this.state.showAddExpenseModal}
                                                onHide={this.closeResidentExpenseModal}
                                                ResidentId={this.state.selectedResident}
                                                TransactionId={this.state.Id}
                                                Info={this.state.Info}
                                                Date={this.state.Date}
                                                Amount={this.state.Amount}
                                                TransactionTypeId={this.state.TypeId}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        {this.state.showAddFundModal ? (
                                            <AddFundsModal
                                                show={this.state.showAddFundModal}
                                                onHide={this.closeResidentFundModal}
                                                ResidentId={this.state.selectedResident}
                                                TransactionId={this.state.Id}
                                                Info={this.state.Info}
                                                Date={this.state.Date}
                                                Amount={this.state.Amount}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        {this.state.showEditThresholdModal ? (
                                            <EditThresholdModal
                                                show={this.state.showEditThresholdModal}
                                                onHide={this.closeEditThresholdModal}
                                                ResidentId={this.state.selectedResident}
                                                Threshold={this.state.MinimumBalance}

                                                ResidentName={this.state.PersonalName}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}
