import React from "react";
import Swal from "sweetalert2";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import APIUtil from "../../Util/ApiUtil";
import { Link } from "react-router-dom";
import Footer from "../Footer"
let careHomeUser = JSON.parse(localStorage.getItem("user"));
export class AdminAddCareHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      careHomeUserId: parseInt(careHomeUser.Id),
      Name: "",
      Phone: "",
      Street: "",
      Town: "",
      CountryOptions: ["England", "Scotland", "Wales", "Northern Ireland"],
      countrySelected: "",
      PostCode: "",

      MName: "",
      MPhone: "",
      MEmail: "",
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleStreetChange = this.handleStreetChange.bind(this);
    this.handleTownChange = this.handleTownChange.bind(this);
    this.handleCountryOptionsChange =
      this.handleCountryOptionsChange.bind(this);
    this.handlePostCodeChange = this.handlePostCodeChange.bind(this);

    this.handleMNameChange = this.handleMNameChange.bind(this);
    this.handleMPhoneChange = this.handleMPhoneChange.bind(this);
    this.handleMEmailChange = this.handleMEmailChange.bind(this);
    this.onCreateCareHome = this.onCreateCareHome.bind(this);
  }

  handleNameChange = (e) => {
    this.setState({ Name: e.target.value });
  };

  handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/, "");
    if (e.target.value.length >11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid phone number",
      });
    } else {
      this.setState({ Phone: value });
    }
  };

  handleStreetChange = (e) => {
    this.setState({ Street: e.target.value });
  };

  handleTownChange = (e) => {
    this.setState({ Town: e.target.value });
  };

  handleCountryOptionsChange = (e) => {
    this.setState({
      countrySelected: e.target.value,
    });
  };

  handlePostCodeChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    if ( e.target.value.length >7) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid Postal code ",
      });
    } else {
      this.setState({ PostCode: value });
    }
  };

  handleMNameChange = (e) => {
    this.setState({ MName: e.target.value });
  };

  handleMPhoneChange = (e) => {
    const value = e.target.value.replace(/\D/, "");
    if (e.target.value.length>11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid phone number",
      });
    } else {
      this.setState({ MPhone: value });
    }
  };
  handleMEmailChange = (e) => {
    this.setState({ MEmail: e.target.value });
  };

  valid() {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (this.state.Name == null || this.state.Name == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    } else if (this.state.Phone == null || this.state.Phone == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    } else if (this.state.Phone.length !== 10 && this.state.Phone.length !== 11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid phone number",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.Street == null || this.state.Street == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    } else if (this.state.Town == null || this.state.Town == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    } else if (
      this.state.countrySelected == null ||
      this.state.countrySelected == ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.PostCode == null || this.state.PostCode == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    }
    else if (this.state.PostCode.length !== 6 && this.state.PostCode.length !== 7) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid PostCode",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.MName == null || this.state.MName == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    } else if (this.state.MPhone == null || this.state.MPhone == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    } 
    
    else if (this.state.MPhone.length !== 10 && this.state.MPhone.length !== 11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid phone number",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } 
    else if (!pattern.test(this.state.MEmail)) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please provide a valid email",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    }  else if (!this.state.MEmail.includes("@" && ".")) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please provide a valid email",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    } else {
      return true;
    }
  }

  onCreateCareHome = (event) => {
    document.getElementById("submitbtn").innerHTML = "Processing...";

    event.preventDefault();
    if (this.valid()) {
      fetch(APIUtil.BASE_URL + APIUtil.CARE_HOME_API, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CreatedBy: this.state.careHomeUserId,
          ModifiedBy: this.state.careHomeUserId,
          name: this.state.Name,
          phone: this.state.Phone,
          street: this.state.Street,
          town: this.state.Town,
          country: this.state.countrySelected,
          postCode: this.state.PostCode,
          manager: {
            name: this.state.MName,
            email: this.state.MEmail.toLocaleLowerCase(),
            phone: this.state.MPhone,
          },
        }),
      }).then(async (response) => {
        if (response.ok) {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: "Care Home is added successfully",
          });
          document.getElementById("submitbtn").innerHTML = "Save";

          this.setState({ Name: "" });
          this.setState({ Phone: "" });
          this.setState({ Street: "" });

          this.setState({ Town: "" });
          this.setState({ countrySelected: "" });
          this.setState({ PostCode: "" });

          this.setState({ MName: "" });
          this.setState({ MPhone: "" });
          this.setState({ MEmail: "" });
          window.location.href = "/Admin/AdminSearchCareHome";
        } else {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: "Email already exist",
          });
        
          document.getElementById("submitbtn").innerHTML = "Save";
        }
      }).catch((error) => {
        console.log("error",error);
        Swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "error",
          title: "Email already exist",
        });
        document.getElementById("submitbtn").innerHTML = "Save";
      });
    }
  };

  render() {
    return (
      <>
        <div
          id="sidebar"
          className="hold-transition sidebar-mini sidebar-collapse"
        >
          <div className="wrapper">
            <AdminNavbar />
            <AdminSidebar />
            <form submit={this.onCreateCareHome}>
              <div className="content-wrapper">
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1>Add Care Home</h1>
                      </div>

                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item">
                            <a href="/Admin/AdminIndex">Home</a>
                          </li>
                          <li className="breadcrumb-item active">Care Home</li>
                          <li className="breadcrumb-item active">Add</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Care Home Details</h3>
                            <div className="col-md-3"></div>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Name</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-home"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleNameChange}
                                      value={this.state.Name}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Phone No.</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-phone-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      pattern="[0-9]*"
                                      className="form-control"
                                      onChange={this.handlePhoneChange}
                                      value={this.state.Phone}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Street Name</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-map-marker-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleStreetChange}
                                      value={this.state.Street}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Town</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-map-marker-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleTownChange}
                                      value={this.state.Town}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label for="inputName">Country</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>

                                  <select
                                    className="form-control"
                                    value={this.state.countrySelected}
                                    onChange={this.handleCountryOptionsChange}
                                  >
                                    <option selected="" disabled="">
                                      Select
                                    </option>
                                    {this.state.CountryOptions.map((option) => (
                                      <option value={option.value}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Post Code</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-map-pin"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handlePostCodeChange}
                                      value={this.state.PostCode}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Manager Details</h3>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Manager Name</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-user"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleMNameChange}
                                      value={this.state.MName}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Phone No.</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-phone-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleMPhoneChange}
                                      value={this.state.MPhone}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 md-4 sm-1">
                                <div className="form-group">
                                  <label>E-mail</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-envelope"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={this.handleMEmailChange}
                                      value={this.state.MEmail}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="card-footer bg-transparent">
                  <Link
                    to="/Admin/AdminSearchCareHome"
                    onClick={this.onCreateCareHome}
                    className="btn btn-primary"
                    id="submitbtn"
                  >
                    Save
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link
                    to="/Admin/AdminIndex"
                    className="btn btn-default"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
              <Footer/>

            </form>

          </div>
        </div>
      </>
    );
  }
}
