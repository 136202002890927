import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import "../Css/Resident.css";
import APIUtil from "../../Util/ApiUtil";
import ReactPaginate from "react-paginate";
import moment from "moment";

export class Expenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expenses: [],
      expenseTypes: [],
      FamilyMemberId: "",
      Expenses: [],
      months: [],
      ExpenseDate: new Date(),
      queryParams: "?Limit=10&Offset=0",
      loading: false,

       //pagination
       Offset: 0,
       perPage: 10,
       currentPage: 0,
       pageNum: 1,
    };

    this.findExpenseTypeNameById = this.findExpenseTypeNameById.bind(this);
    this.refreshList = this.refreshList.bind(this);
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.state.FamilyMemberId=user.Id;
    
    this.refreshList();
  }
 
  findExpenseTypeNameById(id) {
    let expenseType = this.props.expenseTypes.find((type) => type.id === id);

    if (expenseType !== undefined) {
      
      return expenseType.name;
    }
  }


  handlePageClick = (e) => {
    
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    
    this.setState(
      {
        currentPage: selectedPage,
        Offset: offset,
      },
      () => {
        this.refreshList();
      }
    );
    
  };

  

  refreshList() {
    {
      this.setState({ loading: true });
    }
     fetch(APIUtil.BASE_URL + APIUtil.GET_EXPENSE_API+'/'+this.state.FamilyMemberId)
    .then((response) => response.json())
    .then((data) => {

      this.setState({

       expenses: data,
       pageCount: Math.ceil(data.totalCount / this.state.perPage),
       loading: false,
       pageNum: Math.ceil(this.state.Offset / 10),
      });

      
    });
  }

  render() {
    let expenses = [];
    let total = 0;
    {
      this.state.expenses?.map((expense) => {
        total = total + expense.amount;
        expenses.push(
          <tr>
            <td>{this.findExpenseTypeNameById(expense.expenseTypeId)}</td>
            <td>{expense.amount}</td>
            <td>{moment(expense.date).format("DD-MMM-YY")}</td>
          </tr>
        );
      });
    }
    return (
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Expense Report</h3>
        </div>

        <div className="card-body">
        {this.state.loading ? (
                        <div style={{ textAlign: "center" }}>
                          <img src="/dist/img/loader.gif" width="20%" />
                        </div>
                      ) : (
                        <>
          <table id="example2" className="table table-bordered table-hover">
            <thead>
              <tr>
                <th style={{ width: "35%" }}>Type</th>
                <th style={{ width: "35%" }}>Amount</th>
                <th style={{ width: "30%" }}>Date</th>
              </tr>
            </thead>
            <tbody>{expenses}</tbody>
          </table>
          <ReactPaginate
             previousLabel={"prev"}
             nextLabel={"next"}
             breakLabel={"..."}
             breakClassName={"break-me"}
             pageCount={this.state.pageCount}
             forcePage={this.state.pageNum}
             marginPagesDisplayed={2}
             pageRangeDisplayed={5}
             onPageChange={this.handlePageClick}
             containerClassName={"pagination"}
             subContainerClassName={"pages pagination"}
             activeClassName={"active"}
          />
          </>
          )}
        </div>
      </div>
    );
  }
}
