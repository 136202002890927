class ApiUtil {

    static DOWNLOAD_BASE_URL = process.env.REACT_APP_DOWNLOADS;
    static BASE_URL = process.env.REACT_APP_API_URL;
    static AUTH_URL = "/api/Auth";
    static FORGOT_PASSWORD = "/api/Auth/ForgotPassword/";
    static AUTH_RESIDENT_URL = "/api/Auth/Otp/";
    static CHANGE_PASSWORD_SUPERADMIN = "/api/Auth/ChangePassword";
    static VERIFY_OTP = "/api/Auth/ValidateOTP/";
    static GET_USER = "/api/User";

    static ADMIN_API = "/api/admin/";

    // static ADD_ADMIN_MODAL="/api/admin";
    // static EDIT_ADMIN_MODAL="/api/admin/7";
    // static ADMIN_DETAILS="/api/Admin";
    // static ADMIN_DETAILS_BY_ID="/api/Admin/7";
    //static SEARCH_ADMIN="/api/Admin";

    //Admin
    static RESIDENT_API = "/api/Resident";
    static RESIDENT_API_WITH_LIMITS = "/api/Resident?Limit=10&Offset=0";
    //static ADD_RESIDENT = "/api/Resident";
    // static GET_RESIDENT_BY_ID = "/api/Resident/";

    static CARE_HOME_API = "/api/CareHome";
    static CARE_HOME_API_WITH_LIMITS = "/api/CareHome?Limit=10&Offset=0";

    static CARE_HOME_DROPDOWN_API = "/api/CareHome/CareHomesDropdown";

    static CARE_HOME_DETAILS_BY_ID = "/api/CareHome/Detail";
    static CARE_HOME_RESIDENT_DETAILS_BY_ID = "/api/Resident/Detail";
    //static EDIT_RESIDENT_BY_ID = "/api/Resident/";
    static CAREHOME_GROUPS = "/api/Admin/CareHomeGroups";

    static ADMIN_SUMMARY = "/api/Admin/Summary";

    static CARE_HOME_MANAGER_SUMMARY = "/api/CareHome/Manager";

    static ADMIN_CARE_HOME_LIST = "/api/CareHome/CareHomeList";
    static ADD_EXPENSE_MODAL = "/api/Expense";

    //funds
    static Add_FUNDS = "/api/Deposit";

    //Expense Type
    static EXPENSE_TYPE = "/api/ExpenseType";

    //Manager
    static GET_USERDETAILS = "/api/User";

    static POST_USER_ASSOCIATE_MANAGER = "/api/User/AssociateManager";

    static GET_ASSOCIATE_MANAGER_API = "/api/User/AssociateManagers";

    //FamilyMember

    NOTIFICATION_API = "/api/Resident/Notification";

    //Resident

    static RESIDENT_SUMMARY = "/api/Resident/Summary";
    static RESIDENT_NOTIFICATION = "/api/Resident/Notification";
    static CONTACT_US_API = "/api/Resident/ContactUs";
    static GET_EXPENSE_API = "/api/Expense";
    static GET_DEPOSIT_API = "/api/Deposit";
    static GET_TRANSACTION_DETAILS_API = "/api/Resident/Transactions";
    static GET_RESIDENT_REPORT_API = "/api/Resident/ResidentReport";

    static GET_CAREHOME_EXPENSE_API = this.GET_EXPENSE_API + "/CareHome";
    static GET_CAREHOME_PETTYCASH_API = "/api/PettyCash/CareHome";
    static GET_CAREHOME_PETTYCASH_REPORT_API = "/api/PettyCash/CareHomeReport";

    static SAVE_THRESHOLD = "/api/Resident/SaveThreshold";
}

export default ApiUtil;
