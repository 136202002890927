import React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import APIUtil from "../../../Util/ApiUtil";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';


export class ResetPasswordModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminEmail:this.props.adminEmail,
    };
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
  }

  notify() {
    toast.success("We Have send you new password to your Email Id", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  handleSubmit(event){
    event.preventDefault();
    document.getElementById("submitbtn").innerHTML = "Processing...";
    fetch(APIUtil.BASE_URL+APIUtil.FORGOT_PASSWORD+this.state.adminEmail, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then(async response =>{
        if(response.ok){
          await console.log("response is 200");

          //this.notify();
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            icon: 'success',
            title: 'Password changed successfully,Please check your mail'
          })
          
          document.getElementById("submitbtn").innerHTML = "Ok, Reset";
          this.props.onHide();
        } 
        else{
        
          await console.log("failed");
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            icon: 'error',
            title: 'Failed to reset password'
          })
        }
        })
      }
  

  render() {
    return (
      <Modal {...this.props} show={this.props.show} onHide={this.handleClose}>
        <Modal.Header>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
                   <p>
                     Are you sure you want to reset the password for this admin?
                     <br />
                     New password link will be sent to admin's registered E-mail
                     a/c.
                   </p>
        </Modal.Body>

        <Modal.Footer className="justify-content-start">
                  <a id="submitbtn" onClick={this.handleSubmit} className="btn btn-primary">
                     Ok, Reset
                   </a>
                   <button onClick={this.props.onHide} className="btn btn-default" data-dismiss="modal">
                     Cancel
                   </button>
        </Modal.Footer>
      </Modal>
      
    );
  }
}

export default ResetPasswordModal;
