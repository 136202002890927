import React from "react";
import AddFundsModal from "../Modals/Manager/AddFundsModal";
import DeleteResidentModal from "../Modals/Manager/DeleteResidentModal";

import AddResidentExpenseModal from "../Modals/Manager/AddResidentExpenseModal";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import Footer from "../Footer";
import { ManagerNavbar } from "./ManagerNavbar";
import { ManagerSidebar } from "./ManagerSidebar";
import APIUtil from "../../Util/ApiUtil";
import axios from "axios";
import ReactPaginate from "react-paginate";

import { Link } from "react-router-dom";
import AddExpenseModal from "../Modals/Admin/AddExpenseModal";

let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class ManagerSearchResident extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      careHomeUserId: parseInt(careHomeUser.Id),
      residents: [],
      showAddExpenseModal: false,
      showAddFundModal: false,
      showDeleteResident:false,
      addExpenseData: {
        amount: 0,
        date: "",
        expenseTypeId: 0,
        info: "",
        file: {},
      },
      addFundData: {},
      loading: false,
      smloading:false,
      selectedResident: 1,
      selectValue: "1",
      residentId: "",
      RoomNumber: "",
      Name: "",
      Age: "",
      FMName: "",
      FMPhone: "",
      FMEmail: "",
      FMHouseNumber: "",
      FMStreet: "",
      FMTown: "",
      FMCountryOptions: "",
      selectedOption: "",
      FMPostCode: "",
      Balance: 0,
      loading: false,
      DownloadUrl: APIUtil.DOWNLOAD_BASE_URL,
      //pagination
      Offset: 0,
      perPage: 10,
      currentPage: 0,
      pageNum: 1,
      currentSort: 'default',
      SortByRoomNo: 'false',
      SortMethod: '',
      sort_room: 'false',
      sort_method: ''
      
    };

    this.refreshList = this.refreshList.bind(this);

    this.closeResidentExpenseModal = this.closeResidentExpenseModal.bind(this);
    this.addResidentExpenseModal = this.addResidentExpenseModal.bind(this);
    this.addResidentFundModal = this.addResidentFundModal.bind(this);
    this.closeResidentFundModal = this.closeResidentFundModal.bind(this);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeAge = this.onChangeAge.bind(this);
    this.onChangeRoomNumber = this.onChangeRoomNumber.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.getTransactionDetails = this.getTransactionDetails.bind(this);

    this.onChangeBalance = this.onChangeBalance.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.closeDeleteResidentModal = this.closeDeleteResidentModal.bind(this);
    this.closeDeleteResidentModalFail = this.closeDeleteResidentModalFail.bind(this);
    
    this.onClickSearch();
    this.handleDropdownChange = this.handleDropdownChange.bind(this);

  }

  async handleDropdownChange(e) {
    await this.setState(
      { selectValue: e.target.value }
    );
    await this.getTransactionDetails();
  }

  getAge(dateOfBirth){
    var date=new Date(dateOfBirth); 
    var today = new Date();
    var age_now = today.getFullYear() - date.getFullYear();
    var m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) 
    {
        age_now--;
    }
    return age_now;
  }

  addResidentExpenseModal(selectedResident) {
    this.setState({ showAddExpenseModal: true, selectedResident });
  }

  closeResidentExpenseModal() {
    this.setState({ showAddExpenseModal: false, selectedResident: 0 });
    this.refreshList();
  }

  addResidentFundModal(selectedResident) {
    this.setState({ showAddFundModal: true, selectedResident });
  }
  deleteResidentExpenseModal(selectedResident) {
    this.setState({ showDeleteResident: true, selectedResident });
  }
  
  closeResidentFundModal() {
    this.setState({ showAddFundModal: false, selectedResident: 0 });
    this.refreshList();

  }
  closeDeleteResidentModal() {
    this.setState({ showDeleteResident: false, selectedResident: 0 });
    this.refreshList();

  }
  closeDeleteResidentModalFail() {
    this.setState({ showDeleteResident: false, selectedResident: 0 });

  }
  
  onChangeRoomNumber(event) {
    this.setState({
      RoomNumber: event.target.value,
    });
  }

  onChangeName(event) {
    this.setState({
      Name: event.target.value,
    });
  }

  onChangeAge(event) {
    this.setState({
      Age: event.target.value,
    });
  }

  onChangeBalance(event) {
    this.setState({
      Balance: event.target.value,
    });
  }

  componentDidMount() {
    this.refreshList();
    this.getTransactionDetails();
  }

  async refreshList() {

    this.setState({ loading: true });

    let url =
      APIUtil.BASE_URL +
      APIUtil.RESIDENT_API +
      `?Limit=` + this.state.perPage +
      `&Offset=` + this.state.Offset +
      `&Name=` +
      this.state.Name +
      `&Age=` +
      this.state.Age.trim() +
      `&RoomNumber=` +
      this.state.RoomNumber.trim() +
      `&Balance=` +
      this.state.Balance +
      `&CareHomeId=` + careHomeUser.CareHomeId +
      `&SortByRoomNo=` + this.state.sort_room +
      `&SortMethod=` + this.state.sort_method


    let responseData = await axios.get(url);
    this.setState({
      residents: responseData.data.result,
      pageCount: Math.ceil(responseData.data.totalCount / this.state.perPage),
      loading: false,
      pageNum: Math.ceil(this.state.Offset / 10)
    });
  }

  getResidentDetails(SortByRoomNo, SortMethod) {
    this.setState({ loading: true });
    fetch(
      APIUtil.BASE_URL +
      APIUtil.RESIDENT_API +
      `?Limit=` +
      this.state.perPage +
      `&Offset=` +
      this.state.Offset +
      `&Name=` +
      this.state.Name +
      `&Age=` +
      this.state.Age.trim() +
      `&RoomNumber=` +
      this.state.RoomNumber.trim() +
      `&Balance=` +
      this.state.Balance +
      `&CareHomeId=` + careHomeUser.CareHomeId +
      `&SortByRoomNo=` + SortByRoomNo +
      `&SortMethod=` + SortMethod
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            residents: data.result,
            pageCount: Math.ceil(data.totalCount / this.state.perPage),
            loading: false,
            pageNum: Math.ceil(this.state.Offset / 10),
          },
        );
      });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        Offset: offset,
      },
      () => {
        this.refreshList();
      }
    );
  };
  getTransactionDetails() {
    {
      this.setState({ smloading: true })
    }
    fetch(
      APIUtil.BASE_URL +
        APIUtil.GET_RESIDENT_REPORT_API +
        "?" + `CareHomeId=` + careHomeUser.CareHomeId
        +
        "&" +
        this.state.selectValue 
        
    )
    .then((response) => response.json())
    .then((data) => {
      this.setState({
        DownloadUrl:APIUtil.DOWNLOAD_BASE_URL+"/"+data.value.replace("//"+"/"),
        smloading: false,
      });
    });
  }
  onClickSearch = async (event) => {
    {
      this.setState({ loading: true });
    }
    // event.preventDefault();
    let url =
      APIUtil.BASE_URL +
      APIUtil.RESIDENT_API +
      `?Limit=` + this.state.perPage +
      `&Offset=` + 0 +
      `&Name=` +
      this.state.Name +
      `&Age=` +
      this.state.Age.trim() +
      `&RoomNumber=` +
      this.state.RoomNumber.trim() +
      `&Balance=` +
      this.state.Balance +
      `&CareHomeId=` + careHomeUser.CareHomeId +
      `&SortByRoomNo=` + this.state.sort_room +
      `&SortMethod=` + this.state.sort_method


    let responseData = await axios.get(url);
    this.setState({
      residents: responseData.data.result,
      pageCount: Math.ceil(responseData.data.totalCount / this.state.perPage),
      loading: false,
      pageNum: 0,
      Offset:0,
      selectedPage:1
    });
  };

  onSortChange = () => {
    
		const  currentSort  = this.state.currentSort;
		let nextSort;

		if (currentSort === 'up') {
      nextSort = 'default';
      this.state.sort_room = "false";
      this.state.sort_method = "";
      this.getResidentDetails("false","");
    }
		else if (currentSort === 'default') {
      this.state.sort_room = "true";
      this.state.sort_method = "";
      nextSort = 'up';
      this.getResidentDetails("true","");

  }
		this.setState({
			currentSort: nextSort,
		});
	};

   sortTypes = {
    up: {
      class: 'sort-up text-info',
      fn: (a, b) => a.roomNumber > b.roomNumber?1:-1
    },
    default: {
      class: 'sort',
      fn: (a, b) => a.id > b.id?1:-1
    }
  };
  

  render() {
    const  currentSort  = this.state.currentSort;
    console.log(currentSort);
    return (
      <>
        <div
          id="sidebar"
          className="hold-transition sidebar-mini sidebar-collapse"
        >
          <div className="wrapper">
            <ManagerNavbar />
            <ManagerSidebar />
            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Residents</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <a href="Manager-index.html">Home</a>
                        </li>
                        <li className="breadcrumb-item active">Residents</li>
                        <li className="breadcrumb-item active">Search</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Search</h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-md-3">
                              <label htmlFor="inputName">Name</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                value={this.state.Name}
                                onChange={this.onChangeName}
                              />
                            </div>
                            <div className="form-group col-md-2">
                              <label htmlFor="inputName">Age</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                value={this.state.Age}
                                onChange={this.onChangeAge}
                              />
                            </div>
                            <div className="form-group col-md-2">
                              <label htmlFor="inputName">Room No.</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                value={this.state.RoomNumber}
                                onChange={this.onChangeRoomNumber}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="inputName">Balance</label>
                              <select onChange={this.onChangeBalance}
                                className="form-control">
                                <option value='0' selected="" disabled="">
                                  Select
                                </option>
                                <option value='1'>Less than 10,000</option>
                                <option value='2'>10,000-20,000</option>
                                <option value='3'>20,000-30,000</option>
                                <option value='4'>30,000-40,000</option>
                                <option value='5'>40,000-50,000</option>
                                <option value='6'>More than 50,000</option>
                              </select>
                            </div>
                            <div className="form-group col-md-2">
                              <label htmlFor="inputName"></label>
                              <div style={{ padding: ".375rem .75rem" }}>
                                <button
                                  type="button"
                                  className="btn btn-block btn-primary search1"
                                  onClick={this.onClickSearch}
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">

                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Details</h3>
                          <div className="card-tools">
                          
                            <select
                              id="dropdown"
                              onChange={this.handleDropdownChange}

                            >
                              <option value="1">This Month</option>
                              <option value="2">Last Month</option>
                              <option value="3">Last Quarter</option>
                              <option value="4">This Year</option>
                            </select> 
                            {this.state.smloading ? (
                            <img src="/dist/img/spinner.gif"/>
                          ) : 
                            this.state.DownloadUrl!=""?(
                            <a href={this.state.DownloadUrl} target="_blank"  className="btn btn-tool btn-sm" download>
                            <i className="fas fa-download"></i>
                            </a>
                            ):""
                            }
                            
                            <a
                              href="/Manager/ManagerAddResident"
                              className="btn btn-sm btn-secondary"
                            >
                              <i className="fas fa-plus"></i> Add{" "}
                            </a>
                          </div>
                        </div>

                        <div className="card-body">
                          {this.state.loading ? (
                            <img src="/dist/img/loader.gif" width="20%" />
                          ) : (
                            <>
                              <table
                                id="example2"
                                className="table table-bordered table-hover"
                              >
                                <thead>
                                  <tr>
                                    <th style={{ width: "25%" }}>Name</th>
                                    <th style={{ width: "15%" }}>Age</th>
                                    <th style={{ width: "15%" }}>Room No.
                                    <button style={{ border: "0px", backgroundColor: "white" }} onClick={()=>this.onSortChange()}>
									                  <i className={`fas fa-${this.sortTypes[currentSort].class}`} />
								                    </button>
                                    </th>
                                    <th style={{ width: "15%" }}>Phone No.</th>
                                    <th style={{ width: "15%" }}>Balance</th>
                                    <th style={{ width: "15%" }}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.residents.map((resident) => (
                                    <tr key={resident.id}>
                                      <td>
                                        <a
                                          href={
                                            "/Manager/ManagerSingleResidentDetails/" +
                                            resident.id
                                          }
                                        >
                                          {resident.name}
                                        </a>
                                      </td>
                                      <td>{this.getAge(resident.dateOfBirth)}</td>
                                      <td>{resident.roomNumber}</td>
                                      <td>{resident.contact}</td>
                                      <td className="text-success">
                                        {" "}
                                        <i className="fas fa-xs fa-pound-sign"></i>{" "}
                                        {resident.balance}
                                      </td>
                                      <td className="text-centre py-0 align-middle">
                                        <a
                                          href={
                                            "/Manager/ManagerEditResident/" +
                                            resident.id
                                          }
                                        >
                                          <i className="fas fa-edit" title="Edit"></i>
                                        </a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <a
                                          onClick={() =>
                                            this.addResidentFundModal(
                                              resident.id
                                            )
                                          }
                                        >
                                          <i
                                            className="fas fa-sm fa-plus text-primary"
                                            aria-hidden="true"
                                            data-toggle="modal"
                                            data-target="#Modal-add-funds"
                                            title="Add Funds"
                                          ></i>
                                        </a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <a
                                          onClick={() =>
                                            this.addResidentExpenseModal(
                                              resident.id
                                            )
                                          }
                                        >
                                          <i
                                            className="fas fa-wallet text-primary"
                                            aria-hidden="true"
                                            data-toggle="modal"
                                            data-target="#Modal-manage"
                                            title="Add Expense"
                                          ></i>
                                        </a>                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        <a
                                          onClick={() =>
                                            this.deleteResidentExpenseModal(
                                              resident.id
                                            )
                                          }
                                        >
                                          <i
                                            className="fas fa-trash-alt text-danger"
                                            aria-hidden="true"
                                            data-toggle="modal"
                                            data-target="#Modal-manage"
                                            title="Delete Resident"
                                          ></i>
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.pageCount}
                                forcePage={this.state.pageNum}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {this.state.showAddExpenseModal ? (
                        <AddExpenseModal
                          show={this.state.showAddExpenseModal}
                          onHide={this.closeResidentExpenseModal}
                          ResidentId={this.state.selectedResident}
                        />
                      ) : (
                        ""
                      )}
                      {this.state.showAddFundModal ? (
                        <AddFundsModal
                          show={this.state.showAddFundModal}
                          onHide={this.closeResidentFundModal}
                          ResidentId={this.state.selectedResident}
                        />
                      ) : (
                        ""
                      )}
                         {this.state.showDeleteResident ? (
                        <DeleteResidentModal
                          show={this.state.showDeleteResident}
                          onHide={this.closeDeleteResidentModal}
                          onHideFail={this.closeDeleteResidentModalFail}
                          ResidentId={this.state.selectedResident}
                        />
                      ) : (
                        ""
                      )}
                      
                    </div>
                  </div>
                </div>

              </section>
            </div>
            <Footer />

          </div>

        </div>
      </>
    );
  }
}
