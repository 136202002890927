import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import { Link } from "react-router-dom";
import APIUtil from "../../Util/ApiUtil";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Footer } from "../Footer";

let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class AdminSearchCareHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      careHomeUserId: parseInt(careHomeUser.Id),
      careHomes: [],

      addFundsShow: false,

      careHomeId: "",
      Name: "",
      Phone: "",
      Street: "",
      Town: "",
      Country: "",
      PostCode: "",
      MName: "",
      MPhone: "",
      MEmail: "",
      loading: false,

      //pagination
      Offset: 0,
      perPage: 10,
      currentPage: 0,
      pageNum: 1,
    };

    this.refreshList = this.refreshList.bind(this);

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeMName = this.onChangeMName.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);

    this.onClick = this.onClick.bind(this);
    this.onClick();
  }

  componentDidMount() {
    this.refreshList();
  }

  refreshList() {
    {
      this.setState({ loading: true });
    }
    fetch(
      APIUtil.BASE_URL +
      APIUtil.CARE_HOME_API +
      `?Limit=` +
      this.state.perPage +
      `&Offset=` +
      this.state.Offset +
      `&AdminId=` +
      this.state.careHomeUserId
    )
      .then((response) => response.json())
      .then(async (data) => {
        console.log("Limit is", this.state.perPage);

        await this.setState(
          {
            careHomes: data.result,
            pageCount: Math.ceil(data.totalCount / this.state.perPage),
            loading: false,
            pageNum: Math.ceil(this.state.Offset / 10),
          },
          console.log("pageNum", this.state.pageNum)
        );
      });
  }

  handlePageClick = (e) => {
    console.log("inside handlePageClick");
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    console.log(selectedPage, offset);
    this.setState(
      {
        currentPage: selectedPage,
        Offset: offset,
      },
      () => {
        this.refreshList();
      }
    );
    console.log("handle page click", this.state);
  };

  onChangeName(event) {
    this.setState({
      Name: event.target.value,
    });
  }
  onChangeMName(event) {
    this.setState({
      MName: event.target.value,
    });
  }

  onChangePhone(event) {
    this.setState({
      Phone: event.target.value,
    });
  }

  onClick = async (event) => {
    let url =
      APIUtil.BASE_URL +
      APIUtil.CARE_HOME_API_WITH_LIMITS +
      `&CareHome=` +
      this.state.Name +
      `&Manager=` +
      this.state.MName.trim() +
      `&Phone=` +
      this.state.Phone.trim() +
      `&AdminId=` +
      this.state.careHomeUserId;
    let responseData = await axios.get(url);
    this.setState({
      careHomes: responseData.data.result,
      pageCount: Math.ceil(responseData.data.totalCount / this.state.perPage),
      pageNum: 0

    });
  };

  addFundsClose = (value) => {
    this.setState({ addFundsShow: false });
  };

  render() {
    const { careHomes } = this.state;
    return (
      <>
        <div
          id="sidebar"
          className="hold-transition sidebar-mini sidebar-collapse"
        >
          <div className="wrapper">
            <AdminNavbar />
            <AdminSidebar />

            <div className="content-wrapper" style={{ marginLeft: "250px" }}>
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Care Homes</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <a href="/Admin/AdminIndex">Home</a>
                        </li>
                        <li className="breadcrumb-item active">Care Home</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content" style={{ userSelect: "auto" }}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Search</h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-md-3">
                              <label htmlFor="inputName">Care Home</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                value={this.state.Name}
                                onChange={this.onChangeName}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="inputName">Manager</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                value={this.state.MName}
                                onChange={this.onChangeMName}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="inputName">Phone No.</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                value={this.state.Phone}
                                onChange={this.onChangePhone}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="inputName"></label>
                              <div style={{ padding: ".375rem" }}>
                                <button
                                  onClick={this.onClick}
                                  type="button"
                                  className="btn btn-block btn-primary search1"
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Details</h3>
                      <div className="card-tools">
                        <Link
                          to="/Admin/AdminAddCareHome"
                          className="btn btn-sm btn-secondary"
                        >
                          <i className="fas fa-plus"></i> Add{" "}
                        </Link>
                      </div>
                    </div>

                    <div className="card-body">
                      {this.state.loading ? (
                        <div style={{ textAlign: "center" }}>
                          <img src="/dist/img/loader.gif" width="20%" />
                        </div>
                      ) : (
                        <>
                          <table
                            id="example2"
                            className="table table-bordered table-hover"
                          >
                            <thead>
                              <tr>
                                <th style={{ width: "20%" }}>Care Home</th>
                                <th style={{ width: "20%" }}>Manager</th>
                                <th style={{ width: "15%" }}>Phone No.</th>
                                <th style={{ width: "20%" }}>Address</th>
                                <th style={{ width: "15%" }}>
                                  Residents Count
                                </th>
                                <th style={{ width: "10%" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.careHomes.map((careHome) => (
                                <tr key={careHome.id}>
                                  <td>
                                    <a
                                      href={
                                        "/Admin/AdminSingleCareHomeDetailsCard/" +
                                        careHome.id
                                      }
                                    >
                                      {careHome.name}
                                    </a>
                                  </td>
                                  <td>{careHome.manager.name}</td>
                                  <td>{careHome.phone}</td>
                                  <td>
                                    {careHome.street + " " + careHome.town}
                                  </td>
                                  <td>{careHome.residentCount}</td>
                                  <td className="text-centre py-0 align-middle">
                                    <a
                                      className="fas fa-sm fa-edit"
                                      title="Edit"
                                      href={
                                        "/Admin/AdminEditCareHome/" +
                                        careHome.id
                                      }
                                    ></a>{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href={"/Admin/AdminAddResident/" + careHome.id
                                    }>
                                      <i
                                        className="fas fa-sm fa-user-plus"
                                        title="Add Resident"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCount}
                            forcePage={this.state.pageNum}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>

            </div>
            <Footer/>

          </div>

        </div>

      </>
    );
  }
}
