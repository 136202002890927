import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import $ from "jquery";

export class AdminSidebar extends React.Component {
  componentDidMount = () => {
    $(document).on("click", "#sidebaropen", function () {
      if ($("#sidebar").hasClass("sidebar-collapse")) {
        $("#sidebar").removeClass("sidebar-collapse");
        $("#sidebaropen").css("margin-left", "250px");
      } else {
        $("#sidebar").addClass("sidebar-collapse");
        $("#sidebaropen").css("margin-left", "0px");
      }
    });
  };

  render() {
    return (
      <aside
        className="main-sidebar
         sidebar-light-primary elevation-4"
      >
        <a href="#" className="brand-link " align="center">
          <img src="/images/logo-min.png" alt="Transact" />
          <span className="brand-text font-weight-light">
            <img src="/images/logo-lg.png" alt="Transact" />
          </span>
        </a>
        <div className="sidebar" style={{ textAlign: "left" }}>
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <Link to="/Admin/AdminIndex" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>Dashboard</p>
                </Link>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-home"></i>
                  <p>
                    Care Homes
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/Admin/AdminSearchCareHome" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Search</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Admin/AdminAddCareHome" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-house-user"></i>
                  <p>
                    Residents
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="/Admin/AdminSearchResident" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Search</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/Admin/AdminAddResident" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add</p>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

export default AdminSidebar;
