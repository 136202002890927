import React from "react";
import APIUtil from "../../Util/ApiUtil";
let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class CareHomesListTable extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      TotalCount: 0,
      CareHomes: [],
    };

    this.onChangeTotalCount = this.onChangeTotalCount.bind(this);
    this.refreshList = this.refreshList.bind(this);
  }

  componentDidMount() {
    this.refreshList();
  }

  onChangeTotalCount(event) {
    this.setState({
      TotalCount: event.target.value,
    });
  }

  refreshList() {
    fetch(APIUtil.BASE_URL + APIUtil.ADMIN_CARE_HOME_LIST+"?Id="+careHomeUser.Id+"&Limit=5&Offset=0")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          TotalCount: data.totalCount,
          CareHomes: data.careHomes,
        });
      });
  }

  render() {
    let carehomes = [];
    {
      this.state.CareHomes.map((carehome) => {
        carehomes.push(
            <tr key ={carehome.name}>
            <td
            // key={carehome.id}
            >
              {/* <a href={"/Admin/AdminSingleCareHomeDetailsCard/"+carehome.id}> */}
              {carehome.name}
              {/* </a> */}
            </td>
            <td>{carehome.manager}</td>
            <td>{carehome.residentCount}</td>
          </tr>
        );
      });
    }

    return (
      <div className="col-md-6">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Care Homes List</h3>
            <div className="card-tools">
              <span className="text-bold text-md text-primary">
                count : {this.state.TotalCount}
              </span>{" "}
              &nbsp;
            </div>
          </div>

          <div className="card-body">
            <table id="example2" className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th style={{ width: "35%" }}>Care Home</th>
                  <th style={{ width: "30%" }}>Manager</th>
                  <th style={{ width: "15%" }}>Residents Count</th>
                </tr>
              </thead>
              <tbody>{carehomes}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
