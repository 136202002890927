import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import APIUtil from "../../Util/ApiUtil";

export class  ResidentNavbar extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
        UserId:"", 
        Name:"",
        Email:"",
    }

    this.refreshList = this.refreshList.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.state.UserId=user.Id;
    this.refreshList();
  }

  logout = (e) => {

    localStorage.clear();
    window.location.pathname = '/';
    
}

  refreshList() {
   
     fetch(APIUtil.BASE_URL + APIUtil.GET_USER+'/'+this.state.UserId)
    .then((response) => response.json())
    .then((data) => {
        this.setState({
        Name: data.name,
        Email: data.email
      });
    });
  }
  
  render() {
    return (
      
     
          <nav className="main-header navbar navbar-expand-md navbar-light 
          navbar-white">
            <div className="container">
              <a href="#" className="navbar-brand">
                <img src="/images/Logo-Transact-2.png" alt="Transact Logo" />
              </a>
              <div
                className="collapse navbar-collapse order-3"
                id="navbarCollapse"
              >
                <ul className="navbar-nav"></ul>
              </div>
              <ul className="order-1 order-md-3 navbar-nav
               navbar-no-expand ml-auto">
               
                <li
                  className="dropdown user user-menu open" 
                  style={{marginTop: "10px"}}>
                  {" "}
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <img
                      src="/images/avatar.png"
                      className="user-image"
                      alt="User Image"
                    />
                  </a>
                  <ul className="dropdown-menu" style={{left: "inherit", right:"0px"}}>
                    <li className="user-header1 text-white">
                      <p>
                       
                        {this.state.Name}
                        <br/>
                       {this.state.Email} <br />
                        <small>Resident</small>
                      </p>
                    </li>
                    <li className="user-body">
                    
                      <p>
                        <a href="/" onClick={this.logout}>Sign out</a>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
       
     
      
     
    );
  }
}
