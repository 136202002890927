import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import "../Css/Resident.css";
import APIUtil from "../../Util/ApiUtil";

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      Deposits: 0.0,
      Expense: 0.0,
      Balance: 0.0,
      Carehomes: 0,
      rNotification: 0,
    };

    this.onChangeCareHomes = this.onChangeCareHomes.bind(this);
    //this.onChangeTotalResidents = this.onChangeTotalResidents.bind(this);
    this.onChangeBalance = this.onChangeBalance.bind(this);
    this.onChangeDeposits = this.onChangeDeposits.bind(this);
    this.onChangeExpense = this.onChangeExpense.bind(this);

    this.refreshList = this.refreshList.bind(this);
    this.refreshList();

    this.refreshList1 = this.refreshList1.bind(this);
    this.refreshList1();
  }

  onChangeCareHomes(event) {
    this.setState({
      Carehomes: event.target.value,
    });
  }
  onChangeNotification(event) {
    this.setState({
      rNotification: event.target.value,
    });
  }
  onChangeBalance(event) {
    this.setState({
      Balance: event.target.value,
    });
  }
  onChangeDeposits(event) {
    this.setState({
      Deposits: event.target.value,
    });
  }
  onChangeExpense(event) {
    this.setState({
      Expense: event.target.value,
    });
  }

  componentDidMount() {
   
  }

  refreshList() {
    let response = fetch(APIUtil.BASE_URL + APIUtil.RESIDENT_SUMMARY)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Carehomes: data.careHome,
          Balance: data.balance,
          Deposits: data.deposits,
          Expense: data.expenses,
        });

        
      });
  }

  // refreshList1() {
  //   let response= fetch(APIUtil.BASE_URL+APIUtil.RESIDENT_NOTIFICATION)
  //     .then((response) => response.json(console.log("hello", response)))
  //     .then((data) => {
  //       this.setState({
  //         rNotification: data,
  //       });
  //       console.log("Notification data", this.state.response);
  //     });
  // }

  refreshList1() {
    fetch(APIUtil.BASE_URL + APIUtil.RESIDENT_NOTIFICATION)
      .then((response) => response.json())
      .then((data) => {
        
        this.setState({ notifications: data });
      });
  }

  render() {
    return (
      <div>
        {/* <!-- Content Header (Page header) --> */}
        <div className="content-header" style={{ textAlign: "left" }}>
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0"> Home</h1>
              </div>
              {/* <!-- /.col --> */}
            </div>
            {/* <!-- /.row --> */}
          </div>
          {/* <!-- /.container-fluid --> */}
        </div>
        {/* <!-- /.content-header --> */}
        {/* <!-- Main content --> */}
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6 sm-1">
                    <div className="info-box bg-white shadow">
                      <span className="info-box-icon text-success">
                        <i className="fas fa-long-arrow-alt-down"></i>
                      </span>

                      <div className="info-box-content">
                        <span className="info-box-number">
                          {" "}
                          <b className="d-block">
                            <i className="fas fa-xs fa-pound-sign"></i>{" "}
                            {this.state.Deposits}{" "}
                          </b>
                        </span>
                        <span className="info-box-text">Payments</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 sm-1">
                    <div className="info-box bg-white shadow">
                      <span className="info-box-icon text-warning">
                        <i className="fas fa-long-arrow-alt-up"></i>
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-number">
                          {" "}
                          <b className="d-block">
                            <i className="fas fa-xs fa-pound-sign"></i>{" "}
                            {this.state.Expense}
                          </b>
                        </span>
                        <span className="info-box-text">Expenses</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 sm-1">
                    <div className="info-box bg-white shadow">
                      <span className="info-box-icon text-danger">
                        <i className="fas fa-money-bill"></i>
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-number text-danger">
                          <b className="d-block">
                            {" "}
                            <i className="fas fa-xs fa-pound-sign"></i>{" "}
                            {this.state.Balance}
                          </b>
                        </span>
                        <span className="info-box-text">Balance</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 sm-1">
                    <div className="info-box bg-white shadow">
                      <span className="info-box-icon text-primary">
                        <i className="fas fa-home"></i>
                      </span>
                      <div className="info-box-content">
                        <span className="info-box-number">
                          <b className="d-block"> {this.state.Carehomes}</b>
                        </span>
                        <span className="info-box-text">Care Home</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title ">Notifications</h5>
                  </div>
                  {/* <!-- /.card-header --> */}
                  <div className="card-body mt-3 mb-3">
                    <ul className="todo-list" data-widget="todo-list">
                      {this.state.notifications.map((notify) => (
                        <li>
                          {/* <!-- todo text --> */}
                          <span className="text">{notify.data}</span>
                          {/* <!-- Emphasis label --> */}
                          <span className="float-right">
                            <small className="badge badge-danger">2 mins</small>
                          </span>
                        </li>
                      ))}

                      <li>
                        <span className="text">
                          Your balance is below 10,000
                        </span>
                        <span className="float-right">
                          <small className="badge badge-danger"> 4 hours</small>
                        </span>
                      </li>
                      <li>
                        <span className="text">
                          You have reached your goal!
                        </span>
                        <span className="float-right">
                          <small className="badge"> 2 days ago</small>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /.card -->		 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
