import React from "react";
// import '.../Css/Adminlte.min.css';
// import '.../Css/Custom.css';
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import APIUtil from "../../../Util/ApiUtil";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

toast.configure();
let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class EditExpenseTypeModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ExpenseTypeId: this.props.ExpenseTypeId,
            ExpenseTypeName: this.props.ExpenseTypeName,

        }

        this.changeExpenseType = this.changeExpenseType.bind(this);
    }

    saveExpenseType = (event) => {

        event.preventDefault();
        console.log(this.state.ExpenseTypeName);
        if (this.state.ExpenseTypeName.length > 0) {
            fetch(APIUtil.BASE_URL + APIUtil.EXPENSE_TYPE + "/" + this.props.ExpenseTypeId, {
                method: "PUT",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: this.state.ExpenseTypeName,
                    id: this.props.ExpenseTypeId,
                    careHomeId: parseInt(careHomeUser.CareHomeId),

                }),
            })
                .then((response) => {
                    if (response.statusText === 'OK') {
                        this.props.onHide();
                    }
                })
        }
        else {
            Swal.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: "Please enter expense type",
            });
        }

    }

    changeExpenseType(event) {

        this.setState({ ExpenseTypeName: event.target.value });
    }

    render() {
        return (
            <Modal {...this.props} show={this.props.show} onHide={this.handleClose}>
                <Modal.Header>
                    <Modal.Title>Edit Expense Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group col-8">
                            <label>Type</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    {" "}
                                    <span className="input-group-text">
                                        <i className="fas fa-sm fa-hashtag"></i>
                                    </span>{" "}
                                </div>
                                <input type="text" className="form-control" placeholder="Expense Type" defaultValue={this.state.ExpenseTypeName} onChange={this.changeExpenseType} />
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="justify-content-start">
                    <Button onClick={this.saveExpenseType} className="btn btn-primary">Save</Button>
                    <button
                        onClick={this.props.onHide}
                        className="btn btn-default"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default EditExpenseTypeModal;
