import React from "react";
// import "../Css/Adminlte.min.css";
// import "../Css/Custom.css";
import Swal from "sweetalert2";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import APIUtil from "../../../Util/ApiUtil";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
toast.configure();

class AddResidentExpenseModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Expense: 0,
      Date: "",
      postDate: "",
      ExpenseTypeId: 0,
      Info: "",
      CareHomeId: 0,
      ResidentId: this.props.ResidentId,
      selectedOption: "",
      expenseError: "",
      dateError: "",
      expenseTypeError: "",
      addInfoError: "",
      ExpenseTypes: [],
      errors: { amount: "", expenseType: "", info: "" },
      selectedReceipt: null,
      careHomeId: 0,
    };

    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeExpenseTypeId = this.onChangeExpenseTypeId.bind(this);
    this.onChangeInfo = this.onChangeInfo.bind(this);
    this.onChangeCareHomeId = this.onChangeCareHomeId.bind(this);
    this.onChangeResidentId = this.onChangeResidentId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getExpenseTypes = this.getExpenseTypes.bind(this);
    this.onHandleAmount = this.onHandleAmount.bind(this);
  }

  onHandleAmount = (e) => {
    let amount = e.target.value.replace(/\D/, "");
    let errors = this.state.errors;

    if (!Number(amount)) {
      errors.amount = "Invalid Amount";
      this.setState({
        errors,
        [e.target.name]: amount,
      });
    } else {
      errors.amount = "";
      this.setState({
        [e.target.name]: amount,
        errors,
      });
    }
  };

  onChangeDate(date) {
    this.setState({
      dateError: "",
      postDate: moment(date).utc().format(),
      Date: date,
    });
  }
  onChangeExpenseTypeId(event) {
    let errors = this.state.errors;
    if (event.target.value.length > 0) {
      errors.expenseType = "";
      this.setState({
        errors,
        ExpenseTypeId: event.target.value,
      });
    } else {
      errors.expenseType = "Please select expense type";
      this.setState({
        errors,
        ExpenseTypeId: event.target.value,
      });
    }
  }
  onChangeInfo(event) {
    this.setState({
      addInfoError: "",
      Info: event.target.value,
    });
  }
  onChangeCareHomeId(event) {
    this.setState({
      CareHomeId: event.target.value,
    });
  }
  onChangeResidentId(event) {
    this.setState({
      ResidentId: event.target.value,
    });
  }

 

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.getExpenseTypes();
    this.setState({
      ResidentId: this.props.ResidentId,
      careHomeId: user.CareHomeId,
    });
  }

  getExpenseTypes() {
    fetch(APIUtil.BASE_URL + APIUtil.EXPENSE_TYPE, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          
          this.setState({ ExpenseTypes: result });
        },
        (error) => {
          console.log("failed",error);
        }
      );
  }

  valid() {
    let isValid = true;
    if (this.state.Expense == null || this.state.Expense == "") {
      this.setState({ expenseError: "Field cannot be empty" });
      isValid = false;
    } else if (this.state.date == null || this.state.date == "") {
      this.setState({ dateError: "Field can't be empty" });
      isValid = false;
    } else if (this.state.Info == null || this.state.Info == "") {
      this.setState({ addInfoError: "Field can't be empty" });
      isValid = false;
    } else if (
      this.state.ExpenseTypeId == null ||
      this.state.ExpenseTypeId == ""
    ) {
      this.setState({ expenseTypeError: "Field can't be empty" });
      isValid = false;
    }

    return isValid;
  }

  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedReceipt: event.target.files[0] });
  };

  handleSubmit(event) {
    console.log(this.valid())
    if (!this.valid()) {
      return;
    }

    document.getElementById("submitbtn").innerHTML = "Processing...";
     event.preventDefault();

    let formData = new FormData();

    if( this.state.selectedReceipt!=null)
    formData.append(
      "File",
      this.state.selectedReceipt,
      this.state.selectedReceipt.name
    );

    formData.append("Amount", this.state.Expense);
    formData.append("ExpenseTypeId", this.state.ExpenseTypeId);
    formData.append("ResidentId", this.props.ResidentId);
    formData.append("Date", this.state.postDate);
    formData.append("Info", this.state.Info);
    formData.append("CareHomeId", this.state.careHomeId);

    fetch(APIUtil.BASE_URL + APIUtil.ADD_EXPENSE_MODAL, {
      method: "POST",
      body: formData,
    })
      .then(async response =>{
          if (response.ok) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              icon: 'success',
              title: 'Expense added successfully'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
            this.setState({Amount:"", Info: ""});
            this.props.onHide();
        }
        else
        {
          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            icon: 'error',
              title: 'Failed to add Expense'
          })
          document.getElementById("submitbtn").innerHTML = "Save";
         }
        });
        }
    

  render() {
    let options = [];
    {
      this.state.ExpenseTypes.length > 0 &&
        this.state.ExpenseTypes?.map((expense) => {
          if (expense.id == this.state.selectedOption) {
            options.push(
              <option value={expense.id} selected>
                {expense.name}
              </option>
            );
          } else {
            options.push(<option value={expense.id}>{expense.name}</option>);
          }
        });
    }
    return (
      <Modal
        {...this.props}
        show={this.props.show}
        onHide={this.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Expense
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="form-group col-md-6">
                <label>Expense</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-pound-sign"></i>
                    </span>{" "}
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.Expense}
                    name="Expense"
                    onChange={this.onHandleAmount}
                  />
                </div>
                {this.state.errors.amount.length > 0 ? (
                  <div className="text-danger">{this.state.errors.amount}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group col-md-6">
                <label>Date</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-calendar-alt"></i>
                    </span>{" "}
                  </div>

                  <DatePicker
                    selected={this.state.Date}
                    onChange={this.onChangeDate}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label for="inputName">Type</label>
                <select
                  className="form-control"
                  onChange={this.onChangeExpenseTypeId}
                >
                  <option value="" selected="" disabled="">
                    Select
                  </option>
                  {options}
                </select>
                {this.state.errors.expenseType.length > 0 ? (
                  <div className="text-danger">
                    {this.state.errors.expenseType}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group col-md-6">
                <label for="inputMessage">Add Info</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-hashtag"></i>
                    </span>{" "}
                  </div>
                  <textarea
                    id="inputMessage"
                    className="form-control"
                    rows="1"
                    value={this.state.Info}
                    onChange={this.onChangeInfo}
                  ></textarea>
                </div>
                {this.state.errors.info.length > 0 ? (
                  <div className="text-danger">{this.state.errors.info}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label for="exampleInputFile">Upload Receipt</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="exampleInputFile"
                      onChange={this.onFileChange}
                    />
                    <label className="custom-file-label" for="exampleInputFile">
                      Choose file
                    </label>
                  </div>
                  <div className="input-group-append">
                    <span className="input-group-text">Upload</span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <div >
            <button id="submitbtn" onClick={this.handleSubmit} className="btn btn-primary">
              Save
            </button>
            &nbsp;&nbsp;
            <button
              onClick={this.props.onHide}
              className="btn btn-default"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddResidentExpenseModal;
