import React from "react";
// import "../Css/Adminlte.min.css";
// import "../Css/Custom.css";
import Swal from "sweetalert2";
import {Modal, Button, Row, Col, Form} from "react-bootstrap";
import APIUtil from "../../../Util/ApiUtil";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

toast.configure();
let careHomeUser = JSON.parse(localStorage.getItem("user"));

class AddExpenseModal extends React.Component {
    constructor(props) {
        super(props);
        const arr = window.location.href.split("/");
        const rid = parseInt(arr[arr.length - 1]);
                       
        this.state = {
            Expense: 0,
            EnteredDate: this.props.Date === undefined ? new Date() : new Date(this.props.Date),
            postDate: moment(new Date()).utc().format(),
            ExpenseTypeId: 0,
            Info: "",
            ResidentId: 0,
            selectedOption: "",
            expenseError: "",
            dateError: "",
            expenseTypeError: "",
            addInfoError: "",
            ExpenseTypes: [],
            errors: {amount: "", expenseType: "", info: ""},
            selectedReceipt: null,
            careHomeId: 0,
            transactionId: null,
            isAdd: true,
        };
        if (!isNaN(rid)) {
            this.state.ResidentId = rid
        } else if (!isNaN(window.selectedResident)) {
            this.state.ResidentId = window.selectedResident;
        } else if (!isNaN(this.props.selectedResident)) {
            this.state.ResidentId = this.props.selectedResident;
        } else if (!isNaN(this.props.ResidentId)) {
            this.state.ResidentId = this.props.ResidentId;
        } else if (!isNaN(this.props.CareHomeId)) {
            this.setState({careHomeId: this.props.CareHomeId});
        }
        if (!isNaN(this.props.TransactionId)) {
            this.state.transactionId = this.props.TransactionId;
        }
        this.state.Info = this.props.Info;
        if (!isNaN(this.props.Amount)) {
            this.state.Expense = this.props.Amount;
        }
        this.state.postDate = moment(this.state.EnteredDate).utc().format();
        this.state.ExpenseTypeId = this.props.TransactionTypeId;
        this.state.isAdd = this.state.transactionId === null;
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeExpenseTypeId = this.onChangeExpenseTypeId.bind(this);
        this.onChangeInfo = this.onChangeInfo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getExpenseTypes = this.getExpenseTypes.bind(this);
        this.onHandleAmount = this.onHandleAmount.bind(this);
    }

    onHandleAmount = (e) => {
        const amount = e.target.value.replace(/[^0-9\.]+/g, "");

        let errors = this.state.errors;

        if (!Number(amount)) {
            errors.amount = "Invalid Amount";
            this.setState({
                errors,
                [e.target.name]: amount,
            });
        } else {
            errors.amount = "";
            this.setState({
                [e.target.name]: amount,
                errors,
            });
        }
    };

    onChangeDate(date) {
        this.setState({
            dateError: "",
            postDate:  moment(date).utc().format(),
            Date: date,
            EnteredDate: date,
        });
    }

    onChangeExpenseTypeId(event) {
        let errors = this.state.errors;
        if (event.target.value.length > 0) {
            errors.expenseType = "";
            this.setState({
                errors,
                ExpenseTypeId: event.target.value,
            });
        } else {
            errors.expenseType = "Please select expense type";
            this.setState({
                errors,
                ExpenseTypeId: event.target.value,
            });
        }
    }

    onChangeInfo(event) {
        this.setState({
            addInfoError: "",
            Info: event.target.value,
        });
    }


    componentDidMount() {
        let user = JSON.parse(localStorage.getItem("user"));
        if (!isNaN(this.props.CareHomeId))
            this.setState({
                //ResidentId: this.props.ResidentId,
                careHomeId: this.props.CareHomeId,
            });
        this.getExpenseTypes();

    }

    getExpenseTypes() {
        var url = APIUtil.BASE_URL + APIUtil.EXPENSE_TYPE;

        if (!isNaN(careHomeUser.CareHomeId)) {
            url = url + "/" + careHomeUser.CareHomeId
        } else if (!isNaN(this.props.CareHomeId)) {
            this.setState({
                careHomeId: this.props.CareHomeId
            });
            url = url + "/" + this.props.CareHomeId

        }
        fetch(url
            , {
                method: "GET",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => response.json())
            .then(
                (result) => {
                    this.setState({ExpenseTypes: result});
                },
                (error) => {
                    console.log("failed", error);
                }
            );
    }

    valid() {
        if (this.state.Expense == null || this.state.Expense === "" || this.state.Expense === 0.0) {
            Swal.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: "Please fill Expense",
            });
            document.getElementById("submitbtn").innerHTML = "Save";
        } else if (this.state.postDate == null || this.state.postDate === "") {
            Swal.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: "Please fill Date",
            });
            document.getElementById("submitbtn").innerHTML = "Save";
        } else if (this.state.Info == null || this.state.Info === "") {
            Swal.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: "Please fill Info",
            });
            document.getElementById("submitbtn").innerHTML = "Save";
        } else if (this.state.ExpenseTypeId == null || this.state.ExpenseTypeId === "") {
            Swal.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: "Please fill Expense Type",
            });
            document.getElementById("submitbtn").innerHTML = "Save";
        } else {
            return true;
        }

    }

    onFileChange = (event) => {
        // Update the state
        this.setState({selectedReceipt: event.target.files[0]});
        if (event.target.files[0] != null) {
            document.getElementById("fileName").innerText = event.target.files[0].name;
        }
    };

    handleSubmit(event) {
        /* if (!this.valid()) {
          return;
        }*/
        if (this.valid()) {
            document.getElementById("submitbtn").innerHTML = "Processing...";
            event.preventDefault();

            let formData = new FormData();

            // Update the formData object
            if (this.state.selectedReceipt != null)
                formData.append(
                    "File",
                    this.state.selectedReceipt,
                    this.state.selectedReceipt.name
                );
            formData.append("Amount", this.state.Expense);
            formData.append("ExpenseTypeId", this.state.ExpenseTypeId);
            formData.append("ResidentId", this.state.ResidentId);
            formData.append("Date", this.state.postDate);
            formData.append("Info", this.state.Info);
            formData.append("CareHomeId", this.state.careHomeId);
            formData.append("TransactionId", this.state.transactionId);

            fetch(APIUtil.BASE_URL + APIUtil.ADD_EXPENSE_MODAL, {
                method: "POST",
                body: formData,
            })
                .then(async response => {
                    if (response.ok) {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 5000,
                            icon: 'success',
                            title: 'Expense added successfully'
                        })
                        document.getElementById("submitbtn").innerHTML = "Save";
                        this.setState({Amount: "", Info: "", residentId: parseInt(this.props.ResidentId),});
                        this.props.onHide();
                    } else {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 5000,
                            icon: 'error',
                            title: 'Failed to add Expense'
                        })
                        document.getElementById("submitbtn").innerHTML = "Save";
                    }
                });
        }
    }

    render() {
        let options = [];
        {
            this.state.ExpenseTypes.length > 0 &&
            this.state.ExpenseTypes?.map((expense) => {
                if (expense.id === this.state.ExpenseTypeId) {
                    options.push(
                        <option value={expense.id} selected>
                            {expense.name}
                        </option>
                    );
                } else {
                    options.push(<option value={expense.id}>{expense.name}</option>);
                }
            });
        }
        return (
            <Modal
                {...this.props}
                show={this.props.show}
                onHide={this.handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    {this.state.isAdd ? (<Modal.Title id="contained-modal-title-vcenter">
                            Add Expense
                        </Modal.Title>
                    ) : (
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit Expense
                        </Modal.Title>
                    )}


                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label>Expense</label>
                                <span
                                    style={{color: "red", fontSize: "14px"}}
                                >
                  *
                </span>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        {" "}
                                        <span className="input-group-text">
                      <i className="fas fa-sm fa-pound-sign"></i>
                    </span>{" "}
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.Expense}
                                        name="Expense"
                                        onChange={this.onHandleAmount}
                                    />
                                </div>
                                {this.state.errors.amount.length > 0 ? (
                                    <div className="text-danger">{this.state.errors.amount}</div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="form-group col-md-6">
                                <label>Date</label>
                                <span
                                    style={{color: "red", fontSize: "14px"}}
                                >
                  *
                </span>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        {" "}
                                        <span className="input-group-text">
                      <i className="fas fa-sm fa-calendar-alt"></i>
                    </span>{" "}
                                    </div>

                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={this.state.EnteredDate}
                                        onChange={this.onChangeDate}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label for="inputName">Type</label>
                                <span
                                    style={{color: "red", fontSize: "14px"}}
                                >
                  *
                </span>
                                <select
                                    className="form-control"
                                    onChange={this.onChangeExpenseTypeId}
                                >
                                    <option value="" selected="" disabled="">
                                        Select
                                    </option>
                                    {options}
                                </select>
                                {this.state.errors.expenseType.length > 0 ? (
                                    <div className="text-danger">
                                        {this.state.errors.expenseType}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="form-group col-md-6">
                                <label for="inputMessage">Add Info</label>
                                <span
                                    style={{color: "red", fontSize: "14px"}}
                                >
                  *
                </span>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        {" "}
                                        <span className="input-group-text">
                      <i className="fas fa-sm fa-hashtag"></i>
                    </span>{" "}
                                    </div>
                                    <textarea
                                        id="inputMessage"
                                        className="form-control"
                                        rows="1"
                                        value={this.state.Info}
                                        onChange={this.onChangeInfo}
                                    ></textarea>
                                </div>
                                {this.state.errors.info.length > 0 ? (
                                    <div className="text-danger">{this.state.errors.info}</div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        {this.state.isAdd ? (
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="exampleInputFile">Upload Receipt</label>

                                    <div className="input-group">
                                        <div className="custom-file">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="exampleInputFile"
                                                onChange={this.onFileChange}
                                            />
                                            <label id="fileName" className="custom-file-label" htmlFor="exampleInputFile">
                                                Choose file
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ) : (<></>)}                        
                    </form>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <div>
                        <button id="submitbtn" onClick={this.handleSubmit} className="btn btn-primary">
                            Save
                        </button>
                        &nbsp;
                        &nbsp;
                        <button
                            onClick={this.props.onHide}
                            className="btn btn-default"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddExpenseModal;
