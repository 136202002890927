import React from "react";
import Swal from "sweetalert2";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import Footer from "../Footer";
import axios from "axios";
import APIUtil from "../../Util/ApiUtil";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
toast.configure();
let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class AdminEditCareHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      careHomeUserId: parseInt(careHomeUser.Id),
      careHomeId: 0,
      Name: "",
      Phone: "",
      Street: "",
      Town: "",
      FMCountryOptions: ["England", "Scotland", "Wales", "Northern Ireland"],
      countrySelected: "",
      PostCode: "",
      MName: "",
      MPhone: "",
      MEmail: "",
      MId:0
    };

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeStreet = this.onChangeStreet.bind(this);
    this.onChangeTown = this.onChangeTown.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangePostCode = this.onChangePostCode.bind(this);
    this.onChangeMName = this.onChangeMName.bind(this);
    this.onChangeMPhone = this.onChangeMPhone.bind(this);
    this.onChangeMEmail = this.onChangeMEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onChangeName(event) {
    this.setState({
      Name: event.target.value,
    });
  }
  onChangePhone(e) {
    const value = e.target.value.replace(/\D/, "");
    if (e.target.value.length > 11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid phone number",
      });
    } else {
      this.setState({ Phone: value });
    }
  }

  onChangeStreet(event) {
    this.setState({
      Street: event.target.value,
    });
  }
  onChangeTown(event) {
    this.setState({
      Town: event.target.value,
    });
  }
  onChangeCountry(event) {
    this.setState({
      countrySelected: event.target.value,
    });
  }
  onChangePostCode(e) {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    if (e.target.value.length > 7) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid Postal Code",
      });
    } else {
      this.setState({ PostCode: value });
    }
  }
  onChangeMName(event) {
    this.setState({
      MName: event.target.value,
    });
  }
  onChangeMPhone(event) {
    const value = event.target.value.replace(/\D/, "");
    if (event.target.value.length > 11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid Phone number",
      });
    } else {
      this.setState({ MPhone: value });
    }
  }

  onChangeMEmail(event) {
    this.setState({
      MEmail: event.target.value,
    });
  }

  valid() {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (this.state.Name == null || this.state.Name == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: 'Please fill all the mandatory fields"',
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.Phone == null || this.state.Phone == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: 'Please fill all the mandatory fields"',
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.Phone.length > 11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid phone number",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.Street == null || this.state.Street == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: 'Please fill all the mandatory fields"',
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.Town == null || this.state.Town == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: 'Please fill all the mandatory fields"',
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (
      this.state.countrySelected == null ||
      this.state.countrySelected == ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: 'Please fill all the mandatory fields"',
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.PostCode == null || this.state.PostCode == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: 'Please fill all the mandatory fields"',
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.MName == null || this.state.MName == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: 'Please fill all the mandatory fields"',
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.MPhone == null || this.state.MPhone == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: 'Please fill all the mandatory fields"',
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.MPhone.length < 10 && this.state.MPhone.length > 11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid phone number",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (!pattern.test(this.state.MEmail)) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please provide a valid email",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (!this.state.MEmail.includes("@" && ".")) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: 'Please fill all the mandatory fields"',
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else {
      return true;
    }
  }

  componentDidMount() {
    const arr = window.location.href.split("/");
    const careHomeId = parseInt(arr[arr.length - 1]);
    axios
      .get(APIUtil.BASE_URL + APIUtil.CARE_HOME_API + "/" + careHomeId)
      .then((response) => {
        this.setState({
          careHomeId: careHomeId,
          Name: response.data.name,
          Phone: response.data.phone,
          Street: response.data.street,
          Town: response.data.town,
          countrySelected: response.data.country,
          PostCode: response.data.postCode,
          MId: response.data.manager.id,

          MName: response.data.manager.name,
          MPhone: response.data.manager.phone,
          MEmail: response.data.manager.email,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleSubmit(event) {
    document.getElementById("submitbtn").innerHTML = "Processing...";
    event.preventDefault();
    if (this.valid()) {
      fetch(
        APIUtil.BASE_URL + APIUtil.CARE_HOME_API + "/" + this.state.careHomeId,
        {
          method: "PUT",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ModifiedBy: this.state.careHomeUserId,
            CreatedBy: this.state.careHomeUserId,
            id: this.state.careHomeId,
            name: this.state.Name,
            phone: this.state.Phone,
            street: this.state.Street,
            town: this.state.Town,
            country: this.state.countrySelected,
            postCode: this.state.PostCode,
            manager: {
              id:this.state.MId,
              name: this.state.MName,
              phone: this.state.MPhone,
              email: this.state.MEmail,
            },
          }),
        }
      ).then(async (response) => {
        if (response.ok) {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: "Care Home is Edited successfully",
          });
          document.getElementById("submitbtn").innerHTML = "Save";

          this.setState({ Name: "" });
          this.setState({ Phone: "" });
          this.setState({ Street: "" });

          this.setState({ Town: "" });
          this.setState({ countrySelected: "" });
          this.setState({ PostCode: "" });

          this.setState({ MName: "" });
          this.setState({ MPhone: "" });
          this.setState({ MEmail: "" });

          window.location.href = "/Admin/AdminSearchCareHome";
        } else {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: "Failed to Edit Care Home",
          });
          document.getElementById("submitbtn").innerHTML = "Save";
        }
      });
    }
  }

  render() {
    return (
      <>
        <div
          id="sidebar"
          className="hold-transition sidebar-mini sidebar-collapse"
        >
          <div className="wrapper">
            <AdminNavbar />
            <AdminSidebar />

            <form className="form" onSubmit={this.handleSubmit}>
              <div className="content-wrapper">
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1>Edit Care Home</h1>
                      </div>
                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item">
                            <a href="/Admin/AdminIndex">Home</a>
                          </li>
                          <li className="breadcrumb-item active">Care Home</li>
                          <li className="breadcrumb-item active">Edit</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Care Home Details</h3>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>House Name / No.</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-home"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.Name}
                                      onChange={this.onChangeName}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Phone No.</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-phone-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.Phone}
                                      onChange={this.onChangePhone}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Street Name</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-map-marker-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.Street}
                                      onChange={this.onChangeStreet}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Town</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-map-marker-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.Town}
                                      onChange={this.onChangeTown}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                <div
                                  className="form-group"
                                  style={{
                                    select: "-ms-expand",
                                    display: "block",
                                  }}
                                >
                                  <label for="inputName">Country</label>
                                  <select
                                    className="form-control"
                                    value={this.state.countrySelected}
                                    onChange={this.onChangeCountry}
                                  >
                                    <option selected="" disabled="">
                                      Select
                                    </option>
                                    {this.state.FMCountryOptions.map((i) =>
                                      i == this.state.selectedOption ? (
                                        <option value={i} selected>
                                          {i}
                                        </option>
                                      ) : (
                                        <option value={i}>{i}</option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Post Code</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-map-pin"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.PostCode}
                                      onChange={this.onChangePostCode}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Manager Details</h3>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Name</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-user"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.MName}
                                      onChange={this.onChangeMName}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 md-3 sm-1">
                                <div className="form-group">
                                  <label>Phone No.</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-phone-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.MPhone}
                                      onChange={this.onChangeMPhone}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 md-4 sm-1">
                                <div className="form-group">
                                  <label>E-mail</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-envelope"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.MEmail}
                                      onChange={this.onChangeMEmail}
                                      />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="card-footer bg-transparent">
                  <a
                    onClick={this.handleSubmit}
                    className="btn btn-primary"
                    id="submitbtn"
                  >
                    Save
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a  href="/Admin/AdminSearchCareHome" type="submit" className="btn btn-default">
                    Cancel
                  </a>
                </div>
              </div>
              <Footer />

            </form>
          </div>
        </div>

      </>
    );
  }
}
