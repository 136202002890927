import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import "../Css/Resident.css";
import axios from "axios";
import APIUtil from "../../Util/ApiUtil";


export class OTPScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      
      phoneNumber: "",
      otp: "",
      errorMessage:""
    };

    this.handleChangeOTP= this.handleChangeOTP.bind(this);
    this.handleSubmit= this.handleSubmit.bind(this);

  }

  handleChangeOTP(event) {
    this.setState({
      otp: event.target.value,
    });
    
  }

  componentDidMount() {
    
  }

  handleSubmit(event) {
    

    fetch(APIUtil.BASE_URL+APIUtil.VERIFY_OTP+this.state.otp,{

        method: 'GET',
     }).then(response =>{
      if(response.ok) {
        console.log("response is 200");
        this.setState({
          errorMessage: "",
        });
        window.location.href="/ResidentIndex";
      }
      else{
        console.log("response is not 200")
        this.setState({
          errorMessage: "Invalid OTP",
        });
      }
      })
  }

  render() {
    return (
      <div className="hold-transition lockscreen">
        {/* <!-- Automatic element centering --> */}
        <div className="lockscreen-wrapper">
          <div className="lockscreen-logo">
            <img src="/images/Logo-Transact-3.png" alt="Transact Logo" />
          </div>
          {/* <!-- User name --> */}
          <div className="lockscreen-name">OTP sent to your number</div>
          <br />
          {/* <!-- START LOCK SCREEN ITEM --> */}
          <div className="lockscreen-item">
            {/* <!-- lockscreen image --> */}
            <div className="lockscreen-image">
              <img src="/images/avatar.png" alt="User Image" />
            </div>
            {/* <!-- /.lockscreen-image --> */}

            {/* <!-- lockscreen credentials (contains the form) --> */}
            <form className="lockscreen-credentials"
            >
              <div className="input-group">
                <input type="text" className="form-control" placeholder="OTP"
                value={this.state.otp}
                onChange={this.handleChangeOTP}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn"
                  >
                    <div  onClick={this.handleSubmit}>
                      <i className="fas fa-arrow-right text-muted"></i>{" "}
                    </div>
                  </button>
                  <p>{this.state.errorMessage}</p>
                </div>
              </div>
            </form>
            {/* <!-- /.lockscreen credentials --> */}
          </div>
          {/* <!-- /.lockscreen-item --> */}
          <div className="help-block text-center">Didn't recieve OTP?</div>
          <div className="text-center">
            <div>Resend OTP</div>
            <br />
          </div>
          {/* <!--  <div className="lockscreen-footer text-center text-sm">
              </div> --> */}
        </div>
      </div>
    );
  }
}
