import React from "react";
import Swal from "sweetalert2";

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { ManagerNavbar } from "./ManagerNavbar";
import { ManagerSidebar } from "./ManagerSidebar";
import APIUtil from "../../Util/ApiUtil";
import Footer from "../Footer";

let careHomeUser = JSON.parse(localStorage.getItem("user"));


export class ManagerAddResident extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const sDate = new Date(year - 1, month, day - 1)
    this.state = {
      careHomeUserId: parseInt(careHomeUser.Id),
      RoomNumber: "0",
      Name: "",
      Age: 1,
      selectedDate: sDate,
      maxDate: sDate,
      dateOfBirth: moment(sDate).utc().format(),
      FMName: "",
      FMPhoneNumber: "",
      FMEmail: "",
      FMHouseNumber: "",
      FMStreetName: "",
      FMTown: "",
      FMCountryOptions: ["England", "Scotland", "Wales", "Northern Ireland"],
      countrySelected: "",
      FMPostalCode: "",
      UserName: "",
    };


    //this.handleCareHomeChange = this.handleCareHomeChange.bind(this);
    this.handleRoomNumberChange = this.handleRoomNumberChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleAgeChange = this.handleAgeChange.bind(this);
    this.handleFMNameChange = this.handleFMNameChange.bind(this);
    this.handleFMPhoneNumberChange = this.handleFMPhoneNumberChange.bind(this);
    this.handleFMEmailChange = this.handleFMEmailChange.bind(this);
    this.handleFMHouseNumberChange = this.handleFMHouseNumberChange.bind(this);
    this.handleFMStreetNameChange = this.handleFMStreetNameChange.bind(this);
    this.handleFMTownChange = this.handleFMTownChange.bind(this);
    this.handleFMCountryOptionsChange =
      this.handleFMCountryOptionsChange.bind(this);
    this.handleFMPostalCodeChange = this.handleFMPostalCodeChange.bind(this);
    this.onCreateResident = this.onCreateResident.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);

    this.onChangeDate = this.onChangeDate.bind(this);
  };

  // handleCareHomeChange = (e) => {
  //   this.setState({ CareHomeId: e.target.value });
  // };

  handleRoomNumberChange = (e) => {
    const value = e.target.value.replace(/[^0-9a-zA-Z]+/ig, "");
    this.setState({ RoomNumber: value });
  };

  handleNameChange = (e) => {
    this.setState({ Name: e.target.value });
  };

  handleUserNameChange = (e) => {
    this.setState({ UserName: e.target.value });
  };

  handleAgeChange = (e) => {

    this.setState({ Age: e.target.value });
  };

  handleFMNameChange = (e) => {
    this.setState({ FMName: e.target.value });
  };

  onChangeDate(date) {
    try {
      var today = new Date();
      var age_now = today.getFullYear() - date.getFullYear();
      var m = today.getMonth() - date.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
        age_now--;
      }
      this.setState({
        Age: age_now,
        dateError: "",
        dateOfBirth: moment(date).utc().format(),
        selectedDate: date,
      });
    }
    catch {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Enter valid date of birth",
      });
    }

  }

  handleFMPhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/, "");
    if (e.target.value.length > 11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Phone number should not be less than 10-digits and  more than 11-digits",
      });
    } else {
      this.setState({ FMPhoneNumber: value });
    }
  };
  handleFMEmailChange = (e) => {
    this.setState({ FMEmail: e.target.value });
  };

  handleFMHouseNumberChange = (e) => {
    this.setState({ FMHouseNumber: e.target.value });
  };

  handleFMStreetNameChange = (e) => {
    this.setState({ FMStreetName: e.target.value });
  };

  handleFMTownChange = (e) => {
    this.setState({ FMTown: e.target.value });
  };
  handleFMCountryOptionsChange(event) {
    this.setState({
      countrySelected: event.target.value,
    });

  }


  handleFMPostalCodeChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    if (e.target.value.length > 7) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Post Code should not be less than 6-digits and  more than 7-digits",
      });
    } else {
      this.setState({ FMPostalCode: value });
    }
    // const value = e.target.value.replace(/\D/, "");
    // this.setState({ FMPostalCode: value });
  };

  valid() {

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (this.state.Name == null || this.state.Name == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.UserName == null || this.state.UserName == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } 
    else if (this.state.dateOfBirth == null || this.state.dateOfBirth == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill Date of birth",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.Age == null || this.state.Age == "" || this.state.Age == 0) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    else if (this.state.RoomNumber == null || this.state.RoomNumber == "" || this.state.RoomNumber == "0") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    else if (this.state.FMName == null || this.state.FMName == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    else if (this.state.FMPhoneNumber == null || this.state.FMPhoneNumber == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    else if (this.state.FMPhoneNumber.length !== 10 && this.state.FMPhoneNumber.length !== 11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Phone number should not be less than 10-digits and  more than 11-digits",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    else if ((!pattern.test(this.state.FMEmail)) && (this.state.FMEmail.length != 0)) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please provide a valid email",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    else if ((!this.state.FMEmail.includes("@" && ".")) && (this.state.FMEmail.length != 0)) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please provide a valid email",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    else if (
      this.state.FMHouseNumber == null ||
      this.state.FMHouseNumber == ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (
      this.state.FMStreetName == null ||
      this.state.FMStreetName == ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.FMTown == null || this.state.FMTown == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (
      this.state.countrySelected == null ||
      this.state.countrySelected == ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (
      this.state.FMPostalCode == null ||
      this.state.FMPostalCode == ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.FMPostalCode.length !== 6 && this.state.FMPostalCode.length !== 7) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "PostCode should not be less than 6-digits and  more than 7-digits",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    else {
      return true;
    }
  }

  goBack = () => {
    window.history.go(-1)

  }
  onCreateResident = (event) => {
    event.preventDefault();

    document.getElementById("submitbtn").innerHTML = "Processing...";

    if (this.valid()) {

      fetch(APIUtil.BASE_URL + APIUtil.RESIDENT_API, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          CreatedBy: this.state.careHomeUserId,
          name: this.state.Name,
          age: parseInt(this.state.Age),
          roomNumber: this.state.RoomNumber,
          careHomeId: parseInt(careHomeUser.CareHomeId),
          dateOfBirth: this.state.dateOfBirth,
          familyMember: {
            name: this.state.FMName,
            phone: this.state.FMPhoneNumber,
            email: this.state.FMEmail.toLocaleLowerCase(),
            houseNumber: this.state.FMHouseNumber,
            street: this.state.FMStreetName,
            town: this.state.FMTown,
            country: this.state.countrySelected,
            postCode: this.state.FMPostalCode,
            userName: this.state.UserName
          }
        }),
      })
        .then(async (response) => {
          let data = await response.text();

          //  const data= await response.json();
          if (response.ok) {
            Swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: "Resident is added successfully",
            });
            document.getElementById("submitbtn").innerHTML = "Save";

            this.setState({ Name: "" });
            this.setState({ Age: "" });
            this.setState({ RoomNumber: "" });
            this.setState({ FMName: "" });
            this.setState({ FMPhoneNumber: "" });

            this.setState({ FMEmail: "" });
            this.setState({ FMHouseNumber: "" });
            this.setState({ FMStreetName: "" });
            this.setState({ FMTown: "" });
            this.setState({ countrySelected: "" });
            this.setState({ FMPostalCode: "" });
            this.setState({ UserName: ""});

            window.location.href = "/Manager/ManagerSearchResident";
          } else{
            Swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: data,
            });
            console.log(response,data);
            document.getElementById("submitbtn").innerHTML = "Save";
          }
        }).catch(error => {

          Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            icon: 'error',
            title: 'Something went wrong'
          })
          document.getElementById("submitbtn").innerHTML = "Save";
        });
    }
  };



  render() {
    return (
      <>
        <div id="sidebar" className="hold-transition sidebar-mini sidebar-collapse">
          <div className="wrapper">
            <ManagerNavbar />
            <ManagerSidebar />

            <form submit={this.onCreateResident}>
              <div className="content-wrapper">

                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1>Add Resident</h1>
                      </div>
                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item">
                            <a href="/Manager/ManagerIndex">Home</a>
                          </li>
                          <li className="breadcrumb-item active">
                            {" "}
                            Add Residents
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /.container-fluid --> */}
                </section>
                {/* <!-- Main content --> */}
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      {/* <!-- Details --> */}
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Details</h3>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <label>Name</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-user"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.Name}
                                      onChange={this.handleNameChange}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-group">
                                  <label>Date Of Birth</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-hashtag"></i>
                                      </span>{" "}
                                    </div>

                                    <DatePicker
                                      dateFormat="dd-MM-yyyy"
                                      maxDate={moment(this.state.maxDate).toDate()}
                                      selected={this.state.selectedDate}
                                      onChange={this.onChangeDate}
                                      className="form-control"
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-group">
                                  <label>Room No.</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-house-user"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.RoomNumber}
                                      onChange={this.handleRoomNumberChange}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Family Member -->  */}
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Family Member Details</h3>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <label>Name</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-user"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMName}
                                      onChange={this.handleFMNameChange}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Username</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-user"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.UserName}
                                      onChange={this.handleUserNameChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label>Phone No.</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-phone-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMPhoneNumber}
                                      onChange={this.handleFMPhoneNumberChange}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                  <label>E mail</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-envelope"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMEmail}
                                      onChange={this.handleFMEmailChange}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label>House Name / No.</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-map-marker-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMHouseNumber}
                                      onChange={this.handleFMHouseNumberChange}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label>Street Name</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-map-marker-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMStreetName}
                                      onChange={this.handleFMStreetNameChange}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                  <label>Town</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-map-pin"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMTown}
                                      onChange={this.handleFMTownChange}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="inputName">Country</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <select
                                    className="form-control"
                                    value={this.state.countrySelected}
                                    onChange={this.handleFMCountryOptionsChange}
                                    className="form-control"
                                  >
                                    <option selected="" disabled="">
                                      Select
                                    </option>
                                    {this.state.FMCountryOptions.map(
                                      (option) => (
                                        <option value={option.value}>
                                          {option}
                                        </option>
                                      )
                                    )}

                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>Post Code</label>
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    *
                                  </span>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-map-pin"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMPostalCode}
                                      onChange={this.handleFMPostalCodeChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="card-footer bg-transparent">
                  <Link
                    href="#"
                    className="btn btn-primary"
                    onClick={this.onCreateResident}
                    id="submitbtn"
                  >
                    Save
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href="./ManagerIndex"
                    className="btn btn-default"
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </form>
            <Footer />
          </div>

        </div>



      </>
    );
  }
}
