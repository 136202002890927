import React from 'react'
import '../Css/Adminlte.min.css';
import '../Css/Custom.css';
import '../Css/Adminlte.min.css';


export class Dashboard extends React.Component {
    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="row">
=                                <div className="col-6">
                                    <div className="info-box bg-white shadow">
                                        <span className="info-box-icon text-primary"> <i className="fas fa-house-user"></i></span>
                                        <div className="info-box-content">
                                            <span className="info-box-number  text-lg">118</span>
                                            <span className="info-box-text">Residents</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="info-box shadow bg-white">
                                        <span className="info-box-icon text-primary"><i className="fas fa-md fa-money-bill"></i> </span>
                                        <div className="info-box-content">
                                            <span className="info-box-number text-lg"><i className="fas fa-xs fa-pound-sign"></i> 30,000 </span>
                                            <span className="info-box-text">Balance</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="info-box shadow">
                                        <span className="info-box-icon text-success"><i className="fas fa-md fa-long-arrow-alt-down"></i></span>
                                        <div className="info-box-content">
                                            <span className="info-box-number  text-lg"><i className="fas fa-xs fa-pound-sign"></i> 90,000</span>
                                            <span className="info-box-text">Income</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="info-box shadow">
                                        <span className="info-box-icon text-warning"><i className="fas fa-md fa-long-arrow-alt-up"></i></span>
                                        <div className="info-box-content">
                                            <span className="info-box-number  text-lg"><i className="fas fa-xs fa-pound-sign"></i> 60,000</span>
                                            <span className="info-box-text">Expenses</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        )
    }
}