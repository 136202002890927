import React from 'react';
import {BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom';

export class Footer extends React.Component {
    render() {
        return (<footer className="main-footer text-sm">
                <div className="float-right d-none d-sm-block">
                    <a href="https://transactexpenses.freshdesk.com" target="_blank">Need Support?</a>
                    <b>Version</b> 1.1
                </div>
                {/*<strong>Copyright &copy; 2021 <a href="https://minditsystems.com">Mind IT Systems</a></strong>*/}
                {/*All rights reserved.*/}
            </footer>

        )
    }
}

export default Footer;