import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import '../Css/Adminlte.min.css';
import '../Css/Custom.css';
import axios from 'axios';
import {Redirect} from 'react-router'
import APIUtil from "../../Util/ApiUtil";
import Swal from "sweetalert2";

let careHomeUser = JSON.parse(localStorage.getItem("user"));
export class ChangePasswordSA extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      careHomeUserId: parseInt(careHomeUser.Id),
      Email: "",
      Password: "",
      ConfirmPassword: "",

    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangePassword(event) {
    this.setState({
      Password: event.target.value,
    });
  }

  handleChangeConfirmPassword(event) {
    this.setState({
      ConfirmPassword: event.target.value,
    });
  }


  valid(){
    if (this.state.Password == null || this.state.Password == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });  
   }
   else  if (this.state.ConfirmPassword == null || this.state.Password == "") {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      icon: "error",
      title: "Please fill all the mandatory fields",
    });
  }
  else
  if (this.state.Password !== this.state.ConfirmPassword) {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      icon: "error",
      title: "Password and Confirm Password doesnot Match",
      });
  }

  else {
    return true;
  }
}

  handleSubmit = (event) => {
    event.preventDefault();
    document.getElementById("submitbtn").innerHTML = "Processing...";

   
    if(this.valid()){
    fetch(APIUtil.BASE_URL + APIUtil.CHANGE_PASSWORD_SUPERADMIN + '/'+ this.state.Password + '/' + this.state.careHomeUserId, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.ok) {
        await console.log("response is 200");

        //this.notify();
        Swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "success",
          title: "Password changed successfully",
        });

        document.getElementById("submitbtn").innerHTML = "Ok, Reset";
        //this.props.onHide();
      } else {
        await console.log("failed");
        Swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "error",
          title: "Failed to reset password",
        });
      }
    });
  };

}


    render() {
      // if(this.state.reset){
      //   return <Redirect to={'/SuperAdmin/Login'}/>
      // }

      return (
        <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
           <img src="/images/Logo-Transact-3.png" alt="Transact Logo"/>
          </div>
          
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">You are only one step a way from your new password, recover your password now.</p>
        
              <form onSubmit={this.handleSubmit}>
                <div className="input-group mb-3">
                  <input type="password" className="form-control" placeholder="Password"
                 value={this.state.Password}
                 onChange={this.handleChangePassword}/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input type="password" className="form-control" placeholder="Confirm Password"
                 value={this.state.ConfirmPassword}
                 onChange={this.handleChangeConfirmPassword}/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button id="submitbtn" onClick={this.handleSubmit} type="submit" className="btn btn-primary btn-block">Change password</button>
                  </div>
                  
                </div>
              </form>
        
              <p className="mt-3 mb-1">
                <a href="/">Sign In</a>
              </p>
            </div>
            
          </div>
        </div>
        </div>
      )
    }
}