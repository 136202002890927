import React, { Component } from "react";
import APIUtil from "../Util/ApiUtil";
import { CSVLink } from "react-csv";

let careHomeUser = JSON.parse(localStorage.getItem("user"));

class AsyncCsv extends Component {
  constructor(props) {
    super(props);

    this.state = {
        
      //residentId: this.props.match.params.id,
      residentId: parseInt(careHomeUser.Id),
      selectValue: "1",
      data: [],
      loading: false,
    };
    this.csvLinkEl = React.createRef();
    this.headers = [
      { label: "Info", key: "info" },
      { label: "Date", key: "date" },
      { label: "Amount", key: "amount" },
      { label: "Type", key: "type" },
    ];
  }

  getTransactionDetails() {
    const arr = window.location.href.split("/");
    const residentId = parseInt(arr[arr.length - 1]);
      console.log("api url",APIUtil.BASE_URL +
      APIUtil.GET_TRANSACTION_DETAILS_API +
      "/" +
      residentId +
      "/" +
      this.state.selectValue);
    return fetch(
      APIUtil.BASE_URL +
        APIUtil.GET_TRANSACTION_DETAILS_API +
        "/" +
        residentId +
        "/" +
        this.state.selectValue
    ).then((res) => res.json(console.log(res)));
  }
  downloadReport = async () => {
    this.setState({ loading: true });
    const data = await this.getTransactionDetails();
    this.setState(
      {
        data: data.transactionDetailsModel,
        loading: false,
      },
      () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        });
      }
    );
  };

  render() {
    const { data, loading } = this.state;
    return (
      <div>
        <a
           className="btn btn-tool"
          value={
            loading ? "Downloading.." : "Download"
          }
          onClick={this.downloadReport}
          disabled={loading}
        >
          <i className="fas fa-download"></i>
          </a>
        <CSVLink
          headers={this.headers}
          data={data}
          filename={"Transactions.csv"}
          ref={this.csvLinkEl}
        />
      </div>
    );
  }
}

export default AsyncCsv;
