import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import $ from "jquery";
import Utils from '../../Util/Utils';

export class ManagerSidebar extends React.Component {
  
  componentDidMount=()=> {
    $(document).on('click','#sidebaropen',function(){
      if($("#sidebar").hasClass("sidebar-collapse"))
      {
        $("#sidebar").removeClass("sidebar-collapse");
        $('#sidebaropen').css('margin-left','250px');
        
      } 
      else
      {
        $("#sidebar").addClass("sidebar-collapse");
        $('#sidebaropen').css('margin-left','0px');
        
      }
    });
  }
  

  render() {
    let currentUser = Utils.GetUserDetails();
    return (
      <aside className="main-sidebar
       sidebar-light-primary
       elevation-4">
        {/* <!-- Brand Logo --> */}
        <a href="#" className="brand-link " align="center">
          <img src="/images/logo-min.png" alt="Transact" />
          <span className="brand-text font-weight-light">
            <img src="/images/logo-lg.png" alt="Transact" />
          </span>
        </a>
        {/* <!-- Sidebar --> */}
        <div className="sidebar">
          {/* <!-- Sidebar Menu --> */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills 
              nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* <!-- Add icons to the links using the .nav-icon class
                   with font-awesome or any other icon font library --> */}
              <li className="nav-item">
                <Link to="/Manager/ManagerIndex" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>
                    Dashboard
                    </p>
                  </Link>
              </li>
              <li className="nav-item">
                <a href="#"
                 className="nav-link">
                  <i className="nav-icon fas fa-house-user"></i>
                  <p>
                    Residents
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      to="/Manager/ManagerSearchResident"
                      className="nav-link"
                    >
                      <i className="far fa-circle nav-icon"></i>
                      <p>Search</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Manager/ManagerAddResident" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add</p>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to="/Manager/SearchExpense" className="nav-link">
                  <i className="nav-icon fas fa-wallet"></i>
                  <p>Expenses</p>
                </Link>
              </li>
             {currentUser.Role!=null?(currentUser.Role === 'CareHomeManager' ? ( <li className="nav-item">
                <Link to="/Manager/ManagerUserDetails" className="nav-link">
                  <i className="nav-icon fas fa-user"></i>
                  <p>Users</p>
                </Link>
              </li>) : "") :""}
              <li className="nav-item">
                <Link to="/Manager/ExpenseTypeDetails" className="nav-link">
                  <i className="nav-icon fas fa-list-alt"></i>
                  <p>Expense Types</p>
                </Link>
              </li>
            </ul>
          </nav>
          {/* <!-- /.sidebar-menu --> */}
        </div>
        {/* <!-- /.sidebar --> */}
      </aside>
    );
  }
}

export default ManagerSidebar;
