import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";

export class AddExpenseType extends React.Component {
  render() {
    return (
      <h1>Add Expense Type is shifted to Expense Type Details</h1> 
    )
  }
}

export default AddExpenseType;