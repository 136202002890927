import React, { Component } from "react";
import { AddAdminModal } from "../Modals/SuperAdmin/AddAdminModal";
import { EditAdminModal } from "../Modals/SuperAdmin/EditAdminModal";
import { ResetPasswordModal } from "../Modals/SuperAdmin/ResetPasswordModal";
import ActivateOrDeactivateModal from "../Modals/SuperAdmin/ActivateOrDeactivateModal";
import APIUtil from "../../Util/ApiUtil";
import { Navbar } from "./Navbar";
import ReactPaginate from "react-paginate";
import Footer from "../Footer";

import { Button, ButtonToolbar } from "react-bootstrap";
import axios from "axios";

export class DetailsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CareHomeDropdown: [],
      selectedCareHome: "",
      admins: [],
      groups: [],
      addModelShow: false,
      editModelShow: false,
      resetModelShow: false,
      adminId: "",
      Group: ["Group A", "Group B", "Group C", "Group D"],
      selectedGroup: "",
      AdminName: "",
      Email: "",

      //edit states
      eAdminId: "",
      eAdminGroup: "",
      eAdminName: "",
      eAdminEmail: "",
      eAdminPhone: "",
      eAdminisActive: "",

      rAdminEmail: "",

      ActivateOrDeactivateModalShow: false,
      isActive:2,
      //pagination
      Offset: 0,
      perPage: 10,
      currentPage: 0,
      pageNum: 1,
    };

    this.refreshList = this.refreshList.bind(this);
    this.refreshList();
    this.handlePageClick = this.handlePageClick.bind(this);

    this.onChangeAdminGroup = this.onChangeAdminGroup.bind(this);
    //this.onChangeAdminGroup = this.isActiveLoad.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);

    this.onChangeAdminName = this.onChangeAdminName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeIsActive = this.onChangeIsActive.bind(this);
    
    this.onClick = this.onClick.bind(this);
    this.onClick();
    this.onChangeCareHomeDropdown = this.onChangeCareHomeDropdown.bind(this);
    this.onChangeCareHomeDropdown();
  }

  componentDidMount() {
    this.refreshList();
  }

  refreshList() {
    fetch(APIUtil.BASE_URL + APIUtil.ADMIN_API +
      `?Limit=` + this.state.perPage +
      `&isActive=` +this.state.isActive+

      `&Offset=` + this.state.Offset)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          // TotalAmount: data.totalCount,
          admins: data.result,

          pageCount: Math.ceil(data.totalCount / this.state.perPage),
          loading: false,
          pageNum: Math.ceil(this.state.Offset / 10),
        });
      }).catch( (ex)=>{
        return;
      });
  }

  handlePageClick = (e) => {

    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        Offset: offset,
      },
      () => {
        this.refreshList();
      }
    );
  };
  onChangeAdminGroup(event) {
    //value: event.target.value
    this.setState({
      selectedGroup: event.target.value,
    });
  }

  onChangeAdminName(event) {
    this.setState({
      AdminName: event.target.value,
    });
  }
  onChangeEmail(event) {
    this.setState({
      Email: event.target.value,
    });
  }
  onChangeIsActive(event) {
    this.setState({
      isActive: event.target.value,
    });
  }
  
  onClick = async (event) => {
    this.setState(
      {
        currentPage: 1,
        Offset: 0,
      });
    let url =
      APIUtil.BASE_URL +
      APIUtil.ADMIN_API +
      `?Limit=` + this.state.perPage +
      `&Offset=0` +
      `&isActive=` +this.state.isActive+
      "&Group=" +
      this.state.selectedCareHome +
      "&AdminName=" +
      this.state.AdminName.trim() +
      "&Email=" +
      this.state.Email.trim();

    let responseData = await axios.get(url);

    this.setState({
      admins: responseData.data.result,
      
      pageCount: Math.ceil(responseData.data.totalCount / this.state.perPage),
      loading: false,
      pageNum: Math.ceil(this.state.Offset / 10),
    });
  };

  onChangeCareHomeDropdown() {

    fetch(APIUtil.BASE_URL + APIUtil.CAREHOME_GROUPS)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          CareHomeDropdown: data,
        });

      });
  }

  ActivateOrDeactivateModalCloseFunction = (value) => {
    this.setState({ ActivateOrDeactivateModalShow: value });
  };

  addModelClose = (value) => {
    this.setState({ addModelShow: false });
  };
  editModelClose = (value) => this.setState({ editModelShow: false });

  resetModelClose = (value) => {
    this.setState({ resetModelShow: false });
  };

  ActivateOrDeactivateModalClose = () =>
    this.setState({ ActivateOrDeactivateModalShow: false });

  onChangeCareHome = (e) => {
    this.setState({
      selectedCareHome: e.target.value,
    });
  };

  render() {
    const { groups } = this.state;

    return (
      <div
        id="sidebar"
        className="hold-transition layout-top-nav"
        {...this.props}
      >
        <div className="wrapper">
          <Navbar />
          <div className="content-wrapper">
            <div className="container">
              <div className="content-header" style={{ textAlign: "left" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-8">
                      <h1 className="mb-1">
                        {" "}
                        Manage <small> Care Home Admins</small>
                      </h1>
                      <p className="text-muted mb-0">
                        You can add/modify the Admins here{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  {/* <!-- Default box --> */}
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Search</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group col-md-3">
                          <label>Group</label>
                          <div className="input-group">
                            <select
                              className="form-control"
                              value={this.state.selectedCareHome}
                              onChange={this.onChangeCareHome}
                            >
                              <option  value="" selected="" disabled="">
                                Select
                              </option>
                              {this.state.CareHomeDropdown.map((careHome) => (
                                <option value={careHome}>
                                  {careHome}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-group col-md-2">
                          <label for="inputName">Admin Name</label>
                          <input
                            type="text"
                            id="inputName"
                            className="form-control"
                            value={this.state.AdminName}
                            onChange={this.onChangeAdminName}
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label for="inputName">E-mail</label>
                          <input
                            type="text"
                            id="inputName"
                            className="form-control"
                            value={this.state.Email}
                            onChange={this.onChangeEmail}
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <label>Status</label>
                          <div className="input-group">
                            <select
                              className="form-control"
                              onChange={this.onChangeIsActive}
                            >
                              <option  value="2" selected="" disabled="">
                                Select
                              </option>
                                <option value="0">In Active
                                </option>
                                <option value="1">Active
                                </option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group col-md-2">
                          <label for="inputName"></label>
                          <div style={{ padding: ".375rem .75rem" }}>
                            <button
                              onClick={this.onClick}
                              type="button"
                              className="btn btn-block btn-primary search1"
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Details</h3>
                    <div className="card-tools">
                      <Button
                        className="btn btn-sm btn-secondary"
                        onClick={() => this.setState({ addModelShow: true })}
                        aria-hidden="true"
                        data-toggle="modal"
                        data-target="#Modal-form-add"
                      >
                        <i className="fas fa-plus"></i>     &nbsp;
                        Add
                      </Button>
                      {this.state.addModelShow ? (
                        <AddAdminModal
                          show={this.state.addModelShow}
                          onHide={this.addModelClose}
                          refreshList={this.refreshList}
                        />
                      ) : null}
                    </div>
                  </div>

                  {/* <!-- /.card-header --> */}
                  <div className="card-body">
                    <table
                      id="example2"
                      className="table table-bordered table-hover"
                    >
                      <thead >
                        <tr>
                          <th style={{ width: "15%" }}>Group</th>
                          <th style={{ width: "15%" }}>Care Homes</th>
                          <th style={{ width: "15%" }}>Name</th>
                          <th style={{ width: "15%" }}>E-mail</th>
                          <th style={{ width: "15%" }}>Phone No.</th>
                          <th style={{ width: "10%" }}>Status</th>
                          <th style={{ width: "15%" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.admins.map((admin) => (
                          <tr key={admin.id}>
                            <td>{admin.group}</td>
                            <td>{admin.careHomes}</td>
                            <td>{admin.name}</td>
                            <td>{admin.email}</td>

                            <td>{admin.phone}</td>

                            <td id="tddata">
                              <span
                                className={
                                  admin.isActive
                                    ? "badge bg-primary"
                                    : "badge bg-gray"
                                }
                              >
                                {admin.isActive ? "Active" : "Inactive"}
                              </span>
                              {/* {admin.isActive.toString()} */}
                            </td>

                            <td className="text-centre py-0 align-middle">
                              <a
                                href="#"
                                className="fas fa-sm fa-edit"
                                onClick={() =>
                                  this.setState({
                                    editModelShow: true,
                                    eAdminId: admin.id,
                                    eAdminGroup: admin.group,
                                    eAdminName: admin.name,
                                    eAdminEmail: admin.email,
                                    eAdminPhone: admin.phone,
                                    eAdminisActive: admin.isActive,
                                  })
                                }
                                aria-hidden="true"
                                data-toggle="modal"
                                data-target="#Modal-form-edit"
                                title="Edit"
                              ></a>
                              &nbsp;&nbsp;
                              &nbsp;&nbsp;
                              &nbsp;&nbsp;
                              <a href="#">
                                <i
                                  className="fas fa-sm fa-unlock-alt"
                                  onClick={() =>
                                    this.setState({
                                      resetModelShow: true,
                                      eAdminId: admin.id,
                                      eAdminGroup: admin.group,
                                      eAdminName: admin.name,
                                      eAdminEmail: admin.email,
                                      eAdminPhone: admin.phone,
                                      eAdminisActive: admin.isActive,
                                    })
                                  }
                                  aria-hidden="true"
                                  data-toggle="modal"
                                  data-target="#modal-default"
                                  title="Reset Password"
                                ></i>
                              </a>
                              &nbsp;&nbsp;
                              &nbsp;&nbsp;
                              &nbsp;&nbsp;
                              <a href="#">
                                <i
                                  className={
                                    admin.isActive
                                      ? "fas fa-sm fa-user-times text-primary"
                                      : "fas fa-sm fa-user-times text-gray"
                                  }
                                  aria-hidden="true"
                                  data-toggle="modal"
                                  data-target="#modal-deactivate"
                                  title={
                                    admin.isActive ? "Deactivate" : "Activate"
                                  }
                                  onClick={() =>
                                    this.setState({
                                      ActivateOrDeactivateModalShow: true,
                                      eAdminId: admin.id,
                                      eAdminGroup: admin.group,
                                      eAdminName: admin.name,
                                      eAdminEmail: admin.email,
                                      eAdminPhone: admin.phone,
                                      eAdminIsActive: admin.isActive,
                                    })
                                  }
                                ></i>
                              </a>
                            </td>
                          </tr>
                        ))}

                        {this.state.editModelShow && (
                          <EditAdminModal
                            show={this.state.editModelShow}
                            onHide={this.editModelClose}
                            onClick={this.onClick}
                            adminId={this.state.eAdminId}
                            adminGroup={this.state.eAdminGroup}
                            adminName={this.state.eAdminName}
                            adminEmail={this.state.eAdminEmail}
                            adminPhone={this.state.eAdminPhone}
                            adminIsActive={this.state.eAdminIsActive}
                            action={this.state.action}
                          />
                        )}

                        {this.state.resetModelShow && (
                          <ResetPasswordModal
                            show={this.state.resetModelShow}
                            onHide={this.resetModelClose}
                            adminEmail={this.state.eAdminEmail}
                          />
                        )}

                        {this.state.ActivateOrDeactivateModalShow && (
                          <ActivateOrDeactivateModal
                            show={this.state.ActivateOrDeactivateModalShow}
                            onHide={this.ActivateOrDeactivateModalClose}
                            onClick={this.onClick}
                            adminId={this.state.eAdminId}
                            adminGroup={this.state.eAdminGroup}
                            adminName={this.state.eAdminName}
                            adminEmail={this.state.eAdminEmail}
                            adminPhone={this.state.eAdminPhone}
                            adminIsActive={this.state.eAdminIsActive}
                            action={this.state.action}
                          />
                        )}
                      </tbody>
                    </table>
                    <div className="dataTables_paginate paging_simple_numbers" >
                    <ReactPaginate
                      previousLabel={"prev"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      forcePage={this.state.pageNum}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"paginate_button page-item "}
                      activeClassName={"active"}
                    />
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

      </div>
    );
  }
}

export default DetailsAdmin;
