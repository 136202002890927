import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { ForgotPassword } from './Components/ForgotPassword';

import { ResetPasswordModal } from './Components/Modals/SuperAdmin/ResetPasswordModal';
import { Login } from './Components/Login';
import { Dashboard } from './Components/AdminComponents/Dashboard';
import { AdminNavbar } from './Components/AdminComponents/AdminNavbar';
import { AdminSidebar } from './Components/AdminComponents/AdminSidebar';
import { LoginResident } from './Components/ResidentComponents/LoginResident';
import { ContactUs } from './Components/AdminComponents/ContactUs';

import { Payments } from './Components/ResidentComponents/Payments';
import { Expenses } from './Components/ResidentComponents/Expenses';
import { Home } from './Components/ResidentComponents/Home';
import { OTPScreen } from './Components/ResidentComponents/OTPScreen';
import { ResidentNavbar } from './Components/ResidentComponents/ResidentNavbar';

import { ResidentIndex } from './Components/ResidentComponents/ResidentIndex';
import { ManagerIndex } from './Components/ManagerComponents/ManagerIndex';
import { AdminIndex } from './Components/AdminComponents/AdminIndex';
import { AdminIndexCards } from './Components/AdminComponents/AdminIndexCards';
import { AdminAddCareHome } from './Components/AdminComponents/AdminAddCareHome';
import { AdminSearchCareHome } from './Components/AdminComponents/AdminSearchCareHome';
import { AdminEditCareHome } from './Components/AdminComponents/AdminEditCareHome';
import { AdminAddResident } from './Components/AdminComponents/AdminAddResident';
import { AdminEditResident } from './Components/AdminComponents/AdminEditResident';
import { AdminSingleCareHomeDetailsCard } from './Components/AdminComponents/AdminSingleCareHomeDetailsCard';
import { DetailsAdmin } from './Components/SuperAdminComponents/DetailsAdmin';
import { AdminSearchResident } from './Components/AdminComponents/AdminSearchResident';
import { ManagerForgotPassword } from './Components/ManagerComponents/ManagerForgotPassword';

import { ManagerAddResident } from './Components/ManagerComponents/ManagerAddResident';
import { ManagerEditResident } from './Components/ManagerComponents/ManagerEditResident';

import { ManagerSearchResident } from './Components/ManagerComponents/ManagerSearchResident';

import { ManagerDetails } from './Components/ManagerComponents/ManagerDetails';
import { ExpenseTypesDetails } from './Components/ManagerComponents/ExpenseTypesDetails';
import { ManagerUserDetails } from './Components/ManagerComponents/ManagerUserDetails';
import { SearchExpense } from './Components/ManagerComponents/SearchExpense';


import { ChangePasswordSA } from './Components/SuperAdminComponents/ChangePasswordSA';
import { AdminSingleResidentDetails } from './Components/AdminComponents/AdminSingleResidentDetails';

import { ManagerSingleResidentDetails } from './Components/ManagerComponents/ManagerSingleResidentDetails';

import ProtectedRoute from './Components/ProtectedRoute';


class App extends React.Component {
  render() {
    return (
      <div className="App">



        {/* SuperAdmin Routes  */}
        <Router>
          <Switch>
            <ProtectedRoute exact path="/SuperAdmin/DetailsAdmin" exact component={DetailsAdmin} />
            <Route exact path="/Login" exact component={Login} />

            <Route exact path="/" exact component={Login} />
            <Route exact path="/ForgotPassword" exact component={ForgotPassword} />
            <ProtectedRoute exact path="/ResetPasswordModal" exact component={ResetPasswordModal} />
            <ProtectedRoute exact path="/ChangePasswordSA" exact component={ChangePasswordSA} />

            {/* Admin Routes  */}
            <ProtectedRoute exact path="/Admin/AdminIndex" exact component={AdminIndex} />

            <ProtectedRoute exact path="/Admin/ContactUs" exact component={ContactUs} />
            <ProtectedRoute exact path="/Admin/AdminIndexCards" exact component={AdminIndexCards} />
            <ProtectedRoute exact path="/Admin/AdminAddCareHome" exact component={AdminAddCareHome} />
            {/* <ProtectedRoute exact path="/Admin/AdminEditCareHome" exact component={AdminEditCareHome} /> */}
            <ProtectedRoute exact path="/Admin/AdminSearchCareHome" exact component={AdminSearchCareHome} />
            <ProtectedRoute exact path='/Admin/AdminEditCareHome/:id' exact component={AdminEditCareHome} />
            <ProtectedRoute exact path="/Admin/AdminAddResident" exact component={AdminAddResident} />
            <ProtectedRoute exact path="/Admin/AdminAddResident/:id" exact component={AdminAddResident} />
            <ProtectedRoute exact path="/Admin/AdminEditResident/:id" exact component={AdminEditResident} />
            <ProtectedRoute exact path="/Admin/AdminSearchResident" exact component={AdminSearchResident} />
            <ProtectedRoute exact path="/Admin/AdminSingleCareHomeDetailsCard/:id" exact component={AdminSingleCareHomeDetailsCard} />
            <ProtectedRoute exact path="/Admin/AdminSingleResidentDetails/:id" exact component={AdminSingleResidentDetails} />

            {/*Manager Routes */}
            <ProtectedRoute exact path="/Manager/ManagerIndex" exact component={ManagerIndex} />

            <ProtectedRoute exact path="/Manager/ManagerAddResident" exact component={ManagerAddResident} />
            <ProtectedRoute exact path="/Manager/ManagerEditResident/:id" exact component={ManagerEditResident} />

            <ProtectedRoute exact path="/Manager/ManagerSearchResident" exact component={ManagerSearchResident} />


            <ProtectedRoute exact path="/ManagerDetails" exact component={ManagerDetails} />
            <ProtectedRoute exact path="/Manager/SearchExpense" exact component={SearchExpense} />
            <ProtectedRoute exact path="/Manager/ManagerUserDetails" exact component={ManagerUserDetails} />
            <ProtectedRoute exact path="/Manager/ExpenseTypeDetails" exact component={ExpenseTypesDetails} />

            <ProtectedRoute exact path="/Manager/ManagerSingleResidentDetails/:id" exact component={ManagerSingleResidentDetails} />
            {/* Resident Routes */}
            <ProtectedRoute exact path="/Resident/OTPScreen" exact component={OTPScreen} />

            <ProtectedRoute exact path="/Resident/ResidentIndex" exact component={ResidentIndex} />

          </Switch>
        </Router>




      </div>

    );
  }
}

export default App;
