import React from "react";
import AddFundsModal from "../Modals/Admin/AddFundsModal";
import AddExpenseModal from "../Modals/Admin/AddExpenseModal";
import DeleteResidentModal from "../Modals/Manager/DeleteResidentModal";

import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import ReactPaginate from "react-paginate";
import APIUtil from "../../Util/ApiUtil";
import axios from "axios";
import { Footer } from "../Footer";
import Swal from "sweetalert2";

let careHomeUser = JSON.parse(localStorage.getItem("user"));
export class AdminSearchResident extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      careHomeUserId: parseInt(careHomeUser.Id),
      CareHomeDropdown: [],
      selectedCareHome: "",
      residents: [],
      showAddExpenseModal: false,
      showAddFundModal: false,
      showDeleteResident:false,
      selectValue: "1",
      addExpenseData: {
        amount: 0,
        date: "",
        expenseTypeId: 0,
        info: "",
        file: {},
      },
      addFundData: {},
      loading: false,
      selectedResident: 0,
      CareHomeId: 0,
      residentId: "",
      RoomNumber: "",
      Name: "",
      Age: "",
      FMName: "",
      FMPhone: "",
      FMEmail: "",
      FMHouseNumber: "",
      FMStreet: "",
      FMTown: "",
      FMCountryOptions: "",
      selectedOption: "",
      FMPostCode: "",
      Balance: 0,
      loading: false,
      smloading:false,
      selectValue: "1",
      DownloadUrl: APIUtil.DOWNLOAD_BASE_URL,
      currentSort: 'default',
      //pagination
      Offset: 0,
      perPage: 10,
      currentPage: 0,
      pageNum: 1,
      CareHome: "",
      SortByRoomNo: 'false',
      SortMethod: '',
      sort_room: 'false',
      sort_method: ''

      
    };

    this.refreshList = this.refreshList.bind(this);

    this.closeResidentExpenseModal = this.closeResidentExpenseModal.bind(this);
    this.addResidentExpenseModal = this.addResidentExpenseModal.bind(this);
    this.addResidentFundModal = this.addResidentFundModal.bind(this);
    this.closeResidentFundModal = this.closeResidentFundModal.bind(this);
    this.onChangeCareHome = this.onChangeCareHome.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeAge = this.onChangeAge.bind(this);
    this.onChangeBalance = this.onChangeBalance.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.closeDeleteResidentModal = this.closeDeleteResidentModal.bind(this);
    this.closeDeleteResidentModalFail = this.closeDeleteResidentModalFail.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.getTransactionDetails = this.getTransactionDetails.bind(this);

    this.onChangeCareHomeDropdown = this.onChangeCareHomeDropdown.bind(this);
    this.onChangeCareHomeDropdown();
  }

  componentDidMount() {
    this.refreshList();
    this.onClickSearch();
    this.getTransactionDetails();

  }
  
  addResidentExpenseModal(selectedResident,careHomeId) {
    window.selectedResident = selectedResident;
    this.setState({ showAddExpenseModal: true, selectedResident,CareHomeId:careHomeId });
  }

  closeResidentExpenseModal() {
    this.setState({ showAddExpenseModal: false, selectedResident: 0 });
    this.refreshList();
  }

  addResidentFundModal(selectedResident,careHomeId) {
    this.setState({ showAddFundModal: true, selectedResident,CareHomeId:careHomeId });
  }

  closeResidentFundModal() {
    this.setState({ showAddFundModal: false, selectedResident: 0 });
    this.refreshList();

  }
  deleteResidentExpenseModal(selectedResident) {
    this.setState({ showDeleteResident: true, selectedResident });
  }
  closeDeleteResidentModal() {
    this.setState({ showDeleteResident: false, selectedResident: 0 });
    this.refreshList();

  }
  closeDeleteResidentModalFail() {
    this.setState({ showDeleteResident: false, selectedResident: 0 });
  }
  
  async refreshList() {
    {
      this.setState({ loading: true });
    }
  let url =
    APIUtil.BASE_URL +
    APIUtil.RESIDENT_API +
    `?Limit=` + this.state.perPage +
    `&Offset=` + this.state.Offset +
    `&CareHome=` +
    this.state.selectedCareHome +
    `&Name=` +
    this.state.Name.trim() +
    `&Age=` +
    this.state.Age.trim() +
    `&Balance=` +
    this.state.Balance +
    `&AdminId=` +
    this.state.careHomeUserId +
    `&SortByRoomNo=` + this.state.sort_room +
    `&SortMethod=` + this.state.sort_method
    ;
  let responseData = await axios.get(url);
  this.setState({
    residents: responseData.data.result,
    pageCount: Math.ceil(responseData.data.totalCount / this.state.perPage),
    loading: false,
    pageNum: Math.ceil(this.state.Offset / 10),
  });
};
  handlePageClick = (e) => {
    console.log("inside handlePageClick");
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        Offset: offset,
      },
      () => {
        this.refreshList();
      }
    );
    console.log("handle page click", this.state);
  };
 
  getTransactionDetails() {
    {
      this.setState({ smloading: true })
    }
    fetch(
      APIUtil.BASE_URL +
        APIUtil.GET_RESIDENT_REPORT_API +
        "?" + `&AdminId=` + this.state.careHomeUserId
        +
        "&" +
        this.state.selectValue 
        
    )
    .then((response) => response.json())
    .then((data) => {
      this.setState({
        DownloadUrl:APIUtil.DOWNLOAD_BASE_URL+"/"+data.value.replace("//"+"/"),
        smloading: false,
      });
    });
  }

  onChangeCareHome = (e) => {
    this.setState({
      selectedCareHome: e.target.value,
    });
  };

  onChangeRoomNumber(event) {
    this.setState({
      RoomNumber: event.target.value,
    });
  }

  onChangeName(event) {
    this.setState({
      Name: event.target.value,
    });
  }

  onChangeAge(event) {
    const value = event.target.value.replace(/\D/, "");
    if (value.length > 3) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Enter valid age",
      });
    } else {
      this.setState({ Age: value });
    }
  }

  onChangeBalance(event) {
    this.setState({
      Balance: event.target.value,
    });
  }

  onClickSearch = async (event) => {
      {
        this.setState({ loading: true });
      }
    let url =
      APIUtil.BASE_URL +
      APIUtil.RESIDENT_API +
      `?Limit=` + this.state.perPage +
      `&Offset=` +0 +
      `&CareHome=` +
      this.state.selectedCareHome +
      `&Name=` +
      this.state.Name.trim() +
      `&Age=` +
      this.state.Age.trim() +
      `&Balance=` +
      this.state.Balance +
      `&AdminId=` +
      this.state.careHomeUserId +
      `&SortByRoomNo=` + this.state.sort_room +
      `&SortMethod=` + this.state.sort_method
      ;
    let responseData = await axios.get(url);
    this.setState({
      residents: responseData.data.result,
      pageCount: Math.ceil(responseData.data.totalCount / this.state.perPage),
      loading: false,
      pageNum: 0,
      Offset:0,
      selectedPage:1
    });
  };

  onChangeCareHomeDropdown() {

    fetch(APIUtil.BASE_URL + APIUtil.CARE_HOME_DROPDOWN_API+"/"+careHomeUser.Id)
      .then((response) => response.json())
      .then((data) => {

        this.setState({
          CareHomeDropdown: data,
        });
      });
  }

  async handleDropdownChange(e) {
    await this.setState(
      { selectValue: e.target.value },
    );
    await this.getTransactionDetails();
  }

  getAge(dateOfBirth){
    var date=new Date(dateOfBirth); 
    var today = new Date();
    var age_now = today.getFullYear() - date.getFullYear();
    var m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) 
    {
        age_now--;
    }
    return age_now;
  }

  getResidentDetails(SortByRoomNo, SortMethod) {
    this.setState({ loading: true });
    fetch(
      
      APIUtil.BASE_URL + APIUtil.RESIDENT_API +
      `?Limit=` + this.state.perPage +
      `&Offset=` + this.state.Offset + `&CareHome=` + this.state.selectedCareHome + `&Name=` + this.state.Name.trim() + `&Age=` + this.state.Age.trim() +
      `&Balance=` + this.state.Balance + `&AdminId=` + this.state.careHomeUserId + `&SortByRoomNo=` + SortByRoomNo +
      `&SortMethod=` + SortMethod

    )
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            residents: data.result,
            pageCount: Math.ceil(data.totalCount / this.state.perPage),
            loading: false,
            pageNum: Math.ceil(this.state.Offset / 10),
          },
        );
      });
  }

  onSortChange = () => {
    
		const  currentSort  = this.state.currentSort;
		let nextSort;
    
	  if (currentSort === 'up') {
      nextSort = 'default';
      this.state.sort_room = "false";
      this.state.sort_method = "";
      this.getResidentDetails("false","");
    }
		else if (currentSort === 'default') {
      nextSort = 'up';
      this.state.sort_room = "true";
      this.state.sort_method = "";
      this.getResidentDetails("true","");

  }
  else nextSort = 'default';
		this.setState({
			currentSort: nextSort,
		});
    
	};

   sortTypes = {
    up: {
      class: 'sort-up text-info',
      fn: (a, b) => a.roomNumber > b.roomNumber?1:-1
    },
    default: {
      class: 'sort',
      fn: (a, b) => a.id > b.id?1:-1
    }
  };
  
  render() {
    const { residents } = this.state;
    const  currentSort  = this.state.currentSort;
    return (

      <>
        <div           id="sidebar"
 className="hold-transition sidebar-mini sidebar-collapse">
          <div className="wrapper">
            <AdminNavbar />
            <AdminSidebar />

            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Residents</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <a href="/Admin/AdminIndex">Home</a>
                        </li>
                        <li className="breadcrumb-item active">Residents</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Search</h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="form-group col-md-3">
                              <label for="inputName">Care Home</label>
                              <select
                                className="form-control"
                                value={this.state.selectedCareHome}
                                onChange={this.onChangeCareHome}
                              >

                                <option selected="" value="" disabled="">
                                  Select
                                </option>
                                {" "}
                                {this.state.CareHomeDropdown.map((careHome) => (
                                  <option value={careHome.name}>

                                    {careHome.name}

                                  </option>
                                ))}

                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <label for="inputName">Name</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                value={this.state.Name}
                                onChange={this.onChangeName}
                              />
                            </div>
                            <div className="form-group col-md-2">
                              <label for="inputName">Age</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                value={this.state.Age}
                                onChange={this.onChangeAge}
                              />
                            </div>
                            <div className="form-group col-md-2">
                              <label for="inputName">Balance</label>
                              <select                                 onChange={this.onChangeBalance}
 className="form-control">
                                <option  value='0' selected="" disabled="">
                                  Select
                                </option>
                                <option value='1'>Less than 10,000</option>
                                <option  value='2'>10,000-20,000</option>
                                <option  value='3'>20,000-30,000</option>
                                <option value='4'>30,000-40,000</option>
                                <option  value='5'>40,000-50,000</option>
                                <option  value='6'>More than 50,000</option>
                              </select>
                            </div>
                            <div className="form-group col-md-2">
                              <label for="inputName"></label>
                              <div style={{ padding: ".375rem" }}>
                                <button
                                  onClick={this.onClickSearch}
                                  type="button"
                                  className="btn btn-block btn-primary search1"
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Details</h3>
                          <div className="card-tools">
                          <select
                              id="dropdown"
                              onChange={this.handleDropdownChange}

                            >
                              <option value="1">This Month</option>
                              <option value="2">Last Month</option>
                              <option value="3">Last Quarter</option>
                              <option value="4">This Year</option>
                            </select> 
                            {this.state.smloading ? (
                            <img src="/dist/img/spinner.gif"/>
                          ) : 
                            this.state.DownloadUrl!=""?(
                            <a href={this.state.DownloadUrl} target="_blank"  className="btn btn-tool btn-sm" download>
                            <i className="fas fa-download"></i>
                            </a>
                            ):""
                            }
                            
                            <a
                              href="/Admin/AdminAddResident"
                              className="btn btn-sm btn-secondary"
                            >
                              <i className="fas fa-plus"></i> Add{" "}
                            </a>
                          </div>
                        </div>

                        <div className="card-body">
                          {this.state.loading ? (
                            <div style={{ textAlign: "center" }}>
                              <img src="/dist/img/loader.gif" width="20%" />
                            </div>
                          ) : (
                            <>
                              <table
                                id="example2"
                                className="table table-bordered table-hover"
                              >
                                <thead>
                                  <tr>
                                    <th style={{ width: "15%" }}>Name</th>
                                    <th style={{ width: "10%" }}>Age</th>
                                    <th style={{ width: "15%" }}>Room No.
                                    <button style={{ border: "0px", backgroundColor: "white" }} onClick={()=>this.onSortChange()}>
									                  <i className={`fas fa-${this.sortTypes[currentSort].class}`} />
								                    </button>
                                    </th>
                                    <th style={{ width: "15%" }}>Contact</th>
                                    <th style={{ width: "15%" }}>Care Home</th>
                                    <th style={{ width: "15%" }}>Balance</th>
                                    <th style={{ width: "15%" }}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.residents.map((resident) => (
                                    <tr key={resident.id}>
                                      <td>
                                        <a
                                          href={
                                            "/Admin/AdminSingleResidentDetails/" +
                                            resident.id
                                          }
                                        >
                                          {resident.name}
                                        </a>
                                      </td>
                                      <td>{this.getAge(resident.dateOfBirth)}</td>
                                      <td>{resident.roomNumber}</td>
                                      <td>{resident.contact}</td>
                                      <td>{resident.careHome}</td>
                                      <td className="text-danger">
                                        {" "}
                                        <i className="fas fa-xs fa-pound-sign"></i>{" "}
                                        {resident.balance}
                                      </td>
                                      <td className="text-centre py-0 align-middle">
                                        <a
                                          href={
                                            "/Admin/AdminEditResident/" +
                                            resident.id
                                          }
                                        >
                                          <i
                                            className="fas fa-edit"
                                            title="Edit"
                                          ></i>
                                        </a>{" "}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <a
                                          onClick={() =>
                                            this.addResidentFundModal(
                                              resident.id,resident.careHomeId
                                            )
                                          }
                                        >
                                          <i
                                            className="fas fa-sm fa-plus text-primary"
                                            aria-hidden="true"
                                            data-toggle="modal"
                                            data-target="#Modal-add-funds"
                                            title="Add Funds"
                                          ></i>
                                        </a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <a
                                          onClick={() =>
                                            this.addResidentExpenseModal(
                                              resident.id,resident.careHomeId
                                            )
                                          }
                                        >
                                          <i
                                            className="fas fa-wallet text-primary"
                                            aria-hidden="true"
                                            data-toggle="modal"
                                            data-target="#Modal-manage"
                                            title="Add Expense"
                                          ></i>
                                        </a>                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        <a
                                          onClick={() =>
                                            this.deleteResidentExpenseModal(
                                              resident.id
                                            )
                                          }
                                        >
                                          <i
                                            className="fas fa-trash-alt text-danger"
                                            aria-hidden="true"
                                            data-toggle="modal"
                                            data-target="#Modal-manage"
                                            title="Delete Resident"
                                          ></i>
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.pageCount}
                                forcePage={this.state.pageNum}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {this.state.showAddExpenseModal ? (
                        <AddExpenseModal
                          show={this.state.showAddExpenseModal}
                          onHide={this.closeResidentExpenseModal}
                          ResidentId={this.state.selectedResident}
                          CareHomeId={this.state.CareHomeId}

                        />
                      ) : (
                        ""
                      )}
                      {this.state.showAddFundModal ? (
                        <AddFundsModal
                          show={this.state.showAddFundModal}
                          onHide={this.closeResidentFundModal}
                          ResidentId={this.state.selectedResident}
                          CareHomeId={this.state.CareHomeId}
                        />
                      ) : (
                        ""
                      )}
                          {this.state.showDeleteResident ? (
                        <DeleteResidentModal
                          show={this.state.showDeleteResident}
                          onHide={this.closeDeleteResidentModal}
                          onHideFail={this.closeDeleteResidentModalFail}
                          ResidentId={this.state.selectedResident}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <Footer/>
          </div>
        </div>
      </>
    );
  }
}

export default AdminSearchResident;
