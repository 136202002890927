import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import "../Css/Adminlte.min.css";
import APIUtil from "../../Util/ApiUtil";

let careHomeUser = JSON.parse(localStorage.getItem("user"));
export class AdminIndexCards extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            careHomeUserId: parseInt(careHomeUser.Id),
            Carehomes: 0,
            TotalResidents: 0,
            Balance: 0.0,
            Deposits: 0.0,
            Expense: 0.0,
            careHomeUserId: parseInt(careHomeUser.Id)
        };
        this.onChangeCareHomes = this.onChangeCareHomes.bind(this);
        this.onChangeTotalResidents = this.onChangeTotalResidents.bind(this);
        this.onChangeBalance = this.onChangeBalance.bind(this);
        this.onChangeDeposits = this.onChangeDeposits.bind(this);
        this.onChangeExpense = this.onChangeExpense.bind(this);

        this.refreshList = this.refreshList.bind(this);
    }

    onChangeCareHomes(event) {
        this.setState({
            Carehomes: event.target.value,
        });
    }
    onChangeTotalResidents(event) {
        this.setState({
            TotalResidents: event.target.value,
        });
    }
    onChangeBalance(event) {
        this.setState({
            Balance: event.target.value,
        });
    }
    onChangeDeposits(event) {
        this.setState({
            Deposits: event.target.value,
        });
    }
    onChangeExpense(event) {
        this.setState({
            Expense: event.target.value,
        });
    }

    componentDidMount() {
        this.refreshList();
    }

    refreshList() {
        fetch(APIUtil.BASE_URL + APIUtil.ADMIN_SUMMARY + '/' + this.state.careHomeUserId)
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    Carehomes: data.careHomes,
                    TotalResidents: data.totalResidents,
                    Balance: data.balance,
                    Deposits: data.deposit,
                    Expense: data.expense,
                });
            });
    }

    render() {
        return (
            <div className="col-lg-7">
                <div className="row">
                    <div className="col-md-6">
                        <div className="info-box shadow bg-white" style={{ height: '140px', marginBottom: '10px' }}>
                            <span className="info-box-icon text-secondary">
                                <i className="fas fa-sm fa-home"></i>{" "}
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-number text-lg">
                                    {this.state.Carehomes}
                                </span>
                                <span className="info-box-text">Care Homes</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="info-box bg-white shadow" style={{ height: '140px', marginBottom: '10px' }}>
                            <span className="info-box-icon text-primary">
                                <i className="fas fa-sm fa-house-user"></i>
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-number  text-lg">
                                    {this.state.TotalResidents}
                                </span>
                                <span className="info-box-text">Total Residents</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="info-box shadow bg-white" style={{ height: '140px', marginBottom: '10px' }}>
                            <span className="info-box-icon text-primary">
                                <i className="fas fa-sm fa-money-bill"></i>
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-number">
                                    <i className="fas fa-xs fa-pound-sign"></i>{" "}
                                    {this.state.Balance}{" "}
                                </span>
                                <span className="info-box-text">Balance</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="info-box shadow" style={{ height: '140px', marginBottom: '10px' }}>
                            <span className="info-box-icon text-success" >
                                <i className="fas fa-sm fa-long-arrow-alt-down"></i>
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-number">
                                    <i className="fas fa-xs fa-pound-sign"></i>{" "}
                                    {this.state.Deposits}{" "}
                                </span>
                                <span className="info-box-text">Deposits</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="info-box shadow" style={{ height: '140px', marginBottom: '10px' }}>
                            <span className="info-box-icon text-warning">
                                <i className="fas fa-sm fa-long-arrow-alt-up"></i>
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-number">
                                    <i className="fas fa-xs fa-pound-sign"></i>
                                    {this.state.Expense}
                                </span>
                                <span className="info-box-text">Expenses</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminIndexCards;
