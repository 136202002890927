import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import "../Css/Adminlte.min.css";
import axios from "axios";
import APIUtil from "../../Util/ApiUtil";
import { Doughnut } from "react-chartjs-2";
let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class NumberOfResidents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TotalCount: 0,
      CareHomes: [],
        individualresidentscount: [],
        Data: {
            labels: [],
            datasets: []
        }
    };    
  }

  componentDidMount() {
    axios
      .get(APIUtil.BASE_URL+APIUtil.ADMIN_CARE_HOME_LIST+"?Id="+careHomeUser.Id)

      .then((res) => {

        const response = res.data;
        let CareHomesArray = [];
        let individualresidentscountArray = [];
        var listofcarehomes = response.careHomes;
        
        var restcount = 0;
        for (var i = 0; i < listofcarehomes.length; i++) {
          if (i < 5) {
            CareHomesArray.push(listofcarehomes[i].name);
            individualresidentscountArray.push(listofcarehomes[i].residentCount);
          } else restcount = restcount + listofcarehomes[i].residentCount;
        }
        
        CareHomesArray.push('Others');
        individualresidentscountArray.push(restcount);
        // console.log("CareHomesArray",CareHomesArray);
        // console.log("individualresidentscountArray",individualresidentscountArray);

        this.setState({
          Data: {
            labels: CareHomesArray,

            datasets: [
              {
                data: individualresidentscountArray,

                backgroundColor: [
                  "#2A9DF4",
                  "#3D4756",
                  "#EA6F9B",
                  "#00B49E",
                  "#A1ACBD",
                  "#d2d6de",
                ],
              },
            ],
          },
        });
      });
  }

  render() {
    return (
      <div className="col-lg-6">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Residents Distribution</h3>
          </div>
          <div className="card-body">
            <h6 className="text-lg-center">
              Number of residents across Care Homes
            </h6>
            <div>
            <Doughnut
            height="250px"
              data={this.state.Data}
              options={{ maintainAspectRatio: false, responsive : true }}
            ></Doughnut>
          </div>
          </div>
        </div>
      </div>
    );
  }
}
