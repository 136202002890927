import React from "react";
import Swal from "sweetalert2";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import APIUtil from "../../../Util/ApiUtil";
import $ from "jquery";
//import {toast} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
//toast.configure()

export class AddAdminModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminGroup: "",
      adminName: "",
      adminEmail: "",
      adminPhone: "",
      activeOptions: ["Active"],
      Group: ["Group A", "Group B", "Group C", "Group D"],
      selectedOption: "",
      isActive: true,
      adminGroupError: "",
      adminNameError: "",
      adminEmailError: "",
      adminPhoneError: "",
    };

    this.onChangeAdminGroup = this.onChangeAdminGroup.bind(this);
    this.onChangeAdminName = this.onChangeAdminName.bind(this);
    this.onChangeAdminEmail = this.onChangeAdminEmail.bind(this);
    this.onChangeAdminPhone = this.onChangeAdminPhone.bind(this);
    this.onChangeActiveOptions = this.onChangeActiveOptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  //   componentDidMount(){
  //     $(this.getDOMNode()).on('hidden.bs.modal', this.props.onHide);
  // }

  onChangeAdminGroup(event) {
    this.setState({
      adminGroup: event.target.value,
    });
  }

  onChangeAdminName(event) {
    this.setState({
      adminNameError: "",
      adminName: event.target.value,
    });
  }
  onChangeAdminEmail(event) {
    this.setState({
      adminEmail: event.target.value,
    });
  }
  onChangeActiveOptions(e) {
    //let value = e.target.value == "isActive" ? true : false;
    this.setState({
      isActive: e.target.value,
    });
  }

  onChangeAdminPhone(e) {
    const value = e.target.value.replace(/\D/, "");
    if ( e.target.value.length >11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid phone number",
      });
    } else {
      this.setState({ adminPhone: value });
    }
  }

  valid() {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (this.state.adminGroup == null || this.state.adminGroup === "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.adminName == null || this.state.adminName === "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (!pattern.test(this.state.adminEmail)) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please provide a valid email",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.adminPhone == null || this.state.adminPhone === "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid phone number",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
     } else if (this.state.adminPhone.length !== 10 && this.state.adminPhone.length !== 11) {
        Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Invalid phone number",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();
    document.getElementById("submitbtn").innerHTML = "Processing...";

    if (this.valid()) {
      fetch(APIUtil.BASE_URL + APIUtil.ADMIN_API, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          group: this.state.adminGroup,
          name: this.state.adminName,
          email: this.state.adminEmail.toLocaleLowerCase(),
          phone: this.state.adminPhone,
          isActive: this.state.isActive,
        }),
      })
        .then(async (response) => {
          if (response.ok) {
            Swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: "Admin added successfully",
            });

            document.getElementById("submitbtn").innerHTML = "Save";

            this.setState({ adminGroup: "" });
            this.setState({ adminName: "" });
            this.setState({ adminEmail: "" });
            this.setState({ adminPhone: "" });
            this.setState({ selectedOption: "" });
            this.setState({ AdminGroup: "" });
            window.location.reload(false);
          } else {
            Swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: "Failed to add Admin",
            });
            document.getElementById("submitbtn").innerHTML = "Save";
          }
        })
        .catch((error) => {
          
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: "Email already exist",
          });
          document.getElementById("submitbtn").innerHTML = "Save";
        });
    }
  }

  render() {
    return (
      <Modal {...this.props} show={this.props.show} onHide={this.handleClose}>
        <Modal.Header>
          <Modal.Title>Add Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="form-group col-md-6">
                <label>Care Home Group</label>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.AdminGroup}
                    onChange={this.onChangeAdminGroup}
                  />
                  {/* <select
                              value={this.state.AdminGroup}
                              onChange={this.onChangeAdminGroup}
                              className="form-control"
                            >
                              <option selected="" disabled="">
                                Select
                              </option>
                              {this.state.Group.map((option) => (
                                <option value={option.value}>{option}</option>
                              ))}
                            </select> */}
                </div>

                {this.state.adminGroupError ? (
                  <p style={{ color: "red", fontSize: "14px" }}>
                    {this.state.adminGroupError}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group col-md-6">
                <label>Admin Name</label>
                <span style={{ color: "red", fontSize: "14px" }}>*</span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-user"></i>
                    </span>{" "}
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.adminName}
                    onChange={this.onChangeAdminName}
                  />
                </div>
              </div>
                <div className="form-group col-md-6">
                  <label>E-mail</label>
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      {" "}
                      <span className="input-group-text">
                        <i className="fas fa-sm fa-envelope"></i>
                      </span>{" "}
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.adminEmail}
                      onChange={this.onChangeAdminEmail}
                    />
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>Phone No.</label>
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      {" "}
                      <span className="input-group-text">
                        <i className="fas fa-sm fa-phone-alt"></i>
                      </span>{" "}
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.adminPhone}
                      onChange={this.onChangeAdminPhone}
                    />
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>Status</label>
                  <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  <div className="input-group">
                    <select
                      name="selectedOption"
                      value={this.state.isActive}
                      onChange={this.onChangeActiveOptions}
                      required
                      className="form-control"
                    >
                      {this.state.activeOptions.map((i) =>
                        i == this.state.selectedOption ? (
                          <option value={i} selected>
                            {i}
                          </option>
                        ) : (
                          <option value={i}>{i}</option>
                        )
                      )}
                    </select>
                  </div>
                </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <div
            id="submitbtn"
            onClick={this.handleSubmit}
            className="btn btn-primary"
          >
            Save
          </div>
          <div
            onClick={this.props.onHide}
            className="btn btn-default"
          >
            Cancel
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddAdminModal;
