import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import APIUtil from "../Util/ApiUtil";
import axios from "axios";

import Swal from "sweetalert2";

export class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Email: "",
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeEmail(event) {
    this.setState({
      Email: event.target.value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
     if (!pattern.test(this.state.Email)) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please provide a valid email",
      });
      return ;
    }

    document.getElementById("submitbtn").innerHTML = "Processing...";

   

    fetch(APIUtil.BASE_URL + APIUtil.FORGOT_PASSWORD + this.state.Email, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      document.getElementById("submitbtn").innerHTML = "Ok";

      if (response.ok) {
        await console.log("response is 200");

        //this.notify();
        Swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "success",
          title: "Password changed successfully,Please check your mail",
        });
        window.location.href = "/Login";

        //this.props.onHide();
      } else {
        await console.log("failed");
        Swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "error",
          title: "Failed to reset password",
        });
      }
    });
  };

  render() {
    return (
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <img src="/images/Logo-Transact-3.png" alt="Transact Logo" />
          </div>

          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">
                You forgot your password? Here you can easily retrieve a new
                password.
              </p>

              <form>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={this.state.Email}
                    onChange={this.handleChangeEmail}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <a
                      id="submitbtn"
                      onClick={this.handleSubmit}
                      className="btn btn-primary"
                    >
                      Request new password
                    </a>
                  </div>
                </div>
              </form>

              <p className="mt-3 mb-1">
                <a href="/Login">Sign In</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
