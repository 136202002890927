import React from 'react'
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import Swal from 'sweetalert2';
import APIUtil from "../../../Util/ApiUtil";
let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class AddUserModal extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      Name: "",
      RoleId: 4,
      AssociateManagerRole: "",
      Phone: "",
      Email: "",
    }

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  onChangeName(e) {
    this.setState({ Name: e.target.value })
  }

  onChangeRole(e) {
    this.setState({ AssociateManagerRole: e.target.value })
  }

  onChangePhone(e) {

    const value = e.target.value.replace(/\D/, '')

    if (e.target.value.length > 11) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Phone number should not be more than 11-digits'
      })
    }
    else {
      this.setState({ Phone: value });
    }
  }

  onChangeEmail(event) {
    this.setState({
      Email: event.target.value
    });
  }

  valid() {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


    if (this.state.Name == null || this.state.Name === "") {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Please fill all the mandatory fields'
      })
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    }

    else if (this.state.AssociateManagerRole == null || this.state.AssociateManagerRole === "") {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Please fill all the mandatory fields'
      })
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    }


    else if (!pattern.test(this.state.Email)) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Please provide a valid email'
      })
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    }

    else if (this.state.Phone == null || this.state.Phone === "") {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Invalid phone number'
      })
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    }
    else if (this.state.Phone.length != 11 && this.state.Phone.length != 10) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Invalid phone number'
      })
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;

    }

    return true;
  }

  handleSubmit(event) {
    event.preventDefault()
    document.getElementById("submitbtn").innerHTML = "Processing...";

    if (this.valid()) {
      {
        fetch(APIUtil.BASE_URL + APIUtil.POST_USER_ASSOCIATE_MANAGER, {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.state.Name,
            roleId: this.state.RoleId,
            associateManagerRole: this.state.AssociateManagerRole,
            email: this.state.Email.toLocaleLowerCase(),
            phone: this.state.Phone,
            CreatedBy: parseInt(careHomeUser.Id),
            IsActive: true,
          }),
        })
          .then(async response => {
            if (response.ok) {

              Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                icon: 'success',
                title: 'User added successfully'
              })

              document.getElementById("submitbtn").innerHTML = "Save";

              this.setState({ Name: "" })
              this.setState({ AssociateManagerRole: "" })
              this.setState({ Email: "" })
              this.setState({ Phone: "" })
              window.location.reload(false);

            }
            else {
              Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                icon: 'error',
                title: 'Failed to add User'
              })
              document.getElementById("submitbtn").innerHTML = "Save";
            }
          })
          .catch(error => {

            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              icon: 'error',
              title: 'Email already exist'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
          });

      }
    }
  }

  render() {

    return (

      <Modal {...this.props}
        show={this.props.show} onHide={this.handleClose}>
        <Modal.Header>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="form-group col-md-6">
                <label>Name </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-user"></i>
                    </span>
                  </div>
                  <input type="text" className="form-control"
                    value={this.state.Name}
                    onChange={this.onChangeName}
                  />
                </div>

              </div>
              <div className="form-group col-md-6">
                <label>Role</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-user"></i>
                    </span>
                  </div>
                  <input type="text" className="form-control"
                    value={this.state.AssociateManagerRole}
                    onChange={this.onChangeRole}
                  />
                </div>
              </div>

              <div className="form-group col-md-6">
                <label>Phone No.</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-phone-alt"></i>
                    </span>{" "}
                  </div>
                  <input type="text" className="form-control"
                    value={this.state.Phone}
                    onChange={this.onChangePhone}
                  />
                </div>

              </div>
              <div className="form-group col-md-6">
                <label>E-mail</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-envelope"></i>
                    </span>{" "}
                  </div>
                  <input type="text" className="form-control"
                    value={this.state.Email}
                    onChange={this.onChangeEmail}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <div id="submitbtn" onClick={this.handleSubmit} className="btn btn-primary">
            Save
          </div>
          <button
            onClick={this.props.onHide}
            className="btn btn-default"
            data-dismiss="modal"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

    );
  }
}
export default AddUserModal;