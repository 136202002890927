import React from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import APIUtil from "../../../Util/ApiUtil";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';
toast.configure();

export class EditAdminModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            adminId: this.props.adminId,
            adminGroup: this.props.adminGroup,
            adminName: this.props.adminName,
            adminEmail: this.props.adminEmail,
            adminPhone: this.props.adminPhone,
            activeOptions: ["Active"],
            isActive: true,
            //isActive: this.props.adminIsActive,
            adminGroupError: "",
            adminNameError: "",
            adminEmailError: "",
            adminPhoneError: "",
        };
        this.onChangeAdminGroup = this.onChangeAdminGroup.bind(this);
        this.onChangeAdminName = this.onChangeAdminName.bind(this);
        // this.onChangeAdminEmail = this.onChangeAdminEmail.bind(this);
        this.onChangeAdminPhone = this.onChangeAdminPhone.bind(this);
        this.onChangeActiveOptions = this.onChangeActiveOptions.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

    }

    onChangeAdminGroup(event) {
        this.setState({
            adminGroup: event.target.value,
        });
    }

    onChangeAdminName(event) {
        this.setState({
            adminName: event.target.value,
        });
    }

    // onChangeAdminEmail(event) {
    //   this.setState({
    //     adminEmail: event.target.value,
    //   });
    // }
    onChangeAdminPhone(e) {
        const value = e.target.value.replace(/\D/, '')
        if (e.target.value.length > 11) {
            Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                icon: 'error',
                title: 'Invalid phone number'
            })
        }
        else {
            this.setState({ adminPhone: value });
        }
    }
    onChangeActiveOptions(event) {
        //let value = event.target.value == "isActive" ? true : false;
        this.setState({
            isActive: event.target.value,
        });

    }

    notify() {
        toast.success("Admin Details Edited Successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }


    valid() {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


        if (this.state.adminGroup == null || this.state.adminGroup === "") {
            Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                icon: 'error',
                title: 'Please fill all the mandatory fields'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
            return false;
        }
        else if (this.state.adminName == null || this.state.adminName === "") {
            Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                icon: 'error',
                title: 'Please fill all the mandatory fields'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
            return false;

        }

        else if (!pattern.test(this.state.adminEmail)) {
            Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                icon: 'error',
                title: 'Please provide a valid email'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
            return false;

        }

        else if (this.state.adminPhone == null || this.state.adminPhone === "") {
            Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                icon: 'error',
                title: 'Invalid phone number'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
            return false;

        }
        else if (this.state.adminPhone.length != 10 && this.state.adminPhone.length != 11) {
            Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                icon: 'error',
                title: 'Invalid phone number'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
            return false;

        }

        return true;
    }


    handleSubmit(event) {
        event.preventDefault();
        document.getElementById("submitbtn").innerHTML = "Processing...";
        if (this.valid()) {
            fetch(APIUtil.BASE_URL + APIUtil.ADMIN_API + this.state.adminId, {
                method: "PUT",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: this.state.adminId,
                    group: this.state.adminGroup,
                    name: this.state.adminName,
                    email: this.state.adminEmail,
                    phone: this.state.adminPhone,
                    isActive: this.state.isActive,
                }),
            })
                .then(async response => {
                    if (response.ok) {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 5000,
                            icon: 'success',
                            title: 'Admin updated successfully'
                        })

                        document.getElementById("submitbtn").innerHTML = "Save";

                        this.setState({ adminGroup: "" })
                        this.setState({ adminName: "" })
                        this.setState({ adminEmail: "" })
                        this.setState({ adminPhone: "" })
                        this.setState({ selectedOption: "" })
                        this.setState({ AdminGroup: "" })

                        window.location.reload(false);

                    }
                    else {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 5000,
                            icon: 'error',
                            title: 'Failed to edit Admin'
                        })
                        document.getElementById("submitbtn").innerHTML = "Save";
                    }
                });
        }


    }

    render() {
        return (
            <Modal {...this.props} show={this.props.show} onHide={this.handleClose}>
                <Modal.Header>
                    <Modal.Title>Edit Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label>Care Home Group </label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        {" "}
                                        <span className="input-group-text">
                                            <i className="fas fa-sm fa-home"></i>
                                        </span>{" "}
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.adminGroup}
                                        onChange={this.onChangeAdminGroup}
                                    />
                                </div>
                                {this.state.adminGroupError ? (
                                    <p style={{ color: "red", fontSize: "14px" }}>
                                        {this.state.adminGroupError}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="form-group col-md-6">
                                <label>Admin Name</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        {" "}
                                        <span className="input-group-text">
                                            <i className="fas fa-sm fa-user"></i>
                                        </span>{" "}
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.adminName}
                                        onChange={this.onChangeAdminName}
                                    />
                                </div>
                                {this.state.adminNameError ? (
                                    <p style={{ color: "red", fontSize: "14px" }}>
                                        {this.state.adminNameError}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="form-group col-md-6">
                                <label>E-mail</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        {" "}
                                        <span className="input-group-text">
                                            <i className="fas fa-sm fa-envelope"></i>
                                        </span>{" "}
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.adminEmail}
                                        disabled
                                    />
                                </div>
                                {this.state.adminNameError ? (
                                    <p style={{ color: "red", fontSize: "14px" }}>
                                        {this.state.adminNameError}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="form-group col-md-6">
                                <label>Phone No.</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        {" "}
                                        <span className="input-group-text">
                                            <i className="fas fa-sm fa-phone-alt"></i>
                                        </span>{" "}
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.adminPhone}
                                        onChange={this.onChangeAdminPhone}
                                    />
                                </div>
                                {this.state.adminPhoneError ? (
                                    <p style={{ color: "red", fontSize: "14px" }}>
                                        {this.state.adminPhoneError}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="form-group col-md-6">
                                <label>Status</label>
                                <div className="input-group">
                                    <select
                                        name="selectedOption"
                                        value={this.state.isActive}
                                        onChange={this.onChangeActiveOptions}
                                        required
                                        className="form-control"
                                    >
                                        {this.state.activeOptions.map((i) =>
                                            i == this.state.selectedOption ? (
                                                <option value={i} selected>
                                                    {i}
                                                </option>
                                            ) : (
                                                <option value={i}>{i}</option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <div id="submitbtn" onClick={this.handleSubmit} className="btn btn-primary">
                        Save
                    </div>
                    <div
                        onClick={this.props.onHide}
                        className="btn btn-default"
                    >
                        Cancel
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default EditAdminModal;
