import React from "react";
import AddFundsModal from "../Modals/Manager/AddFundsModal";
import AddExpenseModal from "../Modals/Admin/AddExpenseModal";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import APIUtil from "../../Util/ApiUtil";
import ReactPaginate from "react-paginate";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';

let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class PettyCashReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAddExpenseModal: false,
            showAddFundModal: false,
            addExpenseData: {
                amount: 0,
                date: "",
                expenseTypeId: 0,
                info: "",
                file: {},
            },
            addFundData: {},
            loading: false,
            selectedResident: 1,

            loading: false,
            DownloadUrl: APIUtil.DOWNLOAD_BASE_URL,
            smloading: false,

            TotalAmount: 0,
            ExpenseModel: [],
            //pagination
            Offset: 0,
            perPage: 5,
            currentPage: 0,
            pageNum: 1,
            daterange: "Select Dates",
            startDate: null,
            endDate: null
        };
        this.refreshList = this.refreshList.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);

        this.closeResidentExpenseModal = this.closeResidentExpenseModal.bind(this);
        this.addResidentExpenseModal = this.addResidentExpenseModal.bind(this);
        this.addResidentFundModal = this.addResidentFundModal.bind(this);
        this.closeResidentFundModal = this.closeResidentFundModal.bind(this);
        this.handleDateRange = this.handleDateRange.bind(this);
        this.reset = this.reset.bind(this);

    }

    componentDidMount() {
        this.refreshList();
        this.getPettyCashReport();
    }
    async reset() {
        await this.setState(
            {
                daterange: "Select Dates",
                startDate: null,
                endDate: null,
            }
        );
        await this.getPettyCashReport();
        await this.refreshList();
    }

    async handleDateRange(e, picker) {
        var startDateString = picker.startDate.format("DD-MM-YYYY");
        var endDateString = picker.endDate.format("DD-MM-YYYY");
        await this.setState(
            {
                daterange: startDateString + " ~ " + endDateString,
                startDate: startDateString,
                endDate: endDateString,
            }
        );
        await this.getPettyCashReport();
        await this.refreshList();
    }

    addResidentExpenseModal(selectedResident) {
        this.setState({ showAddExpenseModal: true, selectedResident });
    }

    closeResidentExpenseModal() {
        this.setState({ showAddExpenseModal: false, selectedResident: 0 });
        window.location.reload();

    }

    addResidentFundModal(selectedResident) {
        this.setState({ showAddFundModal: true, selectedResident });
    }

    closeResidentFundModal() {
        this.setState({ showAddFundModal: false, selectedResident: 0 });
        window.location.reload();

    }
    getPettyCashReport() {
        {
            this.setState({ smloading: true })
        }
        var url = APIUtil.BASE_URL + APIUtil.GET_CAREHOME_PETTYCASH_REPORT_API + "/" + careHomeUser.CareHomeId;
        if (this.state.daterange != "Select Dates") {
            url += "?startDate=" + this.state.startDate + "&endDate=" + this.state.endDate;
        }
        else {
            url += "?startDate=01-01-0001&endDate=01-01-9999";
        }
        fetch(url)
            .then((response) => response.text())
            .then((data) => {
                const url = APIUtil.DOWNLOAD_BASE_URL + "/" + data.replace("//" + "/").replace('\\', '/');
                this.setState({
                    DownloadUrl: url,
                    smloading: false,
                });
            });
    }
    refreshList() {
        {
            this.setState({ loading: true });
        }

        var url = APIUtil.BASE_URL + APIUtil.GET_CAREHOME_PETTYCASH_API + "/" + careHomeUser.CareHomeId;
        if (this.state.daterange != "Select Dates") {
            url += "?startDate=" + this.state.startDate + "&endDate=" + this.state.endDate;
        }
        else {
            url += "?startDate=01-01-0001&endDate=01-01-9999";
        }
        url += `&Limit=` + this.state.perPage + `&Offset=` + this.state.Offset;
        fetch(url)
            .then((response) => response.json())
            .then(async (data) => {
                await this.setState(
                    {
                        TotalAmount: data.totalAmount,
                        ExpenseModel: data.pettyCash,

                        pageCount: Math.ceil(data.totalCount / this.state.perPage),
                        loading: false,
                        pageNum: Math.ceil(this.state.Offset / 5),
                    },
                );
            });
    }

    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState(
            {
                currentPage: selectedPage + 1,
                Offset: offset,
            },
            () => {
                this.refreshList();
            }
        );
    };

    render() {
        return (
            <div className="col-lg-5">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title mb-2">Petty Cash Report</h4>
                        <div className="card-tools">
                            <span className="text-bold text-md text-primary">
                                Total: <i className="fas fa-xs fa-pound-sign"></i>{" "}
                                {this.state.TotalAmount}{"     "}
                                &nbsp;
                                <div className="btn-group card-tools">
                                    {/* <select
                              id="dropdown"
                              onChange={this.handleDropdownChange}

                            >
                              <option value="1">This Month</option>
                              <option value="2">Last Month</option>
                              <option value="3">Last Quarter</option>
                              <option value="4">This Year</option>
                            </select>  */}
                                    <DateRangePicker initialSettings={{
                                        locale: {
                                            format: "DD/MM/YYYY"
                                        }
                                    }}
                                        onApply={this.handleDateRange}
                                    >
                                        <div className="input-group"  >
                                            <input type="text" className="form-control" value={this.state.daterange} id="datetime_picker" />

                                        </div>
                                    </DateRangePicker>
                                    <a target="_blank" onClick={this.reset} style={{ paddingRight: "5px", paddingLeft: "5px", color: "red", cursor: "pointer" }} >x</a>

                                    {this.state.smloading ? (
                                        <img style={{ height: "25px", paddingRight: "5px", paddingLeft: "5px", paddingTop: "5px" }} src="/dist/img/spinner.gif" />
                                    ) :
                                        this.state.DownloadUrl != "" ? (
                                            <a href={this.state.DownloadUrl} target="_self" className="btn btn-tool btn-sm" download>
                                                <i style={{ marginTop: '15px', paddingRight: "5px", paddingLeft: "5px" }} className="fas fa-download"></i>
                                            </a>
                                        ) : ""
                                    }
                                    <a
                                        onClick={() => this.addResidentFundModal()}
                                        className="btn btn-outline-primary btn-sm"
                                    >
                                        <i
                                            className="fas fa-sm fa-plus"
                                            aria-hidden="true"
                                            data-toggle="modal"
                                            data-target="#modal-add-funds"
                                            title="Add Funds "
                                        ></i>
                                    </a>
                                    <a
                                        onClick={() => this.addResidentExpenseModal()}
                                        className="btn btn-outline-primary btn-sm"
                                    >
                                        <i
                                            className="fas fa-sm fa-wallet"
                                            aria-hidden="true"
                                            data-toggle="modal"
                                            data-target="#Modal-add-expense"
                                            title="Add Expense"
                                        ></i>
                                    </a>
                                </div>
                            </span>
                            &nbsp;
                        </div>
                    </div>
                    {/* <!-- /.card-header --> */}
                    <div className="card-body">
                        {this.state.loading ? (
                            <div style={{ textAlign: "center" }}>
                                <img src="/dist/img/loader.gif" width="20%" />
                            </div>
                        ) : (
                            <>
                                <table
                                    id="example2"
                                    className="table table-bordered table-hover mb-4"
                                >
                                    <thead>
                                        <tr>
                                            <th style={{ width: "70%" }}>Type</th>
                                            <th style={{ width: "30%" }}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.ExpenseModel.map((expense) => (
                                            <tr >
                                                <td>
                                                    {expense.type}
                                                    <p style={{ fontSize: "14px" }} className="text-muted mb-0" >  {expense.info} </p>
                                                </td>
                                                <td>
                                                    <i className="fas fa-xs fa-pound-sign"></i>{" "}
                                                    {expense.amount}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    forcePage={this.state.pageNum}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </>
                        )}
                    </div>
                </div>
                {this.state.showAddExpenseModal ? (
                    <AddExpenseModal
                        show={this.state.showAddExpenseModal}
                        onHide={this.closeResidentExpenseModal}
                        ResidentId={0}
                        CareHomeId={careHomeUser.CareHomeId}

                    />
                ) : (
                    ""
                )}
                {this.state.showAddFundModal ? (
                    <AddFundsModal
                        show={this.state.showAddFundModal}
                        onHide={this.closeResidentFundModal}
                        ResidentId={0}
                    />
                ) : (
                    ""
                )}
            </div>

            //   <!-- /.card -->
        );
    }
}
