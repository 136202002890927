import React from 'react'
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import Swal from 'sweetalert2';
import APIUtil from "../../../Util/ApiUtil";
import Utils from "../../../Util/Utils";


export class ActivateOrDeactivateModal extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      userId: this.props.userId,
      Name: this.props.Name,
      RoleId: 4,
      Role: this.props.Role,
      Phone: this.props.Phone,
      Email: this.props.Email,
      activeOptions: ["Active", "Inactive"],
      selectedOption: "Active",
      userIsActive: this.props.userIsActive,

    };
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {

    event.preventDefault();
    var uData=Utils.GetUserDetails();

    document.getElementById("submitbtn").innerHTML = "Processing...";

    
      fetch(APIUtil.BASE_URL+APIUtil.POST_USER_ASSOCIATE_MANAGER, {
        method: "PUT",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: this.state.userId,
          name: this.state.Name,
          roleId:this.state.RoleId,
          associateManagerRole: this.state.Role,
          phone: this.state.Phone,
          email: this.state.Email,
          isActive: !this.state.userIsActive,
          ModifiedBy:parseInt(uData.Id),
          CreatedBy:parseInt(uData.Id)
        },
        
        ),
        
      })
      .then(async response => {
        if (response.ok) {
        const isActiveResponse = this.state.userIsActive ? "Deactivate" : "Activate" 
         Swal.fire({
           toast: true,
           position: 'top-end',
           showConfirmButton: false,
           timer: 5000,
           icon: 'success',
           title: 'User '+ isActiveResponse +' successfully'
         })
         window.location.reload(false);
     
        }
        else{
         Swal.fire({
           toast: true,
           position: 'top-end',
           showConfirmButton: false,
           timer: 5000,
           icon: 'error',
           title: 'Failed'
         })
          document.getElementById("submitbtn").innerHTML = "Save";
        }
       });
    
    
    
  }
    render() {
      
        return (
            <Modal {...this.props} show={this.props.show} onHide={this.handleClose}
        size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
              <Modal.Title>User Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="modal-body">
              <p>Are you sure you want to {this.state.userIsActive ? "deactivate" : "activate"} this user?</p>
            </div>
            </Modal.Body>
     
            <Modal.Footer className="justify-content-start">
            <Button id="submitbtn" onClick={this.handleSubmit} variant="primary" >
            Yes, {this.state.userIsActive ? "Deactivate" : "activate"}
                
               </Button>
               <button
              onClick={this.props.onHide}
              className="btn btn-default"
              data-dismiss="modal"
            >
              Cancel
            </button>          </Modal.Footer>
        </Modal>
        )
    }
}

export default ActivateOrDeactivateModal;