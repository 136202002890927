import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import ApiUtil from "../../Util/ApiUtil";
import { AddUserModal } from "../Modals/Manager/AddUserModal";
import { EditUserModal } from "../Modals/Manager/EditUserModal";
import { ActivateOrDeactivateModal } from "../Modals/Manager/ActivateOrDeactivateModal";
import { Button, ButtonToolbar } from "react-bootstrap";
let careHomeUser = JSON.parse(localStorage.getItem("user"));
var showAddButton = true;
var isManager = true;

export class UsersCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userdata: [],
            addModalShow: false,
            editModalShow: false,
            activateordeactivateModalShow: false,
            // ActivateOrDeactivateModalShow: false,

            Name: "",
            Role: "",
            UsersCount: 0,
            //edit states
            eUserId: "",
            eRoleId: 0,
            eUserName: "",
            eUserRole: "",
            eUserPhone: "",
            eUserEmail: "",
            eModifiedBy: "",
            eUserIsActive: false,
        };
        this.refreshList = this.refreshList.bind(this);
        if (careHomeUser.Role == "AssociateManager") {
            showAddButton = false;
            isManager = false;
        }
        
    }

    componentDidMount() {
        this.refreshList();
    }
    refreshList() {
        fetch(ApiUtil.BASE_URL + ApiUtil.GET_ASSOCIATE_MANAGER_API + "/" + careHomeUser.CareHomeId + "?Limit=5" + "&Offset=0")
            .then((response) => response.json())
            .then((data) => {
                this.setState({ userdata: data.result, UsersCount: data.totalCount });
            });
    }

    addModalClose = (value) => this.setState({ addModalShow: false });

    editModalClose = (value) => this.setState({ editModalShow: false });

    activateordeactivateModalClose = (value) =>
        this.setState({ activateordeactivateModalShow: false });

    render() {
        return (
            <div {...this.props} className="col-lg-7">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Users</h3>
                        <div className="card-tools">
                            <span className="text-bold text-md text-primary">
                                Count: {this.state.UsersCount}
                            </span>{" "}
                            &nbsp;
                            {showAddButton ?
                                (<Button
                                    className="btn btn-sm btn-secondary"
                                    onClick={() => this.setState({ addModalShow: true })}
                                    aria-hidden="true"
                                    data-toggle="modal"
                                    data-target="#Modal-form-add"
                                >
                                    <i className="fas fa-plus"></i>
                                    Add
                                </Button>) : null
                            }
                            {this.state.addModalShow ? (
                                <AddUserModal
                                    show={this.state.addModalShow}
                                    onHide={this.addModalClose}
                                    refreshList={this.refreshList}
                                />
                            ) : null}
                        </div>
                    </div>

                    <div className="card-body">
                        <table id="example2" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th style={{ width: "20%" }}>Name</th>
                                    <th style={{ width: "20%" }}>Role</th>
                                    <th style={{ width: "20%" }}>Phone No.</th>
                                    <th style={{ width: "28%" }}>E-mail</th>
                                    {isManager && (<th style={{ width: "12%" }}>Action</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.userdata.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.name}</td>
                                        <td>{user.associateManagerRole}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.email}</td>
                                        {isManager && (<td>
                                            <a
                                                className="fas  fa-sm fa-edit"
                                                onClick={() =>
                                                    this.setState({
                                                        editModalShow: true,
                                                        eUserId: user.id,
                                                        eUserName: user.name,
                                                        eRoleId: user.roleId,
                                                        eUserRole: user.associateManagerRole,
                                                        eUserPhone: user.phone,
                                                        eUserEmail: user.email,
                                                        eUserIsActive: user.isActive,
                                                        eModifiedBy: user.ModifiedBy,
                                                    })
                                                }
                                                aria-hidden="true"
                                                data-toggle="modal"
                                                data-target="#Modal-form-edit"
                                                title="Edit"
                                            ></a>{" "}
                                            &nbsp;
                                            &nbsp;
                                            <a href="#">
                                                <i
                                                    className={
                                                        user.isActive
                                                            ? "fas  fa-sm fa-user-times text-primary"
                                                            : "fas  fa-sm fa-user-times text-gray"
                                                    }
                                                    aria-hidden="true"
                                                    data-toggle="modal"
                                                    data-target="#modal-deactivate"
                                                    title={user.isActive ? "Deactivate" : "Activate"}
                                                    onClick={() =>
                                                        this.setState({
                                                            activateordeactivateModalShow: true,
                                                            eUserId: user.id,
                                                            eUserName: user.name,
                                                            eUserRole: user.associateManagerRole,
                                                            eUserPhone: user.phone,
                                                            eUserEmail: user.email,
                                                            eUserIsActive: user.isActive,
                                                        })
                                                    }
                                                ></i>
                                            </a>
                                        </td>)}
                                    </tr>
                                ))}
                                {this.state.editModalShow && (
                                    <EditUserModal
                                        show={this.state.editModalShow}
                                        onHide={this.editModalClose}
                                        onClick={this.onClick}
                                        userId={this.state.eUserId}
                                        Name={this.state.eUserName}
                                        RoleId={this.state.eRoleId}
                                        Role={this.state.eUserRole}
                                        Phone={this.state.eUserPhone}
                                        Email={this.state.eUserEmail}
                                        userIsActive={this.state.eUserIsActive}
                                        action={this.state.action}
                                    />
                                )}

                                {this.state.activateordeactivateModalShow && (
                                    <ActivateOrDeactivateModal
                                        show={this.state.activateordeactivateModalShow}
                                        onHide={this.activateordeactivateModalClose}
                                        onClick={this.onClick}
                                        userId={this.state.eUserId}
                                        Name={this.state.eUserName}
                                        Role={this.state.eUserRole}
                                        Phone={this.state.eUserEmail}
                                        Email={this.state.eUserPhone}
                                        userIsActive={this.state.eUserIsActive}
                                        action={this.state.action}
                                    />
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
