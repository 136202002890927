import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import $ from "jquery";
import APIUtil from "../../Util/ApiUtil";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddExpenseModal from "../Modals/Admin/AddExpenseModal";

import AddFundsModal from "../Modals/Manager/AddFundsModal";
import ReactPaginate from "react-paginate";

let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class ResidentsDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchForm: [],
            residents: [],
            showAddExpenseModal: false,
            showAddFundModal: false,
            addExpenseData: {
                amount: 0,
                date: "",
                expenseTypeId: 0,
                info: "",
                file: {},
            },
            addFundData: {},
            loading: false,
            selectedResident: 1,

            //pagination
            offset: 0,
            perPage: 5,
            currentPage: 0,
            pageCount: 1,
            totalResidents: 0,

        };

        this.closeResidentExpenseModal = this.closeResidentExpenseModal.bind(this);
        this.addResidentExpenseModal = this.addResidentExpenseModal.bind(this);
        this.getResidents = this.getResidents.bind(this);
        this.addResidentFundModal = this.addResidentFundModal.bind(this);
        this.closeResidentFundModal = this.closeResidentFundModal.bind(this);
    }

    componentDidMount() {
        this.getTotalPages();
    }

    addResidentExpenseModal(selectedResident) {
        this.setState({ showAddExpenseModal: true, selectedResident });
    }

    closeResidentExpenseModal() {
        this.setState({ showAddExpenseModal: false, selectedResident: 0 });
        window.location.reload();
    }

    addResidentFundModal(selectedResident) {
        this.setState({ showAddFundModal: true, selectedResident });
    }

    closeResidentFundModal() {
        this.setState({ showAddFundModal: false, selectedResident: 0 });
        window.location.reload()
    }
    getAge(dateOfBirth) {
        var date = new Date(dateOfBirth);
        var today = new Date();
        var age_now = today.getFullYear() - date.getFullYear();
        var m = today.getMonth() - date.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
            age_now--;
        }
        return age_now;
    }
    getResidents() {
        this.setState({ loading: true });
        var url = APIUtil.BASE_URL + APIUtil.RESIDENT_API;
        var userid = careHomeUser.Id;
        if (careHomeUser.Role == "Admin")
            url = url + '?AdminId=' + userid
        else
            url = url + '?CareHomeId=' + careHomeUser.CareHomeId

        url = url + this.state.queryParams;

        fetch(url, {
            method: "GET",
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then(
                async (result) => {
                    await this.setState({ residents: result.result, loading: false, pageNum: (this.state.offset / 5) });
                },
                (error) => {
                    console.log("failed", error);
                }
            )
            .then((result) => {
                this.setState({ loading: false });
            });
    }

    getTotalPages() {
        this.setState({ loading: true });
        var url = APIUtil.BASE_URL + APIUtil.RESIDENT_API
        var userid = careHomeUser.Id;
        if (careHomeUser.Role == "Admin")
            url = url + '?AdminId=' + userid
        else
            url = url + '?CareHomeId=' + careHomeUser.CareHomeId



        fetch(url, {
            method: "GET",
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then(
                async (result) => {
                    await this.setState({
                        pageCount: Math.ceil(result.totalCount / this.state.perPage),
                        totalResidents: result.totalCount,
                        residents: result.result.slice(
                            this.state.offset,
                            this.state.perPage
                        ),
                        loading: false,
                    });
                },
                (error) => {
                    console.log("failed", error);
                }
            );
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        console.log(selectedPage + "--" + offset)
        this.setState(
            {
                currentPage: selectedPage,
                offset: offset,
                queryParams: "&Limit=" + this.state.perPage + "&Offset=" + offset,
            },
            () => {
                this.getResidents();
            }
        );
    };

    render() {
        let total = this.state.residents.length;
        let Residents = [];

        {
            this.state.residents.length > 0 &&
                this.state.residents?.map((resident) => {
                    Residents.push(
                        <tr key={resident.id}>
                            <td>
                                <a
                                    href={"/Manager/ManagerSingleResidentDetails/" + resident.id}
                                >
                                    {resident.name}
                                </a>
                            </td>
                            <td>{this.getAge(resident.dateOfBirth)}</td>
                            <td>{resident.roomNumber}</td>
                            <td>{resident.contact}</td>
                            <td className="text-success">
                                {" "}
                                <i className="fas fa-xs  fa-pound-sign"></i> {resident.balance}
                            </td>
                            <td className="text-centre py-0 align-middle">
                                <a href={`/Manager/ManagerEditResident/` + resident.id}>
                                    <i className="fas fa-edit" title="Edit"></i>
                                </a>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a onClick={() => this.addResidentFundModal(resident.id)}>
                                    <i
                                        className="fas fa-sm fa-plus text-primary"
                                        aria-hidden="true"
                                        data-toggle="modal"
                                        data-target="#Modal-manage"
                                        title="Add Funds"
                                    ></i>
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a onClick={() => this.addResidentExpenseModal(resident.id)}>
                                    <i
                                        className="fas fa-wallet text-primary"
                                        aria-hidden="true"
                                        data-toggle="modal"
                                        data-target="#Modal-manage"
                                        title="Add Expense"
                                    ></i>
                                </a>
                            </td>
                        </tr>
                    );
                });
        }
        return (
            <div className="row  col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Residents</h3>
                        <div className="card-tools">
                            <span className="text-bold text-md text-primary">
                                Count: {this.state.totalResidents}
                            </span>
                            &nbsp; &nbsp;
                            <a
                                href="/Manager/ManagerAddResident"
                                className="btn btn-sm btn-secondary"
                            >
                                <i className="fas fa-plus"></i> Add{" "}
                            </a>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.loading ? (
                            <img src="/dist/img/loader.gif" width="20%" />
                        ) : (
                            <>
                                <table
                                    id="example2"
                                    className="table table-bordered table-hover"
                                >
                                    <thead>
                                        <tr>
                                            <th style={{ width: "25%" }}>Name</th>
                                            <th style={{ width: "15%" }}>Age</th>
                                            <th style={{ width: "15%" }}>Room No.</th>
                                            <th style={{ width: "15%" }}>Contact Info.</th>
                                            <th style={{ width: "15%" }}>Balance</th>
                                            <th style={{ width: "15%" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>{Residents}</tbody>
                                </table>
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    forcePage={this.state.pageNum}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </>
                        )}
                    </div>

                    <div>
                        {this.state.showAddExpenseModal ? (
                            <AddExpenseModal
                                show={this.state.showAddExpenseModal}
                                onHide={this.closeResidentExpenseModal}
                                ResidentId={this.state.selectedResident}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.showAddFundModal ? (
                            <AddFundsModal
                                show={this.state.showAddFundModal}
                                onHide={this.closeResidentFundModal}
                                ResidentId={this.state.selectedResident}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                {/* <!-- /.card-header --> */}

                {/* <!-- /.card-body --> */}
            </div>

        );
    }
}
