import React  from 'react';
import {Modal, Button,Row, Col, Form} from 'react-bootstrap'
import Swal from 'sweetalert2';
import APIUtil from "../../../Util/ApiUtil";


export class DeleteResidentModal extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
        ResidentId: this.props.ResidentId,
    };
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  

  onHide = () => {
    
    this.props.onHide();
  }
  onHideFail = () => {
    
    this.props.onHideFail();
  }
  handleSubmit(event) {

    event.preventDefault();
    document.getElementById("submitbtn").innerHTML = "Processing...";
      fetch(APIUtil.BASE_URL+APIUtil.RESIDENT_API+"/"+this.state.ResidentId, {
        method: "DELETE",

      })
      .then(async response => {

        if (response.ok) {
         Swal.fire({
           toast: true,
           position: 'top-end',
           showConfirmButton: false,
           timer: 5000,
           icon: 'success',
           title: 'Resident deleted  successfully'
         })
         this.onHide();
        }
        else if(response.status === 400){
          response.text().then(data => {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              icon: 'error',
              title: data
            })
            this.onHideFail();
          });
        }
        else{
         Swal.fire({
           toast: true,
           position: 'top-end',
           showConfirmButton: false,
           timer: 5000,
           icon: 'error',
           title: 'Something went wrong'
         })
         this.onHideFail();
        }
       });
    
    
    
  }


    render(){
      
    return (
      <>
      <div>
      <Modal {...this.props} show={this.props.show} onHide={this.handleClose}>
        <Modal.Header>
          <Modal.Title>Delete Resident</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Are you sure you want to delete this resident?
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button id="submitbtn" onClick={this.handleSubmit} variant="primary" >
            Yes, Delete
          </Button>
          <button onClick={this.props.onHideFail} className="btn btn-default" data-dismiss="modal">
                     Cancel
                   </button>
        </Modal.Footer>
      </Modal>     
      </div>
       </>
    );
  } 
 }

 export default DeleteResidentModal;

