import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import '../Css/Adminlte.min.css';
import '../Css/Custom.css';
import $ from 'jquery';


let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class ManagerDashboard extends React.Component {

    render() {
        return (
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="text-primary">{careHomeUser.CareHomeName}</h1>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}