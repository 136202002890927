import React from 'react';
import { BrowserRouter as Router, Route, Link,Switch } from 'react-router-dom';
import '../Css/Adminlte.min.css';
import '../Css/Custom.css';
import {ManagerNavbar} from './ManagerNavbar';
import {ManagerSidebar} from './ManagerSidebar';
import {ManagerDashboard} from './ManagerDashboard';
import {ManagerCards} from './ManagerCards';
import {IncomeBox} from './IncomeBox';
import {ResidentsDetails} from './ResidentsDetails';
import {UsersCard} from './UsersCard';
import {ExpenseCard} from './ExpenseCard';
import { PettyCashReport } from './PettyCashReport';
import Footer from '../Footer';


 export class ManagerIndex extends React.Component {

       
    render() {
        return (
            <>
            <div id="sidebar" className="hold-transition sidebar-mini sidebar-collapse">
                <div className="wrapper">
            <ManagerNavbar />
            <ManagerSidebar />  
            <div className="content-wrapper">
             <section className="content">

             <ManagerDashboard />
            
                <div className="container-fluid">
                <div className="row">
                    <ManagerCards />
                    <IncomeBox />
                    </div>
                    </div>

                    
                    <ResidentsDetails />
                    <div className="row">
                    <UsersCard />
                    <PettyCashReport />
                    </div>
                
               </section>
                    
                </div>
                <Footer /> 
            </div>  
            </div>
                        
            </>
        );
    }
 }
  