import React from "react";
import Swal from "sweetalert2";
import APIUtil from "../../Util/ApiUtil";
import AdminNavbar from "./AdminNavbar";

export class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      FName: "",
      Email: "",
      Subject: "",
      Message: "",
    };

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeSubject = this.onChangeSubject.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeName = (e) => {
    this.setState({ FName: e.target.value });
  };

  onChangeEmail = (e) => {
    this.setState({ Email: e.target.value });
  };

  onChangeSubject = (e) => {
    this.setState({ Subject: e.target.value });
  };

  onChangeMessage = (e) => {
    this.setState({ Message: e.target.value });
  };

  valid() {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (this.state.FName == null || this.state.FName == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (!pattern.test(this.state.Email)) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please provide a valid email",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (!this.state.Email.includes("@" && ".")) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please provide a valid email",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.Subject == null || this.state.Subject == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.Message == null || this.state.Message == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else {
      return true;
    }
  }

  onSubmit = (event) => {
    document.getElementById("submitbtn").innerHTML = "Processing...";
    event.preventDefault();
    if (this.valid()) {
      fetch(APIUtil.BASE_URL + APIUtil.CONTACT_US_API, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.state.FName,
          email: this.state.Email,
          subject: this.state.Subject,
          message: this.state.Message,
        }),
      }).then(async (response) => {
        if (response.ok) {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: "Message Send Successfully",
          });
          document.getElementById("submitbtn").innerHTML = "Save";

          this.setState({ FName: "" });
          this.setState({ Email: "" });
          this.setState({ Subject: "" });
          this.setState({ Message: "" });
        } else {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: "Failed to Send the Message",
          });
          document.getElementById("submitbtn").innerHTML = "Save";
        }
      });
    }
  };

  render() {
    return (
      <>
        <div className="wrapper">
          <AdminNavbar />
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Contact Us</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="/Admin/AdminIndex">Home</a>
                      </li>
                      <li className="breadcrumb-item">Contact Us</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container">
                <div className="card">
                  <div className="card-body row">
                    <div className="col-5 text-center d-flex align-items-center justify-content-center">
                      <div className="">
                      <img src="/images/Logo-Transact-3.png" alt="Transact Logo" />
                      
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="form-group">
                        <label htmlFor="inputName">Name</label>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                        <input
                          type="text"
                          id="inputName"
                          className="form-control"
                          value={this.state.FName}
                          onChange={this.onChangeName}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputEmail">E-Mail</label>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                        <input
                          type="email"
                          id="inputEmail"
                          className="form-control"
                          value={this.state.Email}
                          onChange={this.onChangeEmail}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputSubject">Subject</label>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                        <input
                          type="text"
                          id="inputSubject"
                          className="form-control"
                          value={this.state.Subject}
                          onChange={this.onChangeSubject}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputMessage">Message</label>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                        <textarea
                          id="inputMessage"
                          className="form-control"
                          rows="4"
                          value={this.state.Message}
                          onChange={this.onChangeMessage}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          onClick={this.onSubmit}
                          type="submit"
                          className="btn btn-primary"
                          value="Send message"
                          id="submitbtn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default ContactUs;
