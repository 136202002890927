import React from 'react';
import { BrowserRouter as Router, Route, Link,Switch } from 'react-router-dom';
import '../Css/Adminlte.min.css';
import '../Css/Custom.css';
import APIUtil from "../../Util/ApiUtil";
import axios from "axios";
import { toast } from "react-toastify";
import { Bar } from 'react-chartjs-2';
import Utils from '../../Util/Utils'

export class IncomeBox extends React.Component {

  constructor(props) {
        super(props)
    
        this.state = {
          isGreater:true,
          percentage:0,
            loading:false,
            graphData:{
              labels: ['January', 'February', 'March',
                     'April', 'May'],
              datasets: [
                {
                  label: 'Deposits (in £)',
                  backgroundColor: '#0479fb',
                  data: []
                },
                {
                  label: 'Expenses (in £)',
                  backgroundColor: '#ced4da',
                  data: []
                }
              ]
            }

        }

        this.getGraphData = this.getGraphData.bind(this);
    }


  componentDidMount() {
    this.getGraphData();
  }

  getGraphData(){
    let currentUser = Utils.GetUserDetails();
    let userid = currentUser.Id;
    this.setState({loading:true});
    var url=APIUtil.BASE_URL+'/api/Resident/MonthlyTransactions';

    if(currentUser.Role=="Admin")
    url=url+'?AdminId='+userid
    else   
    url=url+'?CareHomeId='+currentUser.CareHomeId

    fetch(url, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        }
      }) 
    .then((response) => response.json())
    .then(
      async (result) => {
        let labels = [], deposits=[], expenses=[], graphData=this.state.graphData;
        if(result.monthlyTransactions!=null && result.monthlyTransactions!='undefined' && result.monthlyTransactions != undefined)
        if(result.monthlyTransactions.length > 0){
          result.monthlyTransactions.map(data =>{
            labels.push(data.month.substring(0, 3));
            deposits.push(data.deposit);
            expenses.push(data.expense);
          })
        
        }
this.state.percentage=result.percentage;
this.state.isGreater=result.isGreater;
        graphData.labels = labels;
        graphData.datasets[0].data=deposits;
        graphData.datasets[1].data=expenses;
       await this.setState({graphData: graphData, loading:false})
      },
      (error) => {
        console.log("failed");
      }
    )
  }
    render() {
      return (
        <div className="col-lg-5">
  <div className="card" style={{height: '290px'}}>
    <div className="card-header">
      <h4 className="card-title">Monthly Deposits and Expenses </h4>
      <div className="card-tools">
        <span className="text-success">
          <i className="fas fa-arrow-up"></i>{Number(this.state.percentage).toFixed(2)}%
        </span>
      </div>
    </div>
    <div className="card-body">
      <div className="position-relative mb-1">
        <div className="chartjs-size-monitor">
          <div className="chartjs-size-monitor-expand">
            <div className=""></div>
          </div>
          <div className="chartjs-size-monitor-shrink">
            <div className=""></div>
          </div>
        </div>
        <div >
        {this.state.loading ? (<img src='/dist/img/loader.gif' width="20%"/>) : (
         <Bar
         height='90'
          data={this.state.graphData}
          options={{
            title:{
              display:true,
              text:'Monthly Deposits and Expenses',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        /> )}

        </div>
        
      </div>
      {/* <div className="d-flex flex-row justify-content-end">
        <span className="mr-1">
          <i className="fas  fa-xs fa-square text-primary"></i> &nbsp; Deposits
          &nbsp;
        </span>
        <span>
          <i className="fas fa-xs fa-square text-gray"></i> &nbsp; Expenses
        </span>
      </div> */}
    </div>
  </div>
</div>       
	   
      )
    } 
} 
export default IncomeBox