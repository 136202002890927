import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import APIUtil from "../../Util/ApiUtil";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";


export class AdminNavbar extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
      UserId:"", 
      Name:"",
      Email:"",
    }
    this.refreshList = this.refreshList.bind(this);
    this.logout = this.logout.bind(this);


  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.state.UserId=user.Id;
    this.refreshList();
  }

  logout = (e) => {

    localStorage.clear();
    window.location.pathname = '/';
    
}
  refreshList() {
   
    fetch(APIUtil.BASE_URL + APIUtil.GET_USER+'/'+this.state.UserId)
   .then((response) => response.json())
   .then((data) => {
       this.setState({
       Name: data.name,
       Email: data.email
     });
   });
 }
  render() {
    return (
      <nav
        className="main-header
       navbar navbar-expand navbar-white navbar-light"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              id="sidebaropen"
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto float-right">
          <li className="nav-item dropdown">
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <a href="#" className="dropdown-item">
                <div className="media">
                  <img
                    src="/images/Logo-Transact-3.png"
                    alt="User Avatar"
                    className="img-size-50 mr-3 img-circle"
                  />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      Brad Diesel
                      <span className="float-right text-sm text-danger">
                        <i className="fas fa-star"></i>
                      </span>
                    </h3>
                    <p className="text-sm">Call me whenever you can...</p>
                    <p className="text-sm text-muted">
                      <i className="far fa-clock mr-1"></i> 4 Hours Ago
                    </p>
                  </div>
                </div>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <div className="media">
                  <img
                    src="/images/avatar.png"
                    alt="User Avatar"
                    className="img-size-50 img-circle mr-3"
                  />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      John Pierce
                      <span className="float-right text-sm text-muted">
                        <i className="fas fa-star"></i>
                      </span>
                    </h3>
                    <p className="text-sm">I got your message bro</p>
                    <p className="text-sm text-muted">
                      <i className="far fa-clock mr-1"></i> 4 Hours Ago
                    </p>
                  </div>
                </div>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <div className="media">
                  <img
                    src="/images/avatar.png"
                    alt="User Avatar"
                    className="img-size-50 img-circle mr-3"
                  />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      Nora Silvester
                      <span className="float-right text-sm text-warning">
                        <i className="fas fa-star"></i>
                      </span>
                    </h3>
                    <p className="text-sm">The subject goes here</p>
                    <p className="text-sm text-muted">
                      <i className="far fa-clock mr-1"></i> 4 Hours Ago
                    </p>
                  </div>
                </div>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item dropdown-footer">
                See All Messages
              </a>
            </div>
          </li>
      

          <li
            className="dropdown user user-menu open"
            style={{ marginTop: "10px" }}
          >
            <a
              href="#"
              className="dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="true"
            >
              <img
                src="/images/avatar.png"
                className="user-image"
                alt="User Image"
              />{" "}
            </a>
            <ul className="dropdown-menu">
              <li className="user-header1 text-white">
                <p>
                  
                  {this.state.Name}
                  <br />
                  {this.state.Email} <br />
                  <small>Admin</small>
                </p>
              </li>

              <li className="user-body">
<p>
                        <Link to="/Admin/ContactUs"> Contact-us</Link>
                      </p>
                <p>
                  <a href="/" onClick={this.logout}>Sign out</a>

                </p>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    );
  }
}

export default AdminNavbar;
