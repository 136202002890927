import React  from 'react';
import {Modal, Button,Row, Col, Form} from 'react-bootstrap'
import Swal from 'sweetalert2';
import APIUtil from "../../../Util/ApiUtil";


export class ActivateOrDeactivateModal extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      adminId: this.props.adminId,
      adminGroup: this.props.adminGroup,
      adminName: this.props.adminName,
      adminEmail: this.props.adminEmail,
      adminPhone: this.props.adminPhone,
      activeOptions: ["Active", "Inactive"],
      selectedOption: "Active",
      isActive: this.props.adminIsActive,
      adminGroupError: "",
      adminNameError: "",
      adminEmailError: "",
      adminPhoneError: "",

    };
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  

  onActivateOrDeactivateHide = () => {
    
    this.props.onActivateOrDeactivateHide(false);
  }

  handleSubmit(event) {

    event.preventDefault();
    document.getElementById("submitbtn").innerHTML = "Processing...";

    
      fetch(APIUtil.BASE_URL+APIUtil.ADMIN_API+this.state.adminId, {
        method: "PUT",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: this.state.adminId,
          group: this.state.adminGroup,
          name: this.state.adminName,
          email: this.state.adminEmail,
          phone: this.state.adminPhone,
          isActive: !this.state.isActive,

        }),
      })
      .then(async response => {
       
        if (response.ok) {
          const isActiveResponse = this.state.isActive ? "Deactivate" : "Activate"
         Swal.fire({
           toast: true,
           position: 'top-end',
           showConfirmButton: false,
           timer: 5000,
           icon: 'success',
           title: 'Admin '+ isActiveResponse + ' successfully'
         })
         window.location.reload(false);
     
        }
        else{
         Swal.fire({
           toast: true,
           position: 'top-end',
           showConfirmButton: false,
           timer: 5000,
           icon: 'error',
           title: 'Failed'
         })
         document.getElementById("submitbtn").innerHTML = "Save";
        }
       });
    
    
    
  }


    render(){
      
    return (
      <>
      <div>
      <Modal {...this.props} show={this.props.show} onHide={this.handleClose}>
        <Modal.Header>
          <Modal.Title>Group Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Are you sure you want to {this.state.isActive ? "deactivate" : "activate"} this member?
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button id="submitbtn" onClick={this.handleSubmit} variant="primary" >
            Yes, {this.state.isActive ? "Deactivate" : "Activate"}
          </Button>
          <button onClick={this.props.onHide} className="btn btn-default" data-dismiss="modal">
                     Cancel
                   </button>
        </Modal.Footer>
      </Modal>     
      </div>
       </>
    );
  } 
 }

 export default ActivateOrDeactivateModal;

