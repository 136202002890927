import React from "react";
import {ExpenseDetails} from "./ExpenseDetails";
import {ManagerNavbar} from "./ManagerNavbar";
import ManagerSidebar from "./ManagerSidebar";
import {Footer} from "../Footer";
import {BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import APIUtil from "../../Util/ApiUtil";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class SearchExpense extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ExpenseTypes: [],
            searchForm: {ExpenseTypeId: "", Amount: "", Month: ""},
            Expenses: [],
            month: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            amountRange: [
                "Below 50",
                "50-100",
                "100-200",
                "200-300",
                "300-400",
                "400-500",
                "500 and above",
            ],
            searchExpenses: false,
        };

        this.getExpenseTypes = this.getExpenseTypes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.searchExpenses = this.searchExpenses.bind(this);
        this.searchFinish = this.searchFinish.bind(this);
    }

    componentDidMount() {
        this.getExpenseTypes();
    }


    searchFinish() {
        this.setState({searchExpenses: false});
    }

    searchExpenses() {
        this.setState({searchExpenses: true});
    }

    handleChange(event) {
        event.preventDefault();
        let searchForm = this.state.searchForm;
        searchForm[event.target.name] = event.target.value;
        this.setState({searchForm});
    }

    getExpenseTypes() {
        fetch(APIUtil.BASE_URL + APIUtil.EXPENSE_TYPE + "/" + careHomeUser.CareHomeId, {
            method: "GET",
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then(
                (result) => {
                    this.setState({ExpenseTypes: result});
                },
                (error) => {
                    console.log("failed", error);
                }
            );
    }

    render() {
        let ExpenseTypes = [];
        let Month = [];
        let amountRange = [];
        {
            this.state.ExpenseTypes?.map((expenseType) => {
                ExpenseTypes.push(
                    <option key={expenseType.id}
                            value={expenseType.id}
                            defaultValue={this.state.searchForm.expenseType === expenseType.id}
                    >
                        {expenseType.name}
                    </option>
                );
            });
        }
        {
            this.state.amountRange?.map((amount, i) => {
                amountRange.push(
                    <option key={i}
                            value={i + 1}
                            defaultValue={this.state.searchForm.amount === amount}
                    >
                        {amount}
                    </option>
                );
            });
        }

        {
            this.state.month?.map((month) => {
                Month.push(
                    <option key={month}
                            value={month}
                            defaultValue={this.state.searchForm.month === month}
                    >
                        {month}
                    </option>
                );
            });
        }
        return (
            <>
                <div
                    id="sidebar"
                    className="hold-transition sidebar-mini sidebar-collapse"
                >
                    <div className="wrapper">
                        <ManagerNavbar/>
                        <ManagerSidebar/>

                        <div className="content-wrapper">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Expense Report</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item">
                                                    <a href="/Manager/ManagerIndex">Home</a>
                                                </li>
                                                <li className="breadcrumb-item active">Expenses</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="content">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Search</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="inputName">Amount</label>
                                                    <select
                                                        className="form-control"
                                                        name="Amount"
                                                        onChange={this.handleChange}
                                                    >
                                                        <option
                                                            defaultValue={
                                                                this.state.searchForm.amountRange === ""
                                                            }
                                                            value=""
                                                        >
                                                            Select
                                                        </option>
                                                        {amountRange}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="inputName">Type</label>
                                                    <select
                                                        className="form-control"
                                                        name="ExpenseTypeId"
                                                        onChange={this.handleChange}
                                                    >
                                                        <option
                                                            defaultValue={
                                                                this.state.searchForm.expenseType === ""
                                                            }
                                                            value=""
                                                        >
                                                            Select
                                                        </option>
                                                        {ExpenseTypes}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="inputName">Month</label>
                                                    <select
                                                        className="form-control"
                                                        name="Month"
                                                        onChange={this.handleChange}
                                                    >
                                                        <option
                                                            defaultValue={this.state.searchForm.month === ""}
                                                            value=""
                                                        >
                                                            Select
                                                        </option>
                                                        {Month}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="inputName"></label>
                                                    <div style={{padding: ".375rem .75rem"}}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-block btn-primary search1"
                                                            onClick={this.searchExpenses}
                                                        >
                                                            Search
                                                        </button>
                                                        {/* <!--</div>--> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* // <!-- /.card-body --> */}
                                        </div>
                                        {/* // <!-- /.card --> */}
                                    </div>
                                </div>

                                <ExpenseDetails
                                    expenseTypes={this.state.ExpenseTypes}
                                    months={this.state.month}
                                    searchForm={this.state.searchForm}
                                    searchExpenses={this.state.searchExpenses}
                                    searched={this.searchFinish}
                                />
                            </section>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </>
        );
    }
}

export default SearchExpense;
