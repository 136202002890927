import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import $ from "jquery";

import APIUtil from "../../Util/ApiUtil";
import { toast } from "react-toastify";

let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class ManagerCards extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            careHomeUserId: parseInt(careHomeUser.Id),
            careHomeUserInfo: {
                residents: 0,
                balance: 0,
                deposits: 0,
                expenses: 0,
                careHomeId: 0,
            },
            positiveBalance: true
        };

        this.getCareHomeDetails = this.getCareHomeDetails.bind(this);
    }

    getCareHomeDetails() {

        fetch(
            APIUtil.BASE_URL + APIUtil.CARE_HOME_MANAGER_SUMMARY + "/" + careHomeUser.CareHomeId,
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then(
                (result) => {
                    if (!isNaN(result.balance))
                        if (parseInt(result.balance) < 0)
                            this.setState({ careHomeUserInfo: result, positiveBalance: false });
                        else
                            this.setState({ careHomeUserInfo: result, positiveBalance: true });

                    window.careHomeId = result.careHomeId;
                },
                (error) => {
                    console.log("failed");
                }
            );
    }

    componentDidMount() {
        this.getCareHomeDetails();
    }

    render() {
        return (
            <div className="col-lg-7" >
                <div className="row">


                    <div className="col-6">
                        <div className="info-box bg-white shadow" style={{ height: '140px', marginBottom: '10px' }}>

                            <span className="info-box-icon text-primary">
                                <i className="fas fa-md fa-house-user"></i>
                            </span>


                            <div className="info-box-content">
                                <span className="info-box-number  text-lg">
                                    {this.state.careHomeUserInfo.residents}
                                </span>
                                <span className="info-box-text">Residents</span>
                            </div>

                        </div>

                    </div>

                    <div className="col-6">
                        <div className="info-box shadow bg-white" style={{ height: '140px', marginBottom: '10px' }}>

                            <span className="info-box-icon text-primary">
                                <i className="fas fa-md fa-money-bill"></i>
                            </span>



                            <div className="info-box-content">
                                <span className="info-box-number text-lg">
                                    <i className="fas fa-xs fa-pound-sign"></i>{" "}
                                    {this.state.careHomeUserInfo.balance}{" "}
                                </span>
                                <span className="info-box-text">Balance</span>
                            </div>
                            {/* <!-- /.info-box-content --> */}
                        </div>
                        {/* <!-- /.info-box --> */}
                    </div>
                </div>
                {/* <!-- /.col --> */}
                <div className="row">
                    <div className="col-6">
                        <div className="info-box shadow" style={{ height: '140px', marginBottom: '10px' }}>
                            <span className="info-box-icon text-success">
                                <i className="fas fa-md fa-long-arrow-alt-down"></i>
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-number  text-lg">
                                    <i className="fas fa-xs fa-pound-sign"></i>{" "}
                                    {this.state.careHomeUserInfo.deposits}
                                </span>
                                <span className="info-box-text">Deposits</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="info-box shadow" style={{ height: '140px', marginBottom: '10px' }}>
                            <span className="info-box-icon text-warning" >
                                <i className="fas fa-md fa-long-arrow-alt-up"></i>
                            </span>
                            <div className="info-box-content">
                                <span className="info-box-number  text-lg">
                                    <i className="fas fa-xs fa-pound-sign"></i>{" "}
                                    {this.state.careHomeUserInfo.expenses}
                                </span>
                                <span className="info-box-text">Expenses</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
