import React from "react";
// import '.../Css/Adminlte.min.css';
// import '.../Css/Custom.css';
import Swal from "sweetalert2";
import APIUtil from "../../../Util/ApiUtil";

import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class EditThresholdModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ResidentId: this.props.ResidentId,
      Threshold: this.props.Threshold,
      ResidentName: this.props.ResidentName,
    }
    
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onChangeAmount(e) {
    const value = e.target.value.replace(/\D/, "");

    this.setState({
      Threshold: value
    })
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));

    this.setState({
      ResidentId: this.props.ResidentId,
      careHomeId: user.CareHomeId,
    })
  }

  handleSubmit(event) {

    document.getElementById("submitbtn").innerHTML = "Processing...";
    event.preventDefault();
console.log(this.state.Threshold );
    if (this.state.Threshold == null || this.state.Threshold == "") {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Please fill the amount',
      })
      document.getElementById("submitbtn").innerHTML = "Save";
    }

    else {      fetch(APIUtil.BASE_URL + APIUtil.SAVE_THRESHOLD, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: parseFloat(this.state.Threshold),
          residentId: parseInt(this.props.ResidentId),
        }),
      })
        .then(async response => {

          if (response.ok) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              icon: 'success',
              title: 'Updated successfully'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
            this.props.onHide();
          }
          else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              icon: 'error',
              title: 'Failed to update'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
          }
        });
      }
    }
  



  render() {

    return (
      <Modal {...this.props}
        show={this.props.show} onHide={this.handleClose}
        size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title>Edit Minimum Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form >

            <div className="row">
              <p>Set the minimum balance threshold for {this.state.ResidentName}.</p>
              <div className="form-group col-md-6">
                <label>Minimum balance</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-pound-sign"></i>
                    </span>
                  </div>
                  <input type="text" className="form-control"
                    value={this.state.Threshold}
                    onChange={this.onChangeAmount}
                  />
                </div>
              </div>              
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className="justify-content-start">
          <div onClick={this.handleSubmit} className="btn btn-primary "
            id="submitbtn"
          >
            Save
          </div>
          <button
            onClick={this.props.onHide}
            className="btn btn-default"
            data-dismiss="modal"
          >
            Cancel
          </button>          </Modal.Footer>
      </Modal>
    );
  }
}


export default EditThresholdModal;