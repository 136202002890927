import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";

export class ManagerDetails extends React.Component {
  render() {
    return (
      <h1> Manager Details is shifted to ManagerSearchResident</h1>
    );
  }
}
