import React, { input } from "react";
import "./Css/Adminlte.min.css";
import "./Css/Custom.css";
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import APIUtil from "../Util/ApiUtil";


export class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      user: null,
      loggedIn: false,
      invalidLogin: false,
      error:"",
      
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
  }


  onCheckboxChange = () => {
    this.setState({ checked: !this.state.checked });
  };

  handleChangeEmail(event) {
    this.setState({
      error: "",
      email: event.target.value,
    });
  }
  handleChangePassword(event) {
    this.setState({
      error: "",
      password: event.target.value
    });
  }



   handleKeypress = e => {
   
  if (e.keyCode === 13) {
    this.btn.click();
  }
};

  handleSubmit = (event) => {

    event.preventDefault();
    try{
    fetch(APIUtil.BASE_URL+APIUtil.AUTH_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
        password: CryptoJS.MD5(this.state.password).toString()
      }),
    })
      .then(async response => {
        const data= await response.json();
        
        if (response.ok) {
          let decoded_data = jwt_decode(data);
          
          var role = decoded_data.Role;
          
          switch (role) {
            case "SuperAdmin":
              window.location.href = "/SuperAdmin/DetailsAdmin";
              break;
            case "Admin":
              window.location.href = "/Admin/AdminIndex";
              break;
            case "CareHomeManager":
              window.location.href = "/Manager/ManagerIndex";
              break;
              case "AssociateManager":
                window.location.href = "/Manager/ManagerIndex";
                break;
              case "FamilyMember":
                window.location.href = "/Resident/ResidentIndex";
                break;
          }
  
          localStorage.setItem("user", JSON.stringify(decoded_data));
        }
        else{
          this.setState({error:"Invalid User Credentials"})
        }
      }).catch(err => {
        this.setState({error:"Something went wrong. Please try again"})
      });
    }catch(e){
      this.setState({error:"Something went wrong. Please try again"})

    }
    
  };

 
  
  render() {
    return (
      
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <img src="/images/Logo-Transact-3.png" alt="Transact Logo" />
          </div>
          {/* <!-- /.login-logo --> */}
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in </p>


              <form onSubmit={this.handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    onKeyPress={this.handleKeypress}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>

               
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                    onKeyPress={this.handleKeypress}

                    required
                  />
                  <p></p>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                {this.state.error ?
                (<p style={{color:"red",fontSize:"14px"}}>{this.state.error}</p>
                ) : ("") }

                <div className="row">
                  <div className="col-8">
                    <div className="icheck-primary">
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember">Remember Me</label>
                    </div>
                  </div>

                  <div className="col-4">
                    <button onClick={this.handleSubmit}
                    ref={node => (this.btn = node)}
                    type="submit"
                    className="btn btn-primary btn-block"
                    >
                      
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
              <br />

              <p className="mb-1">
                <Link to={"/ForgotPassword"}>
                  I forgot my password
                </Link>
              </p>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
