import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import '../Css/Adminlte.min.css';
import '../Css/Custom.css';
import '../Css/Resident.css';
import { ResidentNavbar } from './ResidentNavbar';
import { Home } from './Home';
import { Expenses } from './Expenses';
import { Payments } from './Payments';
import APIUtil from "../../Util/ApiUtil";
import Footer from "../Footer";
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

let careHomeUser = JSON.parse(localStorage.getItem("user"));


export class ResidentIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      careHomeUserId: parseInt(careHomeUser.Id),
      notifications: "",
      ExpenseTypes: [],
      Deposits: 0.0,
      Expense: 0.0,
      Balance: 0.0,
      Carehomes: 0,
      rNotification: 0,
      NotificationTime:"",
      showNotifiction:false,
      name:careHomeUser.ResidentName,
    };
    this.getExpenseTypes = this.getExpenseTypes.bind(this);
    this.onChangeCareHomes = this.onChangeCareHomes.bind(this);
    //this.onChangeTotalResidents = this.onChangeTotalResidents.bind(this);
    this.onChangeBalance = this.onChangeBalance.bind(this);
    this.onChangeDeposits = this.onChangeDeposits.bind(this);
    this.onChangeExpense = this.onChangeExpense.bind(this);

    this.refreshList = this.refreshList.bind(this);
    this.refreshList();

    this.refreshList1 = this.refreshList1.bind(this);
    this.refreshList1();
  }


  componentDidMount() {
    this.getExpenseTypes();
  }



  onChangeCareHomes(event) {
    this.setState({
      Carehomes: event.target.value,
    });
  }
  onChangeNotification(event) {
    this.setState({
      rNotification: event.target.value,
    });
  }
  onChangeBalance(event) {
    this.setState({
      Balance: event.target.value,
    });
  }
  onChangeDeposits(event) {
    this.setState({
      Deposits: event.target.value,
    });
  }
  onChangeExpense(event) {
    this.setState({
      Expense: event.target.value,
    });
  }

  refreshList() {
    let response = fetch(APIUtil.BASE_URL + APIUtil.RESIDENT_SUMMARY + '/' + careHomeUser.ResidentId)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Carehomes: data.careHome,
          Balance: data.balance,
          Deposits: data.deposits,
          Expense: data.expenses,
        });


      });
  }


  refreshList1() {
    fetch(APIUtil.BASE_URL + APIUtil.RESIDENT_NOTIFICATION + "/" + careHomeUser.ResidentId)
      .then((response) => response.json())
      .then((data) => {
        
        var date= Date.parse(data.date);
        if(date>0)
        {
          const diffTime = Math.abs(Date.now() - date);
          const diffHours = Math.ceil(diffTime / (1000 * 60 * 60 )); 
          this.setState({  NotificationTime:diffHours});
        }
        if(data.hasOwnProperty('notification'))
        if(  data.notification!==null && data.notification.length>0)
        this.setState({ notifications: data.notification,showNotifiction:true });


      });
  }
  getExpenseTypes() {

    fetch(APIUtil.BASE_URL + APIUtil.EXPENSE_TYPE, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((response) => response.json(console.log(response)))
      .then(
        (result) => {
          this.setState({ ExpenseTypes: result })
        },
        (error) => {
          console.log("failed");
        }
      )
  }

  render() {
    let ExpenseTypes = [];

    return (
      <>
        <div className="hold-transition layout-top-nav">
          <div className="wrapper">
            <ResidentNavbar />
            <div className="content-wrapper">
              <div className="content-header">
                <div className="container">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1 className="m-0"> {this.state.name}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-6 sm-1">
                          <div className="info-box1 bg-white shadow">
                            <span className="info-box-icon text-success">
                              <i className="fas fa-long-arrow-alt-down"></i>
                            </span>
                            <div className="info-box-content">
                              <span className="info-box-number">
                                {" "}
                                <b className="d-block">
                                  <i className="fas fa-xs fa-pound-sign"></i> {this.state.Deposits}
                                </b>
                              </span>
                              <span className="info-box-text">Deposits</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 sm-1">
                          <div className="info-box1 bg-white shadow">
                            <span className="info-box-icon text-warning">
                              <i className="fas fa-long-arrow-alt-up"></i>
                            </span>
                            <div className="info-box-content">
                              <span className="info-box-number">
                                {" "}
                                <b className="d-block">
                                  <i className="fas fa-xs fa-pound-sign"></i> {this.state.Expense}
                                </b>
                              </span>
                              <span className="info-box-text">Expenses</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 sm-1">
                          <div className="info-box1 bg-white shadow">
                            <span className="info-box-icon text-primary">
                              <i className="fas fa-money-bill"></i>
                            </span>
                            <div className="info-box-content">
                              <span className="info-box-number text-primary">
                                <b className="d-block">
                                  {" "}
                                  <i className="fas fa-xs fa-pound-sign"></i> {this.state.Balance}
                                </b>
                              </span>
                              <span className="info-box-text">Balance</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 sm-1">
                          <div className="info-box1 bg-white shadow">
                            <span className="info-box-icon text-primary">
                              <i className="fas fa-home"></i>
                            </span>
                            <div className="info-box-content">
                              <span className="info-box-number">
                                <b className="d-block"> {this.state.Carehomes}</b>
                              </span>
                              <span className="info-box-text">Care Home</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title ">Notifications</h5>
                        </div>

                        <div className="card-body mt-3 mb-3">
                         {this.state.showNotifiction &&( <ul className="todo-list" data-widget="todo-list">
                            <li>
                              <span className="text">
                                {this.state.notifications}
                              </span>
                           {/*   <span className="float-right">
 {this.state.NotificationTime!=""? <small className="badge badge-danger"> {this.state.NotificationTime} hours</small>:""}
                            
                              </span> */}
                            </li>
                          </ul>)}
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-7">
                      <Expenses expenseTypes={this.state.ExpenseTypes}
                        months={this.state.month} />
                    </div>
                    <div className="col-lg-5">
                      <Payments />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>







      </>
    );
  }
}