import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";

export class ExpenseTypeList extends React.Component {
  render() {
    return (
       <h1>Expense Type List is shifted to ExpenseTypeDetails</h1> 

    )
  }
}
   