import React from "react";
// import '.../Css/Adminlte.min.css';
// import '.../Css/Custom.css';
import Swal from "sweetalert2";
import APIUtil from "../../../Util/ApiUtil";

import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class AddFundsModal extends React.Component {
  constructor(props) {
    super(props)
    const arr = window.location.href.split("/");
    const rid = parseInt(arr[arr.length - 1]);

    this.state = {
      Amount: this.props.Amount === undefined ? 0.0 : this.props.Amount,
      EnteredDate: this.props.Date === undefined ? new Date() : new Date(this.props.Date),
      Info: this.props.Info === undefined ? "" : this.props.Info,
      moneyReceivedError: "",
      dateError: "",
      addInfoError: "",
      ResidentId: 0,
      TransactionId: this.props.TransactionId,
      IsAdd: this.props.TransactionId === undefined
    }
    if (!isNaN(rid))
      this.state.ResidentId = rid
    else if (!isNaN(this.props.ResidentId))
      this.state.ResidentId = this.props.ResidentId
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeInfo = this.onChangeInfo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeResidentId = this.onChangeResidentId.bind(this);

  }

  onChangeAmount(e) {
    const value = e.target.value.replace(/[^0-9\.]+/g, "");
    this.setState({
      Amount: value
    })
  }

  onChangeDate(date) {
    this.setState({
      EnteredDate: date
    })
  }

  onChangeInfo(event) {
    this.setState({
      Info: event.target.value
    })
  }

  onChangeResidentId(event) {
    this.setState({
      ResidentId: event.target.value,
    });
  }

  valid() {
    if (this.state.Amount == null || this.state.Amount === "" || this.state.Amount ===  0.0) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Please fill all the mandatory fields'
      })
      document.getElementById("submitbtn").innerHTML = "Save";
    }
    else if (this.state.EnteredDate == null || this.state.EnteredDate === "") {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Please fill all the mandatory fields'
      })
      document.getElementById("submitbtn").innerHTML = "Save";
    }

    else if (this.state.Info == null || this.state.Info === "") {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Please fill all the mandatory fields'
      })
      document.getElementById("submitbtn").innerHTML = "Save";
    }

    else {
      return true;
    }

  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (!isNaN(this.props.CareHomeId))
      this.setState({
        careHomeId: this.props.CareHomeId,
      })
  }

  handleSubmit(event) {

    document.getElementById("submitbtn").innerHTML = "Processing...";

    event.preventDefault();
    if (this.valid()) {

      fetch(APIUtil.BASE_URL + APIUtil.Add_FUNDS, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          amount: parseFloat(this.state.Amount),
          date: this.state.EnteredDate,
          info: this.state.Info,
          residentId: parseInt(this.state.ResidentId),
          careHomeId: parseInt(this.state.careHomeId),
          id: this.props.TransactionId
        }),
      })
        .then(async response => {

          if (response.ok) {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              icon: 'success',
              title: 'Funds added successfully'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
            this.setState({ Amount: "", Info: "" });
            this.props.onHide();
          }
          else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 5000,
              icon: 'error',
              title: 'Failed to add Funds'
            })
            document.getElementById("submitbtn").innerHTML = "Save";
          }
        });
    }
  }



  render() {
    return (

      <Modal {...this.props}
        show={this.props.show} onHide={this.handleClose}
        size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          {this.state.IsAdd ? ( <Modal.Title>Add Funds</Modal.Title>) : ( <Modal.Title>Edit Funds</Modal.Title>)}
        </Modal.Header>
        <Modal.Body>
          <form >

            <div className="row">
              <div className="form-group col-md-6">
                <label>Money Received</label>
                <span
                  style={{ color: "red", fontSize: "14px" }}
                >
                  *
                </span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-pound-sign"></i>
                    </span>
                  </div>
                  <input type="text" className="form-control"
                    value={this.state.Amount}
                    onChange={this.onChangeAmount}
                  />
                </div>
              </div>
              {/* <!-- /.input group --> */}
              <div className="form-group col-md-6">
                <label>Date</label>
                <span
                  style={{ color: "red", fontSize: "14px" }}
                >
                  *
                </span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-calendar-alt"></i>
                    </span>{" "}
                  </div>
                  <DatePicker
                    selected={this.state.EnteredDate}
                    onChange={this.onChangeDate}
                    name="startDate"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                {/* <!-- /.input group -->  */}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputMessage">Add Info</label>
                <span
                  style={{ color: "red", fontSize: "14px" }}
                >
                  *
                </span>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">
                      <i className="fas fa-sm fa-hashtag"></i>
                    </span>{" "}
                  </div>
                  <textarea
                    id="inputMessage"
                    className="form-control"
                    rows="1"
                    value={this.state.Info}
                    onChange={this.onChangeInfo}
                  ></textarea>
                </div>
                {/* <!-- /.input group -->  */}
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className="justify-content-start">
          <div onClick={this.handleSubmit} className="btn btn-primary "
            id="submitbtn"
          >
            Save
          </div>
          <button
            onClick={this.props.onHide}
            className="btn btn-default"
            data-dismiss="modal"
          >
            Cancel
          </button>        </Modal.Footer>
      </Modal>
    );
  }
}


export default AddFundsModal;