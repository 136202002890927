import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import AdminIndexCards from "./AdminIndexCards";
import { IncomeBox } from "../ManagerComponents/IncomeBox";
import { Footer } from "../Footer";
import { NumberOfResidents } from "./NumberOfResidents";
import { ManagerDashboard } from "../ManagerComponents/ManagerDashboard";
import { CareHomesListTable } from "./CareHomesListTable";

export class AdminIndex extends React.Component {
  render() {
    return (
      <div
      id="sidebar"
      className="hold-transition sidebar-mini sidebar-collapse"
      >
        <div className="wrapper">
          <AdminNavbar />
          <AdminSidebar />
          <div className="content-wrapper">
            <ManagerDashboard />
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <AdminIndexCards />
                  <IncomeBox />
                </div>
              </div>
              <div className="row">
                <NumberOfResidents />
                <CareHomesListTable />
              </div>
            </section>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}
