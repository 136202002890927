import React from "react";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import { AddUserModal } from "../Modals/Manager/AddUserModal";
import { EditUserModal } from "../Modals/Manager/EditUserModal";
import { ActivateOrDeactivateModal } from "../Modals/Manager/ActivateOrDeactivateModal";

import { Button, ButtonToolbar } from "react-bootstrap";
import ApiUtil from "../../Util/ApiUtil";
import Footer from "../Footer";
import { ManagerNavbar } from "./ManagerNavbar";
import { ManagerSidebar } from "./ManagerSidebar";
import { Link } from "react-router-dom";
import axios from "axios";
let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class ManagerUserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userdata: [],
      addModalShow: false,
      editModalShow: false,
      activateordeactivateModalShow: false,
      loading: false,
      // ActivateOrDeactivateModalShow: false,

      Name:"",
      Role:"",

      //edit states
      eUserId: "",
      eUserName: "",
      eUserRole: "",
      eUserPhone: "",
      eUserEmail: "",
    };

    this.refreshList = this.refreshList.bind(this);
    this.refreshList();
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onClick();
  }

  onChangeName(event) {
    this.setState({
      Name: event.target.value,
    });
  }

  
  onChangeRole(event) {
    this.setState({
      Role: event.target.value,
    });
  }
  refreshList() {
    {this.setState({loading:true})};
    fetch(ApiUtil.BASE_URL + ApiUtil.GET_ASSOCIATE_MANAGER_API+"/"+careHomeUser.Id)
      .then((response) => response.json())
      .then(async (data) => {
        await this.setState({ userdata: data.result, loading:false });
      })
  }

  onClick = async (event) => {
    // event.preventDefault();
    let url = ApiUtil.BASE_URL+ApiUtil.GET_ASSOCIATE_MANAGER_API+"/"+careHomeUser.CareHomeId+`?Name=`+this.state.Name+`&Role=`+this.state.Role.trim();
   
    let responseData = await axios.get(url);
    this.setState({
      userdata: responseData.data.result,
    });
    
  };

  addModalClose = (value) =>{
    this.refreshList();
    this.setState({ addModalShow: false });}


  editModalClose = (value) =>{ 
    this.refreshList();
    this.setState({ editModalShow: false });
  }
  activateordeactivateModalClose = (value) => this.setState({activateordeactivateModalShow: false})

  render() {
    return (
      <div
        id="sidebar"
        className="hold-transition sidebar-mini sidebar-collapse"
        {...this.props}
      >
        <div className="wrapper">
          <ManagerNavbar />
          <ManagerSidebar />
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Users</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/Manager/ManagerIndex">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Users</li>
                      <li className="breadcrumb-item active">Search</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Search</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="form-group col-md-4">
                            <label htmlFor="inputName">Name</label>
                            <input
                              type="text"
                              id="inputName"
                              className="form-control"
                              value={this.state.Name}
                              onChange={this.onChangeName}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="inputName">Role</label>
                            <input
                              type="text"
                              id="inputName"
                              className="form-control"
                              value={this.state.Role}
                              onChange={this.onChangeRole}
                            />
                          </div>
                          <div className="form-group col-md-2">
                            <label htmlFor="inputName"></label>
                            <div style={{ padding: ".375rem .75rem" }}>
                              <button
                              onClick={this.onClick}
                                type="button"
                                className="btn btn-block btn-primary search1"
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Details</h3>
                        <div className="card-tools">
                          <Button
                            className="btn btn-sm btn-secondary"
                            onClick={() =>
                              this.setState({ addModalShow: true })
                            }
                            aria-hidden="true"
                            data-toggle="modal"
                            data-target="#Modal-form-add"
                          >
                            <i className="fas fa-plus"></i>
                            &nbsp;
                            Add
                          </Button>
                          {this.state.addModalShow ? (
                            <AddUserModal
                            show={this.state.addModalShow}
                            onHide={this.addModalClose}
                              refreshList={this.refreshList}
                            />
                          ) : null}

                           {/* {this.state.addModelShow ? (
                            <AddUserModel
                              show={this.state.addModelShow}
                              onHide={addModelClose} refreshList={this.refreshList}
                            />
                            ) : null}	 */}
                        </div>
                      </div>
                      {/* <!-- /.card-header --> */}
                  <div className="card-body">
                      {this.state.loading ? (
                      <div style={{textAlign: 'center'}}>
                      <img src="/dist/img/loader.gif" width="20%" />
                       </div>) : (
                        <>
                        <table
                          id="example2"
                          className="table table-bordered table-hover"
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "20%" }}>Name</th>
                              <th style={{ width: "20%" }}>Role</th>
                              <th style={{ width: "20%" }}>Phone No.</th>
                              <th style={{ width: "30%" }}>E-mail</th>
                              <th style={{ width: "10%" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.userdata.map((user) => (
                              <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.associateManagerRole}</td>
                                <td>{user.phone}</td>
                                <td>{user.email}</td>

                                <td className="text-centre py-0 align-middle">
                                  <a 
                                    className="fas fa-sm fa-edit"
                                    onClick={() =>
                                      this.setState({
                                        editModalShow: true,
                                        eUserId: user.id,
                                        eUserName: user.name,
                                        eRoleId: user.roleId,
                                        eUserRole: user.associateManagerRole,
                                        eUserPhone: user.phone,
                                        eUserEmail: user.email,
                                      })
                                    }
                                    aria-hidden="true"
                                    data-toggle="modal"
                                    data-target="#Modal-form-edit"
                                    title="Edit"
                                  ></a>
                                 
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <a>
                                   <i
                                  className={
                                    user.isActive
                                      ? "fas fa-sm fa-user-times text-primary"
                                      : "fas fa-sm fa-user-times text-gray"
                                  }
                                  aria-hidden="true"
                                  data-toggle="modal"
                                  data-target="#modal-deactivate"
                                  title={
                                    user.isActive ? "Deactivate" : "Activate"
                                  }
                                  onClick={() =>
                                    this.setState({
                                      activateordeactivateModalShow: true,
                                        eUserId: user.id,
                                        eUserName: user.name,
                                        eUserRole: user.associateManagerRole,
                                        eUserPhone: user.phone,
                                        eUserEmail: user.email,
                                        eUserisActive: user.isActive,
                                      })
                                    
                                  }
                                ></i>
                                  </a>
                                </td>
                              </tr>
                            ))}

                            {this.state.editModalShow && (
                              <EditUserModal
                                show={this.state.editModalShow}
                                onHide={this.editModalClose}
                                onClick={this.onClick}
                                userId={this.state.eUserId}
                                Name={this.state.eUserName}
                                Role={this.state.eUserRole}
                                Phone={this.state.eUserPhone}
                                Email={this.state.eUserEmail}
                              />
                            )}

                          {this.state.activateordeactivateModalShow && (
                          <ActivateOrDeactivateModal
                            show={this.state.activateordeactivateModalShow}
                            onHide={this.activateordeactivateModalClose}
                            onClick={this.onClick}
                            userId={this.state.eUserId}
                            Name={this.state.eUserName}
                            Role={this.state.eUserRole}
                            Phone={this.state.eUserPhone}
                            Email={this.state.eUserEmail}
                            userIsActive={this.state.eUserisActive}
                            
                          />
                        )}
                          </tbody>
                        </table>
              </>
              )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default ManagerUserDetails;
