import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import { Redirect } from "react-router-dom";
import axios from "axios";
import APIUtil from "../../Util/ApiUtil";




export class LoginResident extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      
      phoneNumber: "",
      otp: "",
    };

    this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    
  }

  handleChangePhoneNumber(event) {
    this.setState({
      phoneNumber: event.target.value,
    });
  }

  handleSubmit(event) {
    

    fetch(APIUtil.BASE_URL+APIUtil.AUTH_RESIDENT_URL+this.state.phoneNumber,{

        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
     }).then(response =>{
      if(response.ok) {
        console.log("response is 200");
        window.location.href="/Resident/OTPScreen";
      }
      else{
        console.log("response is not 200")
      }
      })
  }
  

  render() {
    return (
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            {/* <!-- <a href="logo-transact-sm-200"><b>Admin</b>LTE</a>--> */}
            <img src="/images/Logo-Transact-3.png" alt="Transact Logo" />
          </div>
          {/* <!-- /.login-logo --> */}
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in </p>

              <form action="#" method="post">
                <div className="input-group mb-3">
                  <input
                    type="phone-no."
                    className="form-control"
                    placeholder="Phone No."
                    value={this.state.phoneNumber}
                    onChange={this.handleChangePhoneNumber}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-phone-alt"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <div className="icheck-primary">
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember">Remember Me</label>
                    </div>
                  </div>
                  {/* <!-- /.col --> */}
                  <div className="col-4">
                    <div
                      onClick={this.handleSubmit}
                      type="button"
                      className="btn btn-primary btn-block"
                    >
                      {" "}
                      Sign In
                    </div>
                  </div>
                  {/* <!-- /.col --> */}
                </div>
              </form>
            </div>
            {/* <!-- /.login-card-body --> */}
          </div>
        </div>
      </div>
    );
  }
}
