import React from "react";
import Swal from "sweetalert2";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ManagerNavbar } from "./ManagerNavbar";
import { ManagerSidebar } from "./ManagerSidebar";
import { Footer } from "../Footer";
import axios from "axios";
import APIUtil from "../../Util/ApiUtil";
import $ from "jquery";

let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class ManagerEditResident extends React.Component {
  constructor(props) {
    super(props);

    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const sDate = new Date(year - 1, month, day - 1, date.getHours(), date.getMinutes(), date.getSeconds())
    this.state = {
      careHomeUserId: parseInt(careHomeUser.Id),
      selectedDate: sDate,
      maxDate: sDate,
      dateOfBirth: moment(sDate).format(),
      residentId: 0,
      Name: "",
      Age: 1,
      RoomNumber: "0",
      FMName: "",
      FMPhone: "",
      FMEmail: "",
      FMHouseNumber: "",
      FMStreet: "",
      FMTown: "",
      FMCountryOptions: ["England", "Scotland", "Wales", "Northern Ireland"],
      countrySelected: "",
      FMPostCode: "",
    };
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeAge = this.onChangeAge.bind(this);
    this.onChangeRoomNumber = this.onChangeRoomNumber.bind(this);
    this.onChangeFMName = this.onChangeFMName.bind(this);
    this.onChangeFMPhoneNumber = this.onChangeFMPhoneNumber.bind(this);
    this.onChangeFMEmail = this.onChangeFMEmail.bind(this);
    this.onChangeFMHouseNumber = this.onChangeFMHouseNumber.bind(this);
    this.onChangeFMStreet = this.onChangeFMStreet.bind(this);
    this.onChangeFMTown = this.onChangeFMTown.bind(this);
    this.onChangeFMCountry = this.onChangeFMCountry.bind(this);
    this.onChangeFMPostalCode = this.onChangeFMPostalCode.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);

  }

  onChangeDate(date) {
    try {
      var today = new Date();
      var age_now = today.getFullYear() - date.getFullYear();
      var m = today.getMonth() - date.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
        age_now--;
      }

      const sDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), today.getHours(), today.getMinutes(), today.getSeconds())

      this.setState({
        Age: age_now,
        dateError: "",
        dateOfBirth: sDate,
        selectedDate: date,
      });
    }
    catch {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Enter valid date of birth",
      });
    }


  }
  valid() {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (this.state.Name == null || this.state.Name == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill  the Mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.dateOfBirth == null || this.state.dateOfBirth == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill Date of birth",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
    } else if (this.state.Age == null || this.state.Age == "" || this.state.Age == 0) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill  the Mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.RoomNumber == null || this.state.RoomNumber == "" || this.state.RoomNumber == "0") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill all the Mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.FMName == null || this.state.FMName == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill  the Mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.FMPhone == null || this.state.FMPhone == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill  the Mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.FMPhone.length !== 10 && this.state.FMPhone.length !== 11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Phone number should not be less than 10-digits and  more than 11-digits",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    else if (this.state.FMEmail != null && this.state.FMEmail != '' && (!pattern.test(this.state.FMEmail))) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please provide a valid email",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    // else if ((!this.state.FMEmail.includes("@" && ".")) && (this.state.FMEmail != "")) {
    //   Swal.fire({
    //     toast: true,
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 5000,
    //     icon: "error",
    //     title: "Please provide a valid email5",
    //   });
    //   document.getElementById("submitbtn").innerHTML = "Save";
    //   return false;
    // }
    else if (
      this.state.FMHouseNumber == null ||
      this.state.FMHouseNumber == ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill  the Mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.FMStreet == null || this.state.FMStreet == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill  the Mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.FMTown == null || this.state.FMTown == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill  the Mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (
      this.state.countrySelected == null ||
      this.state.countrySelected == ""
    ) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill  the Mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.FMPostCode == null || this.state.FMPostCode == "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Please fill  the Mandatory fields",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    } else if (this.state.FMPostCode.length !== 6 && this.state.FMPostCode.length !== 7) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "PostCode should not be less than 6-digits and  more than 7-digits",
      });
      document.getElementById("submitbtn").innerHTML = "Save";
      return false;
    }
    else {
      return true;
    }
  }

  onChangeName(e) {
    this.setState({
      Name: e.target.value,
    });
  }
  onChangeAge(e) {
    const value = e.target.value.replace(/\D/, "");
    this.setState({
      Age: value,
    });
  }
  onChangeRoomNumber(e) {
    const value = e.target.value.replace(/[^0-9a-zA-Z]+/ig, "");
    this.setState({
      RoomNumber: value,
    });
  }
  onChangeFMName(e) {
    this.setState({
      FMName: e.target.value,
    });
  }
  onChangeFMPhoneNumber(e) {
    const value = e.target.value.replace(/\D/, "");
    if (e.target.value.length > 11) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: "Phone number should not be less than 10-digits and  more than 11-digits",
      });
    } else {
      this.setState({ FMPhone: value });
    }
  }
  // onChange  = (e)=>{
  //   let name = e.target.name;
  //   this.setState({[name]: e.target.value});
  // }
  onChangeFMEmail(e) {
    this.setState({
      FMEmail: e.target.value,
    });
  }
  onChangeFMHouseNumber(e) {
    this.setState({
      FMHouseNumber: e.target.value,
    });
  }
  onChangeFMStreet(e) {
    this.setState({
      FMStreet: e.target.value,
    });
  }
  onChangeFMTown(e) {
    this.setState({
      FMTown: e.target.value,
    });
  }
  onChangeFMCountry(e) {
    this.setState({
      countrySelected: e.target.value,
    });
  }
  onChangeFMPostalCode(e) {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    if (e.target.value.length > 7) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: 'Post Code should not be less than 6-digits and  more than 7-digits'
      })
    }
    else {
      this.setState({
        FMPostCode: value
      });
    }

  }

  componentDidMount() {

    const arr = window.location.href.split("/");
    const residentId = arr[arr.length - 1];
    axios
      .get(APIUtil.BASE_URL + APIUtil.RESIDENT_API + "/" + residentId)
      .then((response) => {
        this.setState({
          residentId: residentId,
          Name: response.data.name,
          Age: response.data.age,
          RoomNumber: response.data.roomNumber,
          selectedDate: new Date(response.data.dateOfBirth),
          dateOfBirth: response.data.dateOfBirth,
          FMName: response.data.familyMemberDetail.name,
          FMEmail: response.data.familyMemberDetail.email,
          FMPhone: response.data.familyMemberDetail.phoneNumber,
          FMHouseNumber: response.data.familyMemberDetail.houseNumber,
          FMStreet: response.data.familyMemberDetail.street,
          FMTown: response.data.familyMemberDetail.town,
          countrySelected: response.data.familyMemberDetail.country,
          FMPostCode: response.data.familyMemberDetail.postCode,
        });


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleSubmit(e) {
    document.getElementById("submitbtn").innerHTML = "Processing...";
    e.preventDefault();
    if (this.valid()) {
      fetch(
        APIUtil.BASE_URL + APIUtil.RESIDENT_API + "/" + this.state.residentId,
        {
          method: "PUT",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: parseInt(this.state.residentId),
            name: this.state.Name,
            age: parseInt(this.state.Age),
            roomNumber: this.state.RoomNumber,
            CreatedBy: this.state.careHomeUserId,
            ModifiedBy: this.state.careHomeUserId,
            careHomeId: parseInt(careHomeUser.CareHomeId),
            dateOfBirth: this.state.dateOfBirth,

            familyMember: {
              name: this.state.FMName,
              phone: this.state.FMPhone,
              email: this.state.FMEmail,
              houseNumber: this.state.FMHouseNumber,
              street: this.state.FMStreet,
              town: this.state.FMTown,
              country: this.state.countrySelected,
              postCode: this.state.FMPostCode,
            },
          }),
        }
      ).then(async (response) => {
        if (response.ok) {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: "Resident is Edited successfully",
          });
          document.getElementById("submitbtn").innerHTML = "Save";

          this.setState({ Name: "" });
          this.setState({ RoomNumber: "" });
          this.setState({ Age: "" });

          this.setState({ FMName: "" });
          this.setState({ FMPhone: "" });
          this.setState({ FMEmail: "" });

          this.setState({ FMHouseNumber: "" });
          this.setState({ FMStreet: "" });
          this.setState({ FMTown: "" });
          this.setState({ countrySelected: "" });
          this.setState({ FMPostCode: "" });

          window.location.href = "/Manager/ManagerSearchResident";
        } else {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: "Failed to Edit Resident",
          });
          document.getElementById("submitbtn").innerHTML = "Save";
        }
      });
    }
  }

  render() {
    return (
      <>
        <div
          id="sidebar"
          className="hold-transition sidebar-mini sidebar-collapse"
        >
          <div className="wrapper">
            <ManagerNavbar />
            <ManagerSidebar />

            <form className="form" onSubmit={this.onSubmit}>
              <div className="content-wrapper" style={{ marginLeft: "250px" }}>
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1>Edit Resident</h1>
                      </div>
                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item">
                            <a href="/Manager/ManagerIndex">Home</a>
                          </li>
                          <li className="breadcrumb-item active">
                            {" "}
                            Edit Residents
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /.container-fluid --> */}
                </section>
                {/* <!-- Main content --> */}
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      {/* <!-- Details --> */}
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Details</h3>
                          </div>
                          <div className="card-body">
                            <div className="row">

                              <div className="col-6">
                                <div className="form-group">
                                  <label>Name</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-user"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.Name}
                                      onChange={this.onChangeName}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-group">
                                  <label>Date Of Birth</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-hashtag"></i>
                                      </span>{" "}
                                    </div>

                                    <DatePicker
                                      dateFormat="dd-MM-yyyy"
                                      maxDate={moment(this.state.maxDate).toDate()}
                                      selected={this.state.selectedDate}
                                      onChange={this.onChangeDate}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="form-group">
                                  <label>Room No.</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-house-user"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.RoomNumber}
                                      onChange={this.onChangeRoomNumber}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">
                              Family Member Details
                            </h3>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <label>Name</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-user"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMName}
                                      onChange={this.onChangeFMName}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label>Phone No.</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-phone-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMPhone}
                                      onChange={this.onChangeFMPhoneNumber}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label>E mail</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-envelope"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMEmail}
                                      onChange={this.onChangeFMEmail}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <label>House Name / No.</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-home"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMHouseNumber}
                                      onChange={this.onChangeFMHouseNumber}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label>Street Name</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-map-marker-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMStreet}
                                      onChange={this.onChangeFMStreet}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label>Town</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-sm fa-map-marker-alt"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMTown}
                                      onChange={this.onChangeFMTown}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <div className="form-group">
                                  <label htmlFor="inputName">Country</label>
                                  <select
                                    className="form-control"
                                    style={{
                                      select: "-ms-expand",
                                      display: "block",
                                    }}
                                    value={this.state.countrySelected}
                                    onChange={this.onChangeFMCountry}
                                  >
                                    <option selected="" disabled="">
                                      Select
                                    </option>
                                    {this.state.FMCountryOptions.map((i) =>
                                      i == this.state.selectedOption ? (
                                        <option value={i} selected>
                                          {i}
                                        </option>
                                      ) : (
                                        <option value={i}>{i}</option>
                                      )
                                    )}
                                  </select>

                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label>Post Code</label>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      {" "}
                                      <span className="input-group-text">
                                        <i className="fas fa-map-pin"></i>
                                      </span>{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.FMPostCode}
                                      onChange={this.onChangeFMPostalCode}
                                    />
                                  </div>
                                  {/* <!-- /.input group -->  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="card-footer bg-transparent">
                  <a onClick={this.handleSubmit} className="btn btn-primary"
                    id="submitbtn">
                    Save
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/Manager/ManagerSearchResident" type="submit" className="btn btn-default">
                    Cancel
                  </a>
                </div>
              </div>
            </form>
            <Footer />

          </div>

        </div>
      </>
    );
  }
}
