import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "../Css/Adminlte.min.css";
import "../Css/Custom.css";
import APIUtil from "../../Util/ApiUtil";
import axios from "axios";
import { toast } from "react-toastify";
import AddExpenseModal from "../Modals/Admin/AddExpenseModal";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import moment from "moment";
let careHomeUser = JSON.parse(localStorage.getItem("user"));

export class ExpenseDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            searchForm: {},
            expenses: [],
            expenseTypes: [],
            showAddExpenseModal: false,
            months: [],
            //pagination
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageCount: 1,


            queryParams: "",
            loading: false
        }


        this.modelClose = this.modelClose.bind(this);
        this.openModal = this.openModal.bind(this);
        this.getExpenses = this.getExpenses.bind(this);
        this.findExpenseTypeNameById = this.findExpenseTypeNameById.bind(this);
    }
    openModal() {
        this.setState({ showAddExpenseModal: true });
    }

    modelClose() {
        this.setState({ showAddExpenseModal: false });
        this.getExpenses();
    }


    componentWillReceiveProps(nextProps) {
        //if(this.props.searchForm !== nextProps.searchForm){
        let searchForm = nextProps.searchForm;

        this.setState({
            searchForm: nextProps.searchForm
        });

        this.getExpenses();
        // }
    }

    getExpenses() {
        this.setState({ loading: true });
        let queryParams = this.state.queryParams;
        queryParams = "?CareHomeId=" + careHomeUser.CareHomeId + "&Limit=" + this.state.perPage + "&Offset=" + this.state.offset;


        let searchForm = this.state.searchForm;
        for (const property in searchForm) {
            if (searchForm[property].length > 0) {
                queryParams = `${queryParams}&${property}=${searchForm[property]}`;

            }
        }



        fetch(APIUtil.BASE_URL + APIUtil.ADD_EXPENSE_MODAL + queryParams, {

            method: "GET",
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
            },
        }
        )
            .then((response) => response.json())
            .then(
                async (result) => {
                    await this.setState({ expenses: result.expenseModel, loading: false, pageCount: Math.ceil(result.totalCount / this.state.perPage), totalAmount: result.totalAmount });
                },
                (error) => {
                    console.log("failed", error);
                }
            );
    }

    findExpenseTypeNameById(id) {
        let expenseType = this.props.expenseTypes.find(type => type.id === id);

        if (expenseType !== undefined) {

            return expenseType.name;
        }
    }




    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState(
            {
                selected: selectedPage,
                offset: offset,
                queryParams: "?Limit=" + this.state.perPage + "&Offset=" + offset,
            },
            () => {
                this.getExpenses();
            }
        );

    };

    componentDidMount() {
        this.setState({
            queryParams:
                "?Limit=" + this.state.perPage + "&Offset=" + this.state.offset,
        });
        this.setState({
            searchForm: this.props.searchForm,
            expenses: this.getExpenses(),
            expenseTypes: this.props.expenseTypes,
            showAddExpenseModal: false,
            months: this.props.months,
        });
    }
    formatUrl(path) {
        if (path != null && path != "") {
            var url = APIUtil.DOWNLOAD_BASE_URL + "/" + path.replace("C:\\inetpub\\wwwroot\\CareHomes\\Attachments\\", "");
            return url;
        }
        else return "";
    }
    render() {
        let expenses = [];
        let total = 0;
        {
            this.state.expenses?.map((expense) => {
                total = total + expense.amount;
                expenses.push(
                    <tr key={expense.id}>
                        <td>
                            {this.findExpenseTypeNameById(expense.expenseTypeId)}
                            <p style={{ fontSize: "14px" }} className="text-muted mb-0" >  {expense.info} </p>
                        </td>
                        <td>{expense.amount}</td>
                        <td>{moment(expense.date).format("DD-MMM-YYYY")}
                            {this.formatUrl(expense.receiptPath) != "" ? (

                                <a href={this.formatUrl(expense.receiptPath).replace("\\", "/")} className="float-right mr-2 text-sm-right" target="_blank" download={this.formatUrl(expense.receiptPath).replace("\\", "/").substring(this.formatUrl(expense.receiptPath).replace("\\", "/").lastIndexOf('/') + 1)}>
                                    <i className="fas fas fa-file-download text-warning"></i>
                                </a>) : ""}</td>
                    </tr>
                );
            });
        }
        return (
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Expense Report</h3>
                        <div className="card-tools">
                            Total: <i className="fas fa-xs fa-pound-sign"></i>{" "}
                            <strong>{this.state.totalAmount} </strong> &nbsp; &nbsp;
                            <a
                                href="#"
                                className="btn btn-sm btn-secondary"
                                onClick={this.openModal}
                                aria-hidden="true"
                                data-toggle="modal"
                                data-target="#Modal-add"
                            >
                                <i className="fas fa-plus"></i> Add{" "}
                            </a>
                        </div>
                    </div>

                    <div className="card-body">
                        {this.state.loading ? (
                            <img src="/dist/img/loader.gif" width="20%" />
                        ) : (
                            <>
                                <table
                                    id="example2"
                                    className="table table-bordered table-hover"
                                >
                                    <thead>
                                        <tr>
                                            <th style={{ width: "25%" }}>Type</th>
                                            <th style={{ width: "20%" }}>Amount</th>
                                            <th style={{ width: "20%" }}>Date</th>
                                        </tr>
                                    </thead>

                                    <tbody>{expenses}</tbody>
                                </table>

                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={this.state.selected}
                                />
                            </>)}
                    </div>

                </div>
                {this.state.showAddExpenseModal && (
                    <AddExpenseModal
                        show={this.state.showAddExpenseModal}
                        onHide={this.modelClose}
                        expenseTypes={this.props.expenseTypes}
                        months={this.state.months}
                        CareHomeId={careHomeUser.CareHomeId}

                    />
                )}
            </div>

        );

    }
}
